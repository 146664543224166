import dayjs from 'dayjs';
import { API_DATE_FORMAT } from './dateFormatUtils.ts';
import { FormFieldType, FormFieldValuesInput } from '../graphql/generated/graphql.ts';

type FormsType = { fields: readonly { readonly id: string; readonly type: string }[] }[] | undefined | null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createFormFieldValues = (forms: FormsType, uiFormValues: any): FormFieldValuesInput[] => {
  forms
    ?.flatMap(it => it.fields)
    .forEach(field => {
      if (Object.prototype.hasOwnProperty.call(uiFormValues, field.id)) {
        // we assume every value of a datepicker field is a dayjs instance
        if (field.type === FormFieldType.DATE && uiFormValues[field.id]) {
          uiFormValues[field.id] = (uiFormValues[field.id] as dayjs.Dayjs).format(API_DATE_FORMAT);
        }

        if (!Array.isArray(uiFormValues[field.id])) {
          uiFormValues[field.id] = uiFormValues[field.id] ? [uiFormValues[field.id]] : [];
        }
      } else {
        uiFormValues[field.id] = [];
      }
    });

  return Object.entries(uiFormValues).map(entry => ({
    formFieldId: entry[0],
    values: entry[1] as string[],
  }));
};
