import humanizeDuration from 'humanize-duration';
import dayjs from 'dayjs';

export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const SHORT_DATE_FORMAT = 'DD.MM.YYYY';
export const API_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const SHORT_DATETIME_FORMAT = 'DD.MM.YYYY HH:mm';

export const humanizedHours = (hours: number) => {
  const milliseconds = hours * 60 * 60 * 1000;
  return humanizeDuration(milliseconds, { language: 'de', units: ['d', 'h'], round: true });
};

export const personAge = (birthday: string): number => Math.round(dayjs().diff(birthday, 'years', true) * 10) / 10;
