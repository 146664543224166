import React from 'react';
import { Button, Checkbox, Col, theme, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined, WarningTwoTone } from '@ant-design/icons';
import { css } from '@emotion/css';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  ParameterSelection_RequestableParameterFragment,
  ParameterToAddInput,
} from '../../../../graphql/generated/graphql.ts';
import { SheetData, SheetGroup, SheetParameter } from '../../../../graphql/sheet';

export const SheetParameterCol: React.FC<{
  sheet: SheetData;
  group: SheetGroup;
  sheetParam: SheetParameter;
  requestableParam?: ParameterSelection_RequestableParameterFragment;
  available: boolean;
  compatible: boolean;
  selected: boolean;
  onAdd: (inputs: ParameterToAddInput[]) => void;
  onRemove: (paramId: string) => void;
  onInfo: (param: ParameterSelection_RequestableParameterFragment) => void;
  reorder: boolean;
  flipParamNames: boolean;
}> = ({
  sheet,
  group,
  sheetParam,
  requestableParam,
  available,
  compatible,
  selected,
  onAdd,
  onRemove,
  onInfo,
  reorder,
  flipParamNames,
}) => {
  const { token } = theme.useToken();

  const onChange = (e: CheckboxChangeEvent) => {
    e.target.checked
      ? onAdd([
          {
            parameterId: requestableParam!.id,
            preferredBillingType: null,
            preferredDiagnoseIds: [],
          },
        ])
      : onRemove(requestableParam!.id);
  };

  const id = `${sheet.name}_${group.name}_${sheetParam.id}`;

  return (
    <Col
      lg={12}
      xl={8}
      className={css`
        display: flex;
        gap: 8px;
        align-items: center;
      `}
    >
      <Checkbox id={id} checked={selected} disabled={!available || !compatible} onChange={onChange} />
      <label
        htmlFor={id}
        className={css`
          display: flex;
          min-width: 0;
          cursor: ${available && compatible ? 'pointer' : 'not-allowed'};
          opacity: ${available && compatible ? 1 : 0.5};
        `}
      >
        <Typography.Text
          className={css`
            flex-shrink: 0;
            margin-right: ${token.marginXXS}px;
          `}
          ellipsis
        >
          {flipParamNames ? sheetParam.longName : sheetParam.shortName}
        </Typography.Text>
        <Typography.Text
          className={css`
            margin-right: ${token.marginXXS}px;
          `}
          type="secondary"
          ellipsis
        >
          <small>{flipParamNames ? sheetParam.shortName : sheetParam.longName}</small>
        </Typography.Text>
      </label>
      <div
        className={css`
          flex-grow: 1;
          justify-content: end;
          display: flex;
        `}
      >
        {available && compatible && (
          <Button
            icon={<InfoCircleOutlined twoToneColor={token.colorInfo} />}
            size="small"
            type="text"
            onClick={() => onInfo(requestableParam!)}
          />
        )}
        {(!available || !compatible) && (
          <Tooltip
            title={
              available && !compatible
                ? 'Der Parameter kann nicht angefordert werden, da er mit den ausgewählten Parametern nicht kompatibel ist.'
                : `Der Parameter kann aktuell nicht angefordert werden. ${
                    reorder
                      ? 'Nachforderung nicht möglich.'
                      : 'Bitte kontaktieren Sie das Labor für weitere Informationen.'
                  }`
            }
          >
            <WarningTwoTone
              twoToneColor={token.colorWarning}
              className={css`
                margin-right: 5px;
              `}
            />
          </Tooltip>
        )}
      </div>
    </Col>
  );
};
