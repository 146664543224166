import React, { useEffect, useState } from 'react';
import { Button, Input, Table, Tooltip } from 'antd';
import { MainContent } from '../../components/MainContent';
import { useQuery } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import { useDebounce } from 'use-debounce';
import { Markable } from '../../components/Markable';
import { EditOutlined, InfoCircleOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { CreateInsuranceModal } from './insurances/CreateInsuranceModal';
import { UpdateInsuranceModal } from './insurances/UpdateInsuranceModal';
import { ellipsis } from '../../utils/ellipsis';
import { PageHeader } from '@ant-design/pro-components';
import { tableActionCell } from '../../styles/globalCss';
import { graphql } from '../../graphql/generated';
import { InsurancesQuery } from '../../graphql/generated/graphql.ts';

const INSURANCES_QUERY = graphql(`
  query Insurances {
    insurances {
      id
      name
      shortName
      code
      mappings
    }
  }
`);

export type Insurance = NonNullable<InsurancesQuery['insurances']>[number];

export const Insurances: React.FC = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editInsurance, setEditInsurance] = useState<Insurance | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch] = useDebounce(search, 250);
  const [filteredInsurances, setFilteredInsurances] = useState<Insurance[]>([]);

  const { data, loading, refetch } = useQuery(INSURANCES_QUERY, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000,
  });

  useEffect(() => {
    const result =
      data?.insurances?.filter(insurance => {
        if (debouncedSearch && debouncedSearch.length) {
          const searchValue = debouncedSearch.toLowerCase();
          return (
            insurance.name.toLowerCase().includes(searchValue) ||
            insurance.shortName.toLowerCase().includes(searchValue) ||
            insurance.code.toLowerCase().includes(searchValue)
          );
        }
        return true;
      }) ?? [];
    setFilteredInsurances(result);
  }, [debouncedSearch, data]);

  const columns: ColumnsType<Insurance> = [
    {
      title: 'Kurzbezeichnung',
      dataIndex: 'shortName',
      key: 'shortName',
      ellipsis: true,
      width: 160,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.shortName.localeCompare(b.shortName),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Bezeichnung',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
      width: 100,
      sorter: (a, b) => a.code.localeCompare(b.code),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Alias-Codes',
      dataIndex: 'mappings',
      key: 'mappings',
      ellipsis: true,
      width: 100,
      render: (_, record) => {
        const value = record.mappings.join(', ');
        const ellipsisValue = ellipsis(value, 30);

        return (
          <Tooltip title={value} trigger={value.length === ellipsisValue.length ? [] : ['hover']}>
            {ellipsisValue}
          </Tooltip>
        );
      },
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '50px',
      className: tableActionCell,
      render: (_, record) => <Button icon={<EditOutlined />} type="text" onClick={() => setEditInsurance(record)} />,
    },
  ];

  return (
    <MainContent>
      <PageHeader
        title="Krankenkassen"
        extra={[
          <Input
            key="search"
            allowClear
            autoFocus
            placeholder="Suche"
            value={search ?? ''}
            onChange={e => setSearch(e.target.value)}
            prefix={<SearchOutlined />}
            suffix={
              <Tooltip title="Suche nach Bezeichnung, Kurzbezeichnung oder Code">
                <InfoCircleOutlined />
              </Tooltip>
            }
            style={{ width: '250px' }}
          />,
          <Button key="create" icon={<PlusCircleOutlined />} type="primary" onClick={() => setCreateModalOpen(true)}>
            Neue Krankenkasse
          </Button>,
        ]}
        style={{ padding: 0, paddingBottom: 'inherit' }}
      />
      <Table<Insurance>
        scroll={{ x: 'max-content' }}
        rowKey={record => record.id}
        sticky={true}
        size="middle"
        showSorterTooltip={false}
        dataSource={filteredInsurances}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} bis ${range[1]} von ${total} Krankenkassen`,
        }}
        loading={loading}
        columns={columns}
      />
      <CreateInsuranceModal
        open={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false);
          refetch();
        }}
        allInsurances={data?.insurances ?? []}
      />
      <UpdateInsuranceModal
        insurance={editInsurance}
        allInsurances={data?.insurances ?? []}
        onClose={() => {
          setEditInsurance(null);
          refetch();
        }}
      />
    </MainContent>
  );
};
