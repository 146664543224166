import React from 'react';
import { App, Modal } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { PeriodicGroupForm } from './PeriodicGroupForm.tsx';
import { useForm } from 'antd/es/form/Form';
import { graphql } from '../../../../graphql/generated';
import { Period, PeriodicGroupCreateInput } from '../../../../graphql/generated/graphql.ts';

const CREATE_PERIODIC_GROUP_MUTATION = graphql(`
  mutation CreatePeriodicGroup($input: PeriodicGroupCreateInput!) {
    createPeriodicGroup(input: $input) {
      id
    }
  }
`);

export const CreatePeriodicGroupModal: React.FC<{
  labId: string;
  modalVisible: boolean;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ labId, modalVisible, onClose, onSuccess }) => {
  const [createPeriodicGroupMutation, { loading }] = useMutation(CREATE_PERIODIC_GROUP_MUTATION);
  const [form] = useForm<PeriodicGroupCreateInput>();
  const { message } = App.useApp();

  const create = async (values: PeriodicGroupCreateInput) => {
    try {
      await createPeriodicGroupMutation({
        variables: {
          input: {
            ...values,
            labId: labId,
          },
        },
      });

      message.success('Periodische Verrechnungsgruppe wurde erstellt');
      onSuccess();
      closeAndReset();
    } catch (e) {
      message.error('Periodische Verrechnungsgruppe konnte nicht erstellt werden');
    }
  };

  const closeAndReset = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width={768}
      title="Neue periodische Verrechnungsgruppe erstellen"
      okText="Erstellen"
      okButtonProps={{ icon: <PlusCircleOutlined /> }}
      open={modalVisible}
      onCancel={closeAndReset}
      onOk={form.submit}
      confirmLoading={loading}
      destroyOnClose
    >
      <PeriodicGroupForm
        form={form}
        initialValues={{
          labId: labId,
          name: '',
          maxCount: 1,
          period: Period.CURRENT_REQUEST,
        }}
        onFinish={values => create(values as PeriodicGroupCreateInput)}
      />
    </Modal>
  );
};
