import React from 'react';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SheetDataWithId } from './types';
import { SortableSheetEntry } from './SortableSheetEntry.tsx';
import { FragmentType } from '../../graphql/generated';
import type { SheetEditorParameterFragment } from './SheetEditor.tsx';

export const SortableSheets: React.FC<{
  sheets: SheetDataWithId[];
  parameters: FragmentType<typeof SheetEditorParameterFragment>[];
  onSheetDragEnd: (event: DragEndEvent) => void;
  onSheetRemove: (sheetId: string) => void;
  onSheetEdit: (sheetId: string) => void;
  onGroupAdd: (sheetId: string) => void;
  onGroupEdit: (sheetId: string, groupId: string) => void;
  onGroupDragEnd: (sheetId: string, event: DragEndEvent) => void;
  onGroupRemove: (sheetId: string, groupId: string) => void;
  onGroupCopy: (sheetId: string, groupId: string) => void;
  onParamAdd: (sheetId: string, groupId: string) => void;
  onParamDragEnd: (sheetId: string, groupId: string, event: DragEndEvent) => void;
  onParamRemove: (sheetId: string, groupId: string, paramId: string) => void;
  showLab: boolean;
  flipParamNames: boolean;
}> = ({
  sheets,
  parameters,
  onSheetDragEnd,
  onSheetRemove,
  onSheetEdit,
  onGroupAdd,
  onGroupEdit,
  onGroupCopy,
  onParamAdd,
  onGroupDragEnd,
  onGroupRemove,
  onParamDragEnd,
  onParamRemove,
  showLab,
  flipParamNames,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onSheetDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext items={sheets} strategy={verticalListSortingStrategy}>
        {sheets.map(sheet => (
          <SortableSheetEntry
            sheet={sheet}
            parameters={parameters}
            key={sheet.id}
            onRemove={onSheetRemove}
            onEdit={onSheetEdit}
            onGroupAdd={onGroupAdd}
            onGroupEdit={onGroupEdit}
            onGroupCopy={onGroupCopy}
            onGroupDragEnd={onGroupDragEnd}
            onGroupRemove={onGroupRemove}
            onParamAdd={onParamAdd}
            onParamDragEnd={onParamDragEnd}
            onParamRemove={onParamRemove}
            showLab={showLab}
            flipParamNames={flipParamNames}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};
