import React, { useEffect, useState } from 'react';
import { App, Button, Modal, theme, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  type CatalogFragment,
  DoctorForm,
  type InsuranceFragment,
  type LabFragment,
  type SpecialRateFragment,
  type ThemeFragment,
} from './DoctorForm';
import { useForm } from 'antd/es/form/Form';
import { FragmentType, graphql } from '../../../graphql/generated';
import {
  AisImportType,
  BillingPreference,
  DoctorInput,
  DoctorSalutation,
  UpdateDoctorModal_DoctorFragment,
} from '../../../graphql/generated/graphql.ts';

const { useToken } = theme;

const UPDATE_DOCTOR_MUTATION = graphql(`
  mutation UpdateDoctor($id: ID!, $input: DoctorInput!) {
    updateDoctor(id: $id, input: $input) {
      id
    }
  }
`);

export const DoctorFragment = graphql(`
  fragment UpdateDoctorModal_Doctor on Doctor {
    id
    salutation
    preTitle
    postTitle
    firstName
    lastName
    name
    specialist
    meAddress
    hvNumber
    street
    zip
    city
    email
    phone
    assignedLabs {
      lab {
        id
        name
      }
      customerId
      supplier
      runningNumber
    }
    comment
    aisImportType
    aisImportDoctorId
    billingPreference
    preferSpecialRate
    autoDiagnose
    autoSpecialRate
    catalogs {
      id
      name
      lab {
        id
        shortName
      }
    }
    insurances {
      id
    }
    specialRates {
      id
    }
    doctorBilling
    patientBilling
    lgInsurances {
      id
    }
    lgDoctorBilling
    hasAis
    printTransferDocument
    showPrices
    showPriceSuggestion
    flipParamNames
    aisExportTypes
    addEmptyLabels
    theme {
      id
      name
    }
    csvImportEnabled
    overrideRequestEnabled
    disabled
  }
`);

export const UpdateDoctorModal: React.FC<{
  onClose: () => void;
  doctor: UpdateDoctorModal_DoctorFragment | null;
  labFragments: FragmentType<typeof LabFragment>[];
  catalogFragments: FragmentType<typeof CatalogFragment>[];
  insuranceFragments: FragmentType<typeof InsuranceFragment>[];
  specialRateFragments: FragmentType<typeof SpecialRateFragment>[];
  themeFragments: FragmentType<typeof ThemeFragment>[];
}> = ({
  onClose,
  labFragments,
  doctor,
  catalogFragments,
  insuranceFragments,
  specialRateFragments,
  themeFragments,
}) => {
  const { token } = useToken();
  const [updateDoctorMutation, { loading }] = useMutation(UPDATE_DOCTOR_MUTATION);
  const [form] = useForm<DoctorInput>();
  const [hasError, setError] = useState(false);
  const { message } = App.useApp();

  const update = async (values: DoctorInput) => {
    try {
      await updateDoctorMutation({
        variables: {
          id: doctor?.id ?? '',
          input: values,
        },
      });

      message.success('Zuweiser wurde aktualisiert');
      closeAndReset();
    } catch (e) {
      message.error('Zuweiser konnte nicht aktualisiert werden: ' + e);
    }
  };

  const closeAndReset = () => {
    form.resetFields();
    setError(false);
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue({
      salutation: doctor?.salutation ?? DoctorSalutation.HERR,
      name: doctor?.name ?? '',
      specialist: doctor?.specialist ?? '',
      hvNumber: doctor?.hvNumber ?? '',
      meAddress: doctor?.meAddress ?? '',
      firstName: doctor?.firstName ?? '',
      lastName: doctor?.lastName ?? '',
      preTitle: doctor?.preTitle ?? '',
      postTitle: doctor?.postTitle ?? '',
      street: doctor?.street ?? '',
      zip: doctor?.zip ?? '',
      city: doctor?.city ?? '',
      email: doctor?.email ?? '',
      phone: doctor?.phone ?? '',
      assignedLabs:
        doctor?.assignedLabs.map(al => ({
          labId: al.lab.id,
          customerId: al.customerId,
          supplier: al.supplier,
          runningNumber: al.runningNumber,
          overrideRunningNumber: false,
        })) ?? [],
      comment: doctor?.comment ?? '',
      aisImportType: doctor?.aisImportType ?? AisImportType.GENERIC_ANSI,
      aisImportDoctorId: doctor?.aisImportDoctorId ?? '',
      billingPreference: doctor?.billingPreference ?? BillingPreference.ASK,
      preferSpecialRate: doctor?.preferSpecialRate ?? false,
      autoDiagnose: doctor?.autoDiagnose ?? false,
      autoSpecialRate: doctor?.autoSpecialRate ?? false,
      catalogIds: doctor?.catalogs.map(c => c.id) ?? [],
      insuranceIds: doctor?.insurances.map(c => c.id) ?? [],
      specialRateIds: doctor?.specialRates.map(c => c.id) ?? [],
      doctorBilling: doctor?.doctorBilling ?? false,
      patientBilling: doctor?.patientBilling ?? false,
      lgDoctorBilling: doctor?.lgDoctorBilling ?? false,
      lgInsuranceIds: doctor?.lgInsurances.map(c => c.id) ?? [],
      hasAis: doctor?.hasAis ?? false,
      printTransferDocument: doctor?.printTransferDocument ?? false,
      showPrices: doctor?.showPrices ?? true,
      showPriceSuggestion: doctor?.showPriceSuggestion ?? false,
      flipParamNames: doctor?.flipParamNames ?? false,
      addEmptyLabels: doctor?.addEmptyLabels ?? 0,
      aisExportTypes: doctor?.aisExportTypes ?? [],
      themeId: doctor?.theme?.id ?? null,
      csvImportEnabled: doctor?.csvImportEnabled ?? false,
      overrideRequestEnabled: doctor?.overrideRequestEnabled ?? false,
      disabled: doctor?.disabled ?? false,
    });
  }, [form, doctor]);

  return (
    <Modal
      width={1024}
      title="Zuweiser bearbeiten"
      open={!!doctor}
      destroyOnClose
      onCancel={closeAndReset}
      forceRender
      footer={[
        <Typography.Text key="validationInfo" type="danger" style={{ marginRight: token.paddingSM }}>
          {hasError && 'Manche Felder sind nicht ordnungsgemäß ausgefüllt'}
        </Typography.Text>,
        <Button key="cancel" onClick={closeAndReset}>
          Abbrechen
        </Button>,
        <Button key="submit" icon={<EditOutlined />} type="primary" loading={loading} onClick={form.submit}>
          Speichern
        </Button>,
      ]}
    >
      <DoctorForm
        form={form}
        onFinish={update}
        onFieldsChange={(_, allFields) => setError(allFields.some(it => it.errors?.length))}
        labFragments={labFragments}
        catalogFragments={catalogFragments}
        insuranceFragments={insuranceFragments}
        specialRateFragments={specialRateFragments}
        themeFragments={themeFragments}
      />
    </Modal>
  );
};
