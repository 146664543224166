import { useCallback, useState } from 'react';
import { message } from 'antd';

export const useLabelary = (): [
  objectUrl: string,
  fetchAndSet: (width: number, height: number, zpl: string) => void,
  cleanup: () => void,
] => {
  const [objectUrl, setObjectUrl] = useState('');

  const fetchAndSet = useCallback(async (width: number, height: number, zpl: string) => {
    try {
      const response = await fetch(
        `https://api.labelary.com/v1/printers/8dpmm/labels/${width / 25.4}x${height / 25.4}/0/`,
        {
          method: 'POST',
          body: zpl.replace('^CI27', '^CI28'), // use UTF-8
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
        }
      );

      if (!response.ok) {
        message.error('Label kann nicht angezeigt werden bzw. ist nicht gültig.');
        return;
      }

      const blob = await response.blob();
      setObjectUrl(URL.createObjectURL(new Blob([blob], { type: 'image/png' })));
    } catch {
      message.error('Label kann nicht angezeigt werden bzw. ist nicht gültig.');
    }
  }, []);

  const cleanup = useCallback(() => {
    if (objectUrl) {
      URL.revokeObjectURL(objectUrl);
    }
    setObjectUrl('');
  }, [objectUrl]);

  return [objectUrl, fetchAndSet, cleanup];
};
