import React, { useState } from 'react';
import { App, Button, Empty, List, Modal, Space, theme } from 'antd';
import { useQuery } from '@apollo/client';
import { CloseOutlined, EyeOutlined, PrinterOutlined } from '@ant-design/icons';
import { FormRenderer, FormRendererFragment } from './FormRenderer.tsx';
import { useForm } from 'antd/es/form/Form';
import { createFormFieldValues } from '../../utils/form.ts';
import { css } from '@emotion/css';
import { PdfPreviewModal } from '../../components/PdfPreviewModal.tsx';
import { FormFieldValuesInput, FormRenderer_FormFragment, PatientData } from '../../graphql/generated/graphql.ts';
import { graphql, getFragmentData } from '../../graphql/generated';
import { useAuth } from 'react-oidc-context';

const { useToken } = theme;

const DIRECT_FORMS_QUERY = graphql(`
  query DirectForms($doctorId: ID!) {
    directForms(doctorId: $doctorId) {
      ...FormRenderer_Form
    }
  }
`);

interface FormAction {
  form: FormRenderer_FormFragment;
  action: 'view' | 'print';
  values?: FormFieldValuesInput[];
}

export const Forms: React.FC<{ patientData: PatientData; doctorId: string }> = ({ patientData, doctorId }) => {
  const { message } = App.useApp();
  const auth = useAuth();

  const { data, loading } = useQuery(DIRECT_FORMS_QUERY, {
    variables: {
      doctorId: doctorId,
    },
    fetchPolicy: 'cache-and-network',
  });
  const directForms = getFragmentData(FormRendererFragment, data?.directForms);

  const [formRendererAction, setFormRendererAction] = useState<FormAction | null>(null);
  const [objectUrl, setObjectUrl] = useState<string>('');
  const [blob, setBlob] = useState<Blob | null>(null);
  const [pdfLoading, setPdfLoading] = useState(false);

  const step1 = (action: FormAction) => {
    if (action.form.fields.length) {
      setFormRendererAction(action);
      return;
    }
    execute({ ...action, values: [] });
  };

  const execute = async (action: FormAction) => {
    if (action.action === 'view') {
      setPdfLoading(true);
    }
    try {
      const response = await fetch(window._env_.API_URL + '/rest/direct-form', {
        method: 'POST',
        body: JSON.stringify({
          doctorId: doctorId,
          patientData: patientData,
          formId: action.form.id,
          formFieldValues: createFormFieldValues([action.form], action.values),
        }),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${auth.user?.access_token}`,
        },
      });

      if (!response.ok) {
        message.error('Fehler bei der Erstellung des Formulars');
        return;
      }

      const blob = await response.blob();
      if (action.action === 'print') {
        print(blob);
        return;
      }

      setBlob(blob);
      setObjectUrl(URL.createObjectURL(new Blob([blob], { type: 'application/pdf' })));
    } catch (e) {
      message.error('Fehler bei der Erstellung des Formulars');
    } finally {
      setPdfLoading(false);
    }
  };

  const cleanup = () => {
    if (objectUrl) {
      URL.revokeObjectURL(objectUrl);
    }
    setObjectUrl('');
    setBlob(null);
  };

  const print = async (blob: Blob) => {
    if (!window.nativeApi) {
      return;
    }

    try {
      const buffer = await blob.arrayBuffer();
      await window.nativeApi.print(buffer, 'a4');
      message.success('Dokument wurde an den Drucker gesendet');
    } catch (e) {
      message.error('Beim Drucken ist ein Fehler aufgetreten');
    }
  };

  return (
    <>
      <List
        loading={loading}
        size="small"
        itemLayout="horizontal"
        dataSource={directForms ? [...directForms].sort((a, b) => a.name.localeCompare(b.name)) : []}
        locale={{
          emptyText: (
            <Empty
              description="Es stehen im Moment keine Formulare zur Verfügung"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
        renderItem={item => (
          <List.Item
            className={css`
              padding-left: 0 !important;
              padding-right: 0 !important;
            `}
            actions={[
              <Button
                hidden={!window.nativeApi}
                key="print"
                type="link"
                icon={<PrinterOutlined />}
                size="small"
                onClick={() => step1({ form: item, action: 'print' })}
              >
                Drucken
              </Button>,
              <Button
                key="view"
                type="link"
                icon={<EyeOutlined />}
                size="small"
                onClick={() => step1({ form: item, action: 'view' })}
              >
                Anzeigen
              </Button>,
            ]}
          >
            {item.name}
          </List.Item>
        )}
      />
      <PdfPreviewModal
        title="Formular"
        url={objectUrl}
        loading={pdfLoading}
        footer={
          <Space direction="horizontal">
            <Button
              hidden={!window.nativeApi}
              type="primary"
              icon={<PrinterOutlined />}
              onClick={() => (blob ? print(blob) : null)}
            >
              Drucken
            </Button>
            <Button icon={<CloseOutlined />} onClick={cleanup}>
              Schließen
            </Button>
          </Space>
        }
        onClose={cleanup}
      />
      <FormRendererModal
        forms={formRendererAction ? [formRendererAction.form] : []}
        onCancel={() => setFormRendererAction(null)}
        onOk={values => {
          if (formRendererAction) {
            execute({ ...formRendererAction, values: values });
          }
          setFormRendererAction(null);
        }}
      />
    </>
  );
};

export const FormRendererModal: React.FC<{
  forms: FormRenderer_FormFragment[];
  onCancel: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onOk: (values: any) => void;
}> = ({ forms, onCancel, onOk }) => {
  return (
    <Modal
      title="Bitte Formulardaten ergänzen"
      width={768}
      open={!!forms.length}
      footer={null}
      onCancel={onCancel}
      destroyOnClose
    >
      {forms && <FormRendererWrapper forms={forms} onCancel={onCancel} onOk={onOk} />}
    </Modal>
  );
};

export const FormRendererWrapper: React.FC<{
  forms: FormRenderer_FormFragment[];
  onCancel: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onOk: (values: any) => void;
}> = ({ forms, onCancel, onOk }) => {
  const [formInstance] = useForm();
  const { token } = useToken();

  const submit = async () => {
    try {
      await formInstance.validateFields();
    } catch (error) {
      return;
    }

    onOk(formInstance.getFieldsValue());
  };

  return (
    <>
      <FormRenderer formInstance={formInstance} forms={forms} />
      <Space
        direction="horizontal"
        className={css`
          width: 100%;
          justify-content: end;
          margin-top: ${token.paddingMD}px;
        `}
      >
        <Button onClick={onCancel}>Abbrechen</Button>
        <Button type="primary" onClick={submit}>
          OK
        </Button>
      </Space>
    </>
  );
};
