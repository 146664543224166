import React, { CSSProperties } from 'react';
import { Select, Typography } from 'antd';

const { Option } = Select;

export type Parameter = {
  id: string;
  shortName: string;
  longName: string;
};

interface ParameterSelectProps {
  id?: string;
  multiple?: boolean;
  style?: CSSProperties;
  parameters: Parameter[];
  selected?: string | string[] | undefined;
  onChange?: (value: string | string[]) => void;
}

export const ParameterSelect: React.FC<ParameterSelectProps> = React.memo(
  ({ id, multiple, style, parameters, selected, onChange }) => {
    return (
      <Select
        id={id}
        showSearch
        allowClear
        mode={multiple ? 'multiple' : undefined}
        style={style}
        onChange={onChange}
        value={selected}
        placeholder="Suche nach Parameter"
        optionFilterProp="search"
        optionLabelProp="taglabel"
      >
        {[...parameters]
          .sort((a, b) => a.shortName.localeCompare(b.shortName))
          .map(parameter => {
            return (
              <Option
                key={parameter.id}
                value={parameter.id}
                search={`${parameter.shortName} - ${parameter.longName}`}
                taglabel={parameter.shortName}
              >
                <div>{parameter.shortName}</div>
                <Typography.Text type="secondary">{parameter.longName}</Typography.Text>
              </Option>
            );
          })}
      </Select>
    );
  }
);
