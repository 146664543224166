import React, { useEffect, useState } from 'react';
import { MainContent } from '../../../components/MainContent';
import { useParams } from 'react-router-dom';
import { Button, Dropdown, Input, Table, theme, Tooltip } from 'antd';
import { useQuery } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import {
  CheckOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExportOutlined,
  FileImageOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useDebounce } from 'use-debounce';
import { testTubeColorProperties, translateTestTubeSupplier } from '../../../utils/enumHelpers';
import { TestTubeColor } from '../../../components/TestTubeColor';
import { Markable } from '../../../components/Markable';
import { CreateTestTubeModal } from './testTubes/CreateTestTubeModal';
import { UpdateTestTubeModal } from './testTubes/UpdateTestTubeModal';
import { TestTubeImageModal } from './testTubes/TestTubeImageModal';
import { tableActionCell } from '../../../styles/globalCss';
import { graphql } from '../../../graphql/generated';
import { TestTubesQuery } from '../../../graphql/generated/graphql.ts';
import { css } from '@emotion/css';
import { openExternalLink } from '../../../utils/linkOpener.ts';

const { useToken } = theme;

export const TEST_TUBE_QUERY = graphql(`
  query TestTubes($labId: ID!) {
    labTestTubes(labId: $labId) {
      id
      name
      color
      supplier
      labelText
      labelText2
      link
      hasImage
      volume
    }
  }
`);

export type TestTube = NonNullable<TestTubesQuery['labTestTubes']>[number];

export const TestTubes: React.FC = () => {
  const { token } = useToken();
  const { id: labId } = useParams<{ id: string }>();
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch] = useDebounce(search, 250);
  const [filteredTestTubes, setFilteredTestTubes] = useState<TestTube[]>([]);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editTestTube, setEditTestTube] = useState<TestTube | null>(null);
  const [imageTestTube, setImageTestTube] = useState<TestTube | null>(null);

  const { data, loading, refetch } = useQuery(TEST_TUBE_QUERY, {
    variables: {
      labId: labId ?? '',
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000,
  });

  const columns: ColumnsType<TestTube> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      ellipsis: true,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Lieferant',
      dataIndex: 'supplier',
      key: 'supplier',
      ellipsis: true,
      sorter: (a, b) => a.supplier.localeCompare(b.supplier),
      render: value => translateTestTubeSupplier(value),
    },
    {
      title: 'Etiketten-Text',
      dataIndex: 'labelText',
      key: 'labelText',
      ellipsis: true,
      sorter: (a, b) => a.labelText.localeCompare(b.labelText),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Etiketten-Text 2',
      dataIndex: 'labelText2',
      key: 'labelText2',
      ellipsis: true,
      sorter: (a, b) => a.labelText2.localeCompare(b.labelText2),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Farbe',
      dataIndex: 'color',
      key: 'color',
      ellipsis: true,
      sorter: (a, b) => a.color.localeCompare(b.color),
      render: value => <TestTubeColor properties={testTubeColorProperties(value)} />,
    },
    {
      title: 'Volumen',
      dataIndex: 'volume',
      key: 'volume',
      ellipsis: true,
      sorter: (a, b) => a.volume - b.volume,
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      width: 80,
      render: value => {
        if (!value) {
          return;
        }
        return (
          <Button
            type="link"
            className={css`
              height: 20px;
              margin: 0;
              padding: 0;
            `}
            icon={<ExportOutlined />}
            onClick={() => openExternalLink(value)}
          />
        );
      },
    },
    {
      title: 'Bild',
      dataIndex: 'hasImage',
      key: 'hasImage',
      ellipsis: true,
      sorter: (a, b) => Number(a.hasImage) - Number(b.hasImage),
      render: value => (value ? <CheckOutlined /> : ''),
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '50px',
      className: tableActionCell,
      render: (_, record) => {
        return (
          <Dropdown
            menu={{
              items: [
                {
                  key: 'edit',
                  icon: <EditOutlined />,
                  onClick: () => setEditTestTube(record),
                  label: 'Bearbeiten',
                },
                {
                  key: 'image',
                  icon: <FileImageOutlined />,
                  onClick: () => setImageTestTube(record),
                  label: 'Bild ändern',
                },
              ],
            }}
            trigger={['click']}
            placement="bottomRight"
          >
            <Button icon={<EllipsisOutlined style={{ fontSize: '20px' }} />} type="text" />
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    const filtered = data?.labTestTubes?.filter(s => {
      if (debouncedSearch && debouncedSearch.length) {
        const searchValue = debouncedSearch.toLowerCase();
        return (
          s.name.toLowerCase().includes(searchValue) ||
          s.labelText.toLowerCase().includes(searchValue) ||
          s.labelText2.toLowerCase().includes(searchValue)
        );
      }
      return true;
    });
    setFilteredTestTubes(filtered ?? []);
  }, [debouncedSearch, data]);

  return (
    <MainContent>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: token.paddingSM }}>
        <Input
          allowClear
          autoFocus
          placeholder="Suche"
          value={search ?? ''}
          onChange={e => setSearch(e.target.value)}
          prefix={<SearchOutlined />}
          suffix={
            <Tooltip title="Suche nach Name, Etiketten-Text oder Etiketten-Text 2">
              <InfoCircleOutlined />
            </Tooltip>
          }
          style={{ width: '300px', marginRight: token.paddingSM }}
        />
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          disabled={loading}
          onClick={() => setCreateModalVisible(true)}
        >
          Neuer Probenbehälter
        </Button>
      </div>
      <Table<TestTube>
        scroll={{ x: 'max-content' }}
        rowKey={record => record.id}
        size="middle"
        showSorterTooltip={false}
        dataSource={filteredTestTubes}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} bis ${range[1]} von ${total} Probenbehältern`,
        }}
        loading={loading}
        columns={columns}
      />
      <CreateTestTubeModal
        modalVisible={createModalVisible}
        onSuccess={() => refetch()}
        onClose={() => setCreateModalVisible(false)}
        labId={labId ?? ''}
      />
      <UpdateTestTubeModal testTube={editTestTube} onSuccess={() => refetch()} onClose={() => setEditTestTube(null)} />
      <TestTubeImageModal
        testTube={imageTestTube}
        onClose={() => {
          refetch();
          setImageTestTube(null);
        }}
      />
    </MainContent>
  );
};
