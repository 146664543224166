import React from 'react';
import { Button, Select, Table } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { css } from '@emotion/css';
import { InsuranceDefinitionInput } from '../graphql/generated/graphql.ts';

const { Option } = Select;

type Insurance = { id: string; shortName: string };
type Diagnose = { id: string; name: string };
type PeriodicGroup = { id: string; name: string };

export const ParameterInsuranceEditor: React.FC<{
  insurances: readonly Insurance[];
  diagnoses: readonly Diagnose[];
  periodicGroups: readonly PeriodicGroup[];
  lg: boolean;
}> = ({ insurances, diagnoses, lg, periodicGroups }) => {
  const form = useFormInstance();

  const values =
    (form.getFieldValue('insuranceDefinitions') as InsuranceDefinitionInput[])?.filter(it => it.lg === lg) ?? [];
  const setValues = (values: InsuranceDefinitionInput[]) => {
    form.setFieldValue('insuranceDefinitions', [
      ...((form.getFieldValue('insuranceDefinitions') as InsuranceDefinitionInput[])?.filter(it => it.lg !== lg) ?? []),
      ...values,
    ]);
  };

  const onChange = (selectedRows: Insurance[]) => {
    setValues(
      selectedRows.map(row => {
        const entry = values.find(it => it.insuranceId === row.id);
        return {
          insuranceId: row.id,
          diagnoseIds: entry?.diagnoseIds ?? [],
          lg: lg,
          periodicGroupId: entry?.periodicGroupId ?? null,
        };
      })
    );
  };

  const onChangeDiagnoses = (insurance: Insurance, diagnoseIds: string[]) => {
    const entry = values.find(it => it.insuranceId === insurance.id);
    setValues([
      ...values.filter(it => it.insuranceId !== insurance.id),
      {
        insuranceId: insurance.id,
        diagnoseIds: diagnoseIds,
        lg: lg,
        periodicGroupId: entry?.periodicGroupId ?? null,
      },
    ]);
  };

  const onChangePeriodicGroup = (insurance: Insurance, periodicGroupId: string | null) => {
    const entry = values.find(it => it.insuranceId === insurance.id);
    setValues([
      ...values.filter(it => it.insuranceId !== insurance.id),
      {
        insuranceId: insurance.id,
        diagnoseIds: entry?.diagnoseIds ?? [],
        lg: lg,
        periodicGroupId: periodicGroupId,
      },
    ]);
  };

  const populateAllDiagnoses = (diagnoseIds: string[]) => {
    setValues(values.map(it => ({ ...it, diagnoseIds: diagnoseIds })));
  };

  const populateAllPeriodicGroups = (periodicGroupId: string | null) => {
    setValues(values.map(it => ({ ...it, periodicGroupId: periodicGroupId })));
  };

  return (
    <Table<Insurance>
      rowKey={insurance => insurance.id}
      size="small"
      showSorterTooltip={false}
      dataSource={insurances}
      pagination={false}
      rowSelection={{
        type: 'checkbox',
        selectedRowKeys: values.map(it => it.insuranceId),
        onChange: (_, selectedRows) => onChange(selectedRows),
      }}
      columns={[
        {
          title: 'Name',
          dataIndex: 'shortName',
          key: 'shortName',
          ellipsis: true,
          width: 150,
        },
        {
          title: 'Diagnosen',
          key: 'diagnoses',
          render: (_, record) => {
            const entry = values.find(it => it.insuranceId === record.id);
            return (
              <div
                className={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <Select
                  disabled={!entry}
                  value={entry?.diagnoseIds ?? []}
                  onChange={value => onChangeDiagnoses(record, value)}
                  className={css`
                    flex-grow: 1;
                    width: 70%;
                  `}
                  showSearch
                  optionFilterProp="children"
                  allowClear
                  mode="multiple"
                  placeholder="Bitte auswählen..."
                >
                  {[...diagnoses]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(diagnose => (
                      <Option key={diagnose.id} value={diagnose.id}>
                        {diagnose.name}
                      </Option>
                    ))}
                </Select>
                <Button type="link" disabled={!entry} onClick={() => populateAllDiagnoses(entry?.diagnoseIds ?? [])}>
                  Für alle
                </Button>
              </div>
            );
          },
        },
        {
          title: 'Periodische Verrechnungsgruppe',
          key: 'periodicGroup',
          render: (_, record) => {
            const entry = values.find(it => it.insuranceId === record.id);
            return (
              <div
                className={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <Select
                  disabled={!entry}
                  value={entry?.periodicGroupId}
                  onChange={value => onChangePeriodicGroup(record, value)}
                  className={css`
                    flex-grow: 1;
                    width: 70%;
                  `}
                  showSearch
                  optionFilterProp="children"
                  allowClear
                  placeholder="Bitte auswählen..."
                >
                  {[...periodicGroups]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(group => (
                      <Option key={group.id} value={group.id}>
                        {group.name}
                      </Option>
                    ))}
                </Select>
                <Button
                  type="link"
                  disabled={!entry}
                  onClick={() => populateAllPeriodicGroups(entry?.periodicGroupId ?? null)}
                >
                  Für alle
                </Button>
              </div>
            );
          },
        },
      ]}
    />
  );
};
