import React from 'react';
import { SelectedParameter } from '../../../hooks/store/useAppStore.ts';
import { SheetAccordion } from './sheets/SheetAccordion.tsx';
import { css } from '@emotion/css';
import { Empty } from 'antd';
import {
  ParameterSelection_DoctorSheetFragment,
  ParameterSelection_RequestableParameterFragment,
  ParameterToAddInput,
} from '../../../graphql/generated/graphql.ts';

export const Sheets: React.FC<{
  sheets: readonly ParameterSelection_DoctorSheetFragment[];
  params: readonly ParameterSelection_RequestableParameterFragment[];
  selectedParams: SelectedParameter[];
  onAdd: (inputs: ParameterToAddInput[]) => void;
  onRemove: (paramId: string) => void;
  onInfo: (param: ParameterSelection_RequestableParameterFragment) => void;
  reorder: boolean;
  flipParamNames: boolean;
}> = ({ sheets, params, selectedParams, onAdd, onRemove, onInfo, reorder, flipParamNames }) => {
  const doctorSheet = sheets.find(it => !!it.doctorId);
  const labSheets = sheets.filter(it => !!it.labId);

  if (!doctorSheet?.data.length && !labSheets.some(it => it.data.length)) {
    return <Empty description="Keine Sheets verfügbar" image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <div
      className={css`
        overflow-y: auto;
      `}
    >
      {doctorSheet && doctorSheet.data.length > 0 && (
        <SheetAccordion
          sheet={doctorSheet}
          params={params}
          selectedParams={selectedParams}
          onAdd={onAdd}
          onRemove={onRemove}
          onInfo={onInfo}
          reorder={reorder}
          flipParamNames={flipParamNames}
        />
      )}
      {labSheets
        .filter(it => it.data.length > 0)
        .map(labSheet => (
          <SheetAccordion
            key={labSheet.labId}
            sheet={labSheet}
            params={params}
            selectedParams={selectedParams}
            onAdd={onAdd}
            onRemove={onRemove}
            onInfo={onInfo}
            reorder={reorder}
            flipParamNames={flipParamNames}
          />
        ))}
    </div>
  );
};
