import { FC, useState } from 'react';
import { arrayMove } from '@dnd-kit/sortable';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import { SortableSheets } from './SortableSheets';
import { SheetDataWithId, SheetGroupWithId } from './types';
import { v4 as uuidv4 } from 'uuid';
import { Button, Empty, Input, Modal, Select, Space, Typography } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import { LoadingIndicator } from '../LoadingIndicator';
import { FragmentType, getFragmentData, graphql } from '../../graphql/generated';

export const SheetEditorParameterFragment = graphql(`
  fragment SheetEditor_Parameter on Parameter {
    id
    shortName
    longName
    deactivated
    synonyms
    lab {
      id
      shortName
    }
  }
`);

export const SheetEditor: FC<{
  title?: string;
  loading?: boolean;
  sheets: SheetDataWithId[];
  onChange: (sheets: SheetDataWithId[]) => void;
  parameters: FragmentType<typeof SheetEditorParameterFragment>[];
  showLab: boolean;
  flipParamNames: boolean;
}> = ({ title, loading, sheets, onChange, parameters, showLab, flipParamNames }) => {
  const [sheetName, setSheetName] = useState('');
  const [renameSheet, setRenameSheet] = useState<SheetDataWithId | null>(null);
  const [renameGroup, setRenameGroup] = useState<SheetGroupWithId | null>(null);
  const [copyGroup, setCopyGroup] = useState<SheetGroupWithId | null>(null);
  const [paramAddGroup, setParamAddGroup] = useState<SheetGroupWithId | null>(null);
  const [selectedSheetId, setSelectedSheetId] = useState<string | null>(null);
  const [selectedParameterIds, setSelectedParameterIds] = useState<string[]>([]);
  const [addGroupSheet, setAddGroupSheet] = useState<SheetDataWithId | null>(null);
  const [currentText, setCurrentText] = useState<string>('');

  const handleSheetDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over?.id || active.id === over.id) {
      return;
    }
    const oldIndex = sheets.findIndex(it => it.id === active.id);
    const newIndex = sheets.findIndex(it => it.id === over.id);
    onChange(arrayMove(sheets, oldIndex, newIndex));
  };

  const handleGroupDragEnd = (sheetId: string, event: DragEndEvent) => {
    const { active, over } = event;
    if (!over?.id || active.id === over.id) {
      return;
    }
    const sheet = sheets.find(it => it.id === sheetId)!;
    const oldIndex = sheet.groups.findIndex(it => it.id === active.id);
    const newIndex = sheet.groups.findIndex(it => it.id === over.id);
    const newGroups = arrayMove(sheet.groups, oldIndex, newIndex);
    onChange(
      sheets.map(sheet => {
        if (sheet.id === sheetId) {
          return {
            ...sheet,
            groups: newGroups,
          };
        }
        return sheet;
      })
    );
  };

  const handleParamDragEnd = (sheetId: string, groupId: string, event: DragEndEvent) => {
    const { active, over } = event;
    if (!over?.id || active.id === over.id) {
      return;
    }
    const sheet = sheets.find(it => it.id === sheetId)!;
    const group = sheet.groups.find(it => it.id === groupId)!;
    const oldIndex = group.parameters.findIndex(it => it.id === active.id);
    const newIndex = group.parameters.findIndex(it => it.id === over?.id);
    const newParams = arrayMove(group.parameters, oldIndex, newIndex);
    onChange(
      sheets.map(sheet => {
        if (sheet.id === sheetId) {
          return {
            ...sheet,
            groups: sheet.groups.map(group => {
              if (group.id === groupId) {
                return {
                  ...group,
                  parameters: newParams,
                };
              }
              return group;
            }),
          };
        }
        return sheet;
      })
    );
  };

  const handleSheetRemove = (sheetId: string) => onChange(sheets.filter(it => it.id !== sheetId));

  const handleGroupRemove = (sheetId: string, groupId: string) => {
    onChange(
      sheets.map(sheet => {
        if (sheet.id === sheetId) {
          return {
            ...sheet,
            groups: sheet.groups.filter(it => it.id !== groupId),
          };
        }
        return sheet;
      })
    );
  };

  const handleParamRemove = (sheetId: string, groupId: string, paramId: string) => {
    onChange(
      sheets.map(sheet => {
        if (sheet.id === sheetId) {
          return {
            ...sheet,
            groups: sheet.groups.map(group => {
              if (group.id === groupId) {
                return {
                  ...group,
                  parameters: group.parameters.filter(it => it.id !== paramId),
                };
              }
              return group;
            }),
          };
        }
        return sheet;
      })
    );
  };

  const handleSheetAdd = () => {
    onChange([
      {
        id: uuidv4(),
        name: sheetName,
        groups: [],
      },
      ...sheets,
    ]);
    setSheetName('');
  };

  const startGroupAdd = (sheetId: string) => {
    const sheet = sheets.find(it => it.id === sheetId);
    if (sheet) {
      setCurrentText('');
      setAddGroupSheet(sheet);
    }
  };

  const endGroupAdd = () => setAddGroupSheet(null);

  const handleGroupAdd = () => {
    onChange(
      sheets.map(sheet => {
        if (sheet.id === addGroupSheet?.id) {
          return {
            ...sheet,
            groups: [
              {
                id: uuidv4(),
                name: currentText,
                parameters: [],
              },
              ...sheet.groups,
            ],
          };
        }
        return sheet;
      })
    );
    setAddGroupSheet(null);
  };

  const startSheetRename = (sheetId: string) => {
    const sheet = sheets.find(it => it.id === sheetId);
    if (sheet) {
      setCurrentText(sheet.name);
      setRenameSheet(sheet);
    }
  };

  const endSheetRename = () => setRenameSheet(null);

  const handleRenameSheet = () => {
    onChange(
      sheets.map(sheet => {
        if (sheet.id === renameSheet?.id) {
          return {
            ...sheet,
            name: currentText,
          };
        }
        return sheet;
      })
    );
    endSheetRename();
  };

  const startGroupRename = (sheetId: string, groupId: string) => {
    const group = sheets.find(it => it.id === sheetId)?.groups.find(it => it.id === groupId);
    if (group) {
      setCurrentText(group.name);
      setRenameGroup(group);
    }
  };

  const endGroupRename = () => setRenameGroup(null);

  const handleRenameGroup = () => {
    onChange(
      sheets.map(sheet => {
        return {
          ...sheet,
          groups: sheet.groups.map(group => {
            if (renameGroup?.id === group.id) {
              return {
                ...group,
                name: currentText,
              };
            }
            return group;
          }),
        };
      })
    );
    endGroupRename();
  };

  const startGroupCopy = (sheetId: string, groupId: string) => {
    const group = sheets.find(it => it.id === sheetId)?.groups.find(it => it.id === groupId);
    if (group) {
      setCopyGroup(group);
      setSelectedSheetId(null);
    }
  };

  const endGroupCopy = () => setCopyGroup(null);

  const handleCopyGroup = () => {
    onChange(
      sheets.map(sheet => ({
        ...sheet,
        groups: selectedSheetId === sheet.id ? [{ ...copyGroup!, id: uuidv4() }, ...sheet.groups] : sheet.groups,
      }))
    );
    endGroupCopy();
  };

  const startParamAdd = (sheetId: string, groupId: string) => {
    const group = sheets.find(it => it.id === sheetId)?.groups.find(it => it.id === groupId);
    if (group) {
      setParamAddGroup(group);
      setSelectedParameterIds([]);
    }
  };

  const endParamAdd = () => setParamAddGroup(null);

  const handleParamAdd = () => {
    onChange(
      sheets.map(sheet => ({
        ...sheet,
        groups: sheet.groups.map(group => {
          if (paramAddGroup?.id === group.id) {
            const params = selectedParameterIds
              .filter(it => !group.parameters.some(p => p.paramId === it))
              .map(it => {
                const param = parameters
                  .map(it => getFragmentData(SheetEditorParameterFragment, it))
                  .find(param => param.id === it);
                return {
                  id: uuidv4(),
                  paramId: param?.id ?? 'ERROR',
                  labShortName: param?.lab?.shortName ?? 'ERROR',
                  shortName: param?.shortName ?? 'ERROR',
                  longName: param?.longName ?? 'ERROR',
                };
              });

            return {
              ...group,
              parameters: [...params, ...group.parameters],
            };
          }
          return group;
        }),
      }))
    );
    endParamAdd();
  };

  return (
    <>
      <PageHeader
        title={title}
        style={{ padding: 0, paddingBottom: 'inherit' }}
        extra={[
          <form
            key="addForm"
            onSubmit={e => {
              e.preventDefault();
              handleSheetAdd();
            }}
          >
            <Space direction="horizontal">
              <Input
                placeholder="Name"
                maxLength={50}
                allowClear
                autoFocus
                value={sheetName}
                onChange={e => setSheetName(e.target.value)}
              />
              <Button htmlType="submit" type="primary" disabled={!sheetName || loading} icon={<PlusCircleOutlined />}>
                Sheet hinzufügen
              </Button>
            </Space>
          </form>,
        ]}
      />
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          {!sheets.length && <Empty description="Keine Sheets vorhanden" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          <SortableSheets
            sheets={sheets}
            parameters={parameters}
            onSheetDragEnd={handleSheetDragEnd}
            onSheetRemove={handleSheetRemove}
            onSheetEdit={startSheetRename}
            onGroupAdd={startGroupAdd}
            onGroupEdit={startGroupRename}
            onGroupCopy={startGroupCopy}
            onGroupDragEnd={handleGroupDragEnd}
            onGroupRemove={handleGroupRemove}
            onParamDragEnd={handleParamDragEnd}
            onParamRemove={handleParamRemove}
            onParamAdd={startParamAdd}
            showLab={showLab}
            flipParamNames={flipParamNames}
          />
        </>
      )}
      <Modal
        title={`Sheet ${renameSheet?.name} umbenennen`}
        open={!!renameSheet}
        onCancel={endSheetRename}
        onOk={handleRenameSheet}
        okButtonProps={{ disabled: !currentText.length }}
      >
        <Input placeholder="Name" maxLength={50} value={currentText} onChange={e => setCurrentText(e.target.value)} />
      </Modal>
      <Modal
        title={`Gruppe ${renameGroup?.name} umbenennen`}
        open={!!renameGroup}
        onCancel={endGroupRename}
        onOk={handleRenameGroup}
        okButtonProps={{ disabled: !currentText.length }}
      >
        <Input placeholder="Name" maxLength={50} value={currentText} onChange={e => setCurrentText(e.target.value)} />
      </Modal>
      <Modal
        title={`Gruppe zu Sheet ${addGroupSheet?.name} hinzufügen`}
        open={!!addGroupSheet}
        onCancel={endGroupAdd}
        onOk={handleGroupAdd}
        okButtonProps={{ disabled: !currentText.length }}
      >
        <Input placeholder="Name" maxLength={50} value={currentText} onChange={e => setCurrentText(e.target.value)} />
      </Modal>
      <Modal
        title={`Gruppe ${copyGroup?.name} zu Sheet kopieren`}
        open={!!copyGroup}
        onCancel={endGroupCopy}
        onOk={handleCopyGroup}
        okButtonProps={{ disabled: !selectedSheetId }}
      >
        <Select
          style={{ width: '100%' }}
          placeholder="Bitte Sheet auswählen"
          value={selectedSheetId}
          onChange={v => setSelectedSheetId(v)}
          options={sheets.map(it => ({
            value: it.id,
            label: it.name,
          }))}
        />
      </Modal>
      <Modal
        title={`Parameter zu Gruppe ${paramAddGroup?.name} hinzufügen`}
        open={!!paramAddGroup}
        onCancel={endParamAdd}
        onOk={handleParamAdd}
        okButtonProps={{ disabled: !selectedParameterIds.length }}
        maskClosable={false}
      >
        <Select
          mode="multiple"
          maxTagCount="responsive"
          showSearch
          optionFilterProp="search"
          optionLabelProp="taglabel"
          style={{ width: '100%' }}
          placeholder="Bitte Parameter auswählen"
          value={selectedParameterIds}
          onChange={v => setSelectedParameterIds(v)}
          notFoundContent={<Empty description="Keine Parameter gefunden" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          options={parameters
            .map(it => getFragmentData(SheetEditorParameterFragment, it))
            .filter(p => !paramAddGroup?.parameters.some(gp => gp.paramId === p.id))
            .sort((a, b) =>
              flipParamNames ? a.longName.localeCompare(b.longName) : a.shortName.localeCompare(b.shortName)
            )
            .map(it => ({
              value: it.id,
              label: showLab ? (
                <>
                  <div>{flipParamNames ? `${it.longName} - ${it.shortName}` : `${it.shortName} - ${it.longName}`}</div>
                  <Typography.Text type="secondary">{it.lab.shortName}</Typography.Text>
                </>
              ) : flipParamNames ? (
                `${it.longName} - ${it.shortName}`
              ) : (
                `${it.shortName} - ${it.longName}`
              ),
              search: `${it.shortName} ${it.longName} ${it.synonyms.join(' ')}`,
              taglabel: flipParamNames ? it.longName : it.shortName,
              disabled: paramAddGroup?.parameters.some(p => p.paramId === it.id),
            }))}
        />
      </Modal>
    </>
  );
};
