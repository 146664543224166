import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ImportFileParseResult } from '../../utils/aisImport';
import {
  BillingInfo,
  BillingType,
  ParameterSelection_RequestableParameterFragment,
  ParameterToAddInput,
  PatientData,
} from '../../graphql/generated/graphql.ts';

export interface SelectedParameter extends ParameterSelection_RequestableParameterFragment {
  billingInfo: BillingInfo;
}

export interface ParamQueue {
  parameters: ParameterToAddInput[];
  selectedParameters: SelectedParameter[];
  chooseBilling: boolean;
  ignoredParameters: ParameterSelection_RequestableParameterFragment[];
}

export const initialParamQueue: ParamQueue = {
  parameters: [],
  selectedParameters: [],
  chooseBilling: false,
  ignoredParameters: [],
};

export interface Localization {
  paramId: string;
  specimenId: string;
  location: string;
}

export interface AppState {
  patientData: PatientData | null;
  setPatientData: (patientData: PatientData | null) => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFormData: (formData: any) => void;

  paramQueue: ParamQueue;
  setParamQueue: (callback: (state: AppState) => ParamQueue) => void;

  parameterIdsWithUnknownBilling: string[];
  setParameterIdsWithUnknownBilling: (parameterIdsWithUnknownBilling: string[]) => void;

  diagnose: string;
  setDiagnose: (diagnose: string) => void;

  labInfo: string;
  setLabInfo: (labInfo: string) => void;

  acute: boolean;
  setAcute: (acute: boolean) => void;

  additionalData: Record<string, string>;
  setAdditionalData: (additionalData: Record<string, string>) => void;

  localizations: Localization[];
  setLocalizations: (localizations: Localization[]) => void;

  additionalDataInvalidFields: string[];
  setAdditionalDataInvalidFields: (additionalDataInvalidFields: string[]) => void;

  sampledAt: string | null;
  setSampledAt: (sampledAt: string | null) => void;

  requestIdForUpdate: string;
  setRequestIdForUpdate: (requestIdForUpdate: string) => void;

  requestIdForReorder: string;
  setRequestIdForReorder: (requestIdForReorder: string) => void;

  requestIdForNew: string;
  setRequestIdForNew: (requestIdForNew: string) => void;

  openRequestsModalEnabled: boolean;
  setOpenRequestsModalEnabled: (openRequestsModalEnabled: boolean) => void;

  tempAisImportResult: ImportFileParseResult | null;
  setTempAisImportResult: (tempAisImportResult: ImportFileParseResult | null) => void;

  aisImportResult: ImportFileParseResult | null;
  setAisImportResult: (AisImportResult: ImportFileParseResult | null) => void;

  executeRequestCopy: boolean;
  setExecuteRequestCopy: (executeRequestCopy: boolean) => void;

  preferredBillingType: BillingType | null;
  setPreferredBillingType: (billingType: BillingType | null) => void;

  reset: () => void;
}

export const useAppStore = create<AppState>()(
  devtools(
    set => ({
      patientData: null,
      setPatientData: patientData => set({ patientData }),

      formData: null,
      setFormData: formData => set({ formData }),

      paramQueue: initialParamQueue,
      setParamQueue: callback => set(state => ({ paramQueue: callback(state) })),

      parameterIdsWithUnknownBilling: [],
      setParameterIdsWithUnknownBilling: parameterIdsWithUnknownBilling => set({ parameterIdsWithUnknownBilling }),

      diagnose: '',
      setDiagnose: diagnose => set({ diagnose }),

      labInfo: '',
      setLabInfo: labInfo => set({ labInfo }),

      acute: false,
      setAcute: acute => set({ acute }),

      additionalData: {},
      setAdditionalData: additionalData => set({ additionalData }),

      localizations: [],
      setLocalizations: localizations => set({ localizations }),

      additionalDataInvalidFields: [],
      setAdditionalDataInvalidFields: additionalDataInvalidFields => set({ additionalDataInvalidFields }),

      sampledAt: null,
      setSampledAt: sampledAt => set({ sampledAt }),

      requestIdForUpdate: '',
      setRequestIdForUpdate: requestIdForUpdate => set({ requestIdForUpdate }),

      requestIdForReorder: '',
      setRequestIdForReorder: requestIdForReorder => set({ requestIdForReorder }),

      requestIdForNew: '',
      setRequestIdForNew: requestIdForNew => set({ requestIdForNew }),

      openRequestsModalEnabled: true,
      setOpenRequestsModalEnabled: openRequestsModalEnabled => set({ openRequestsModalEnabled }),

      aisImportResult: null,
      setAisImportResult: aisImportResult => set({ aisImportResult }),

      tempAisImportResult: null,
      setTempAisImportResult: tempAisImportResult => set({ tempAisImportResult }),

      executeRequestCopy: false,
      setExecuteRequestCopy: executeRequestCopy => set({ executeRequestCopy }),

      preferredBillingType: null,
      setPreferredBillingType: preferredBillingType => set({ preferredBillingType }),

      reset: () =>
        set({
          patientData: null,
          parameterIdsWithUnknownBilling: [],
          paramQueue: initialParamQueue,
          diagnose: '',
          labInfo: '',
          acute: false,
          additionalData: {},
          localizations: [],
          additionalDataInvalidFields: [],
          sampledAt: null,
          requestIdForUpdate: '',
          requestIdForReorder: '',
          requestIdForNew: '',
          openRequestsModalEnabled: true,
          formData: null,
          aisImportResult: null,
          tempAisImportResult: null,
          executeRequestCopy: false,
          preferredBillingType: null,
        }),
    }),
    { name: 'app-store' }
  )
);
