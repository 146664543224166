import React from 'react';
import { MainContent } from '../../components/MainContent';
import { PageHeader } from '@ant-design/pro-components';
import { App, Button, Form, Input, Select, Switch } from 'antd';
import { LinkOutlined, SaveOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { SystemStatusInput, SystemStatusType } from '../../graphql/generated/graphql';
import { graphql } from '../../graphql/generated';
import { useMutation, useQuery } from '@apollo/client';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { translateSystemStatusType } from '../../utils/enumHelpers';

const UPDATE_SYSTEM_STATUS_MUTATION = graphql(`
  mutation UpdateSystemStatus($input: SystemStatusInput!) {
    updateSystemStatus(input: $input) {
      id
    }
  }
`);

const SYSTEM_STATUS_QUERY = graphql(`
  query SystemStatus {
    systemStatus {
      id
      active
      heading
      content
      link
      type
    }
  }
`);

export const SystemStatus: React.FC = () => {
  const [form] = useForm<SystemStatusInput>();
  const [updateSystemStatusMutation, { loading: updateLoading }] = useMutation(UPDATE_SYSTEM_STATUS_MUTATION);
  const { message } = App.useApp();

  const save = async (values: SystemStatusInput) => {
    try {
      await updateSystemStatusMutation({
        variables: {
          input: { ...values, link: values.link.trim(), content: values.content.trim() },
        },
      });

      message.success('System Statusmeldung wurde aktualisiert');
    } catch {
      message.error('System Statusmeldung konnte nicht aktualisiert werden');
    }
  };

  const { data, loading } = useQuery(SYSTEM_STATUS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <MainContent size="medium">
      <PageHeader title="System Statusmeldung" style={{ padding: 0, paddingBottom: 'inherit' }} />
      <Form
        labelCol={{ xs: 3, xl: 4 }}
        colon={false}
        labelAlign="left"
        form={form}
        onFinish={save}
        initialValues={{
          type: data?.systemStatus?.type ?? SystemStatusType.INFO,
          active: data?.systemStatus?.active ?? false,
          link: data?.systemStatus?.link ?? '',
          content: data?.systemStatus?.content ?? '',
          heading: data?.systemStatus?.heading ?? '',
        }}
      >
        <Form.Item name="active" label="Aktiv">
          <Switch />
        </Form.Item>
        <Form.Item name="heading" label="Überschrift">
          <Input autoFocus />
        </Form.Item>
        <Form.Item name="content" label="Inhalt">
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item name="link" label="Link" rules={[{ type: 'url' }]}>
          <Input addonBefore={<LinkOutlined />} />
        </Form.Item>
        <Form.Item name="type" label="Typ">
          <Select>
            {Object.keys(SystemStatusType).map(it => (
              <Select.Option key={it} value={it}>
                {translateSystemStatusType(it)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          disabled={updateLoading || form.getFieldsError().some(({ errors }) => errors.length)}
          icon={<SaveOutlined />}
        >
          Speichern
        </Button>
      </Form>
    </MainContent>
  );
};
