const calculateChecksum = (svnr: string): number => {
  const weights = [3, 7, 9, 0, 5, 8, 4, 2, 1, 6];
  let sum = 0;

  for (let i = 0; i < weights.length; i++) {
    sum += weights[i] * parseInt(svnr[i]);
  }
  sum %= 11;

  return sum;
};

/** see https://de.wikipedia.org/wiki/Sozialversicherungsnummer#%C3%96sterreich */
export const isValidSvnr = (svnr: string): boolean => {
  if (svnr.length !== 10) {
    return false;
  }

  if (svnr.startsWith('0000')) {
    // LAB-164: is an Austrian special case ;=]
    return true;
  }

  if (!/^[0-9]+$/.test(svnr) || svnr.charAt(0) === '0') {
    return false;
  }

  return calculateChecksum(svnr) === parseInt(svnr.charAt(3));
};
