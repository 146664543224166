import React, { useEffect } from 'react';
import { App, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { InsuranceForm } from './InsuranceForm';
import { useForm } from 'antd/es/form/Form';
import { graphql } from '../../../graphql/generated';
import { Insurance } from '../Insurances.tsx';
import { InsuranceInput } from '../../../graphql/generated/graphql.ts';

const UPDATE_INSURANCE_MUTATION = graphql(`
  mutation UpdateInsurance($id: ID!, $input: InsuranceInput!) {
    updateInsurance(id: $id, input: $input) {
      id
    }
  }
`);

export const UpdateInsuranceModal: React.FC<{
  onClose: () => void;
  allInsurances: Insurance[];
  insurance: Insurance | null;
}> = ({ onClose, allInsurances, insurance }) => {
  const [updateInsuranceMutation, { loading }] = useMutation(UPDATE_INSURANCE_MUTATION);
  const [form] = useForm<InsuranceInput>();
  const { message } = App.useApp();

  const update = async (values: InsuranceInput) => {
    try {
      await updateInsuranceMutation({
        variables: {
          id: insurance?.id ?? '',
          input: values,
        },
      });

      message.success('Krankenkasse wurde aktualisiert');
      closeAndReset();
    } catch (e) {
      message.error('Krankenkasse konnte nicht aktualisiert werden: ' + e);
    }
  };

  const closeAndReset = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue({
      name: insurance?.name ?? '',
      shortName: insurance?.shortName ?? '',
      code: insurance?.code ?? '',
      mappings: insurance?.mappings ?? [],
    });
  }, [form, insurance]);

  return (
    <Modal
      width={768}
      title="Krankenkasse bearbeiten"
      okText="Speichern"
      okButtonProps={{ icon: <EditOutlined /> }}
      open={!!insurance}
      onCancel={closeAndReset}
      onOk={form.submit}
      confirmLoading={loading}
      destroyOnClose
      forceRender
    >
      <InsuranceForm
        form={form}
        onFinish={update}
        codeValidator={code => !allInsurances.filter(i => i.id !== insurance?.id).some(i => i.code === code)}
      />
    </Modal>
  );
};
