import React from 'react';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SheetDataWithId } from './types';
import { SortableGroupEntry } from './SortableGroupEntry.tsx';
import { Empty } from 'antd';
import { FragmentType } from '../../graphql/generated';
import type { SheetEditorParameterFragment } from './SheetEditor.tsx';

export const SortableGroups: React.FC<{
  sheet: SheetDataWithId;
  parameters: FragmentType<typeof SheetEditorParameterFragment>[];
  onGroupDragEnd: (sheetId: string, event: DragEndEvent) => void;
  onGroupRemove: (sheetId: string, groupId: string) => void;
  onGroupEdit: (sheetId: string, groupId: string) => void;
  onGroupCopy: (sheetId: string, groupId: string) => void;
  onParamAdd: (sheetId: string, groupId: string) => void;
  onParamDragEnd: (sheetId: string, groupId: string, event: DragEndEvent) => void;
  onParamRemove: (sheetId: string, groupId: string, paramId: string) => void;
  showLab: boolean;
  flipParamNames: boolean;
}> = ({
  sheet,
  parameters,
  onGroupDragEnd,
  onGroupRemove,
  onGroupEdit,
  onGroupCopy,
  onParamAdd,
  onParamDragEnd,
  onParamRemove,
  showLab,
  flipParamNames,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={e => onGroupDragEnd(sheet.id, e)}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext items={sheet.groups} strategy={verticalListSortingStrategy}>
        {!sheet.groups.length && <Empty description="Keine Gruppen vorhanden" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        {sheet.groups.map(group => (
          <SortableGroupEntry
            sheet={sheet}
            parameters={parameters}
            group={group}
            key={group.id}
            onRemove={() => onGroupRemove(sheet.id, group.id)}
            onEdit={() => onGroupEdit(sheet.id, group.id)}
            onCopy={() => onGroupCopy(sheet.id, group.id)}
            onParamAdd={() => onParamAdd(sheet.id, group.id)}
            onParamDragEnd={onParamDragEnd}
            onParamRemove={onParamRemove}
            showLab={showLab}
            flipParamNames={flipParamNames}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};
