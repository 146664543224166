import React from 'react';
import { Collapse, CollapseProps, theme, Typography } from 'antd';
import { css } from '@emotion/css';
import { SheetGroupCard } from './SheetGroupCard.tsx';
import { SelectedParameter } from '../../../../hooks/store/useAppStore.ts';
import {
  ParameterSelection_DoctorSheetFragment,
  ParameterSelection_RequestableParameterFragment,
  ParameterToAddInput,
} from '../../../../graphql/generated/graphql.ts';
import { SheetData } from '../../../../graphql/sheet';

export const SheetAccordion: React.FC<{
  sheet: ParameterSelection_DoctorSheetFragment;
  params: readonly ParameterSelection_RequestableParameterFragment[];
  selectedParams: SelectedParameter[];
  onAdd: (inputs: ParameterToAddInput[]) => void;
  onRemove: (paramId: string) => void;
  onInfo: (param: ParameterSelection_RequestableParameterFragment) => void;
  reorder: boolean;
  flipParamNames: boolean;
}> = ({ sheet, params, selectedParams, onAdd, onRemove, onInfo, reorder, flipParamNames }) => {
  const { token } = theme.useToken();

  const items: CollapseProps['items'] =
    sheet.data.map((sheetData: SheetData, index: number) => ({
      key: index,
      label: sheetData.name,
      extra: sheet.labId && <Typography.Text type="secondary">{sheet.lab?.name}</Typography.Text>,
      children: sheetData.groups.map((group, index) => (
        <SheetGroupCard
          key={index}
          sheet={sheetData}
          group={group}
          params={params}
          selectedParams={selectedParams}
          onAdd={onAdd}
          onRemove={onRemove}
          onInfo={onInfo}
          reorder={reorder}
          flipParamNames={flipParamNames}
        />
      )),
    })) ?? [];

  return (
    <Collapse
      destroyInactivePanel
      size="small"
      accordion
      items={items}
      className={css`
        .ant-collapse-header-text {
          font-weight: 600;
        }
        margin-bottom: ${token.marginSM}px;
      `}
    />
  );
};
