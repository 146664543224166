import React, { CSSProperties } from 'react';
import { Badge, theme } from 'antd';
import { css } from '@emotion/css';
import { TestTubeColorProperties } from '../utils/enumHelpers.ts';

const { useToken } = theme;

export const TestTubeColor: React.FC<{
  properties: TestTubeColorProperties;
  withText?: boolean;
  style?: CSSProperties;
}> = ({ properties, withText = true, style }) => {
  const { token } = useToken();
  return (
    <span
      className={css`
        white-space: nowrap;
      `}
    >
      <Badge
        style={style}
        className={css`
          & .ant-badge-status-dot {
            width: 8px !important;
            height: 8px !important;
            margin-right: ${token.paddingXXS}px;
          }
        `}
        color={properties.colorCode}
        dot
      />
      {withText ? properties.translation : ''}
    </span>
  );
};
