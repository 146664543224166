import React, { useEffect, useState } from 'react';
import { Button, Flex, Input, Popconfirm, Table, theme, Tooltip } from 'antd';
import { MainContent } from '../components/MainContent';
import { useQuery } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import { useDebounce } from 'use-debounce';
import { Markable } from '../components/Markable';
import { CheckOutlined, InfoCircleOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import { tableHoverPointer } from '../styles/globalCss';
import { graphql } from '../graphql/generated';
import { DoctorSearchQuery } from '../graphql/generated/graphql.ts';
import { useCurrentContextStore } from '../hooks/store/useCurrentContextStore.ts';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/css';

type Doctor = NonNullable<DoctorSearchQuery['doctorsForCurrentUser']>[number];

const DOCTOR_SEARCH_QUERY = graphql(`
  query DoctorSearch {
    doctorsForCurrentUser {
      id
      name
      firstName
      lastName
      zip
      city
      meAddress
      disabled
      assignedLabs {
        lab {
          id
          shortName
        }
        customerId
      }
    }
  }
`);

export const DoctorSearch: React.FC = () => {
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch] = useDebounce(search, 250);
  const [filteredDoctors, setFilteredDoctors] = useState<Doctor[]>([]);
  const { token } = theme.useToken();
  const { setCurrentDoctorId } = useCurrentContextStore();
  const navigate = useNavigate();

  const { data, loading } = useQuery(DOCTOR_SEARCH_QUERY, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000,
  });

  useEffect(() => {
    const result =
      data?.doctorsForCurrentUser?.filter(doctor => {
        if (debouncedSearch && debouncedSearch.length) {
          const searchValue = debouncedSearch.toLowerCase();
          return (
            doctor.name.toLowerCase().includes(searchValue) ||
            doctor.meAddress.toLowerCase().includes(searchValue) ||
            doctor.city.toLowerCase().includes(searchValue) ||
            doctor.zip.toLowerCase().includes(searchValue) ||
            doctor.firstName?.toLowerCase().includes(searchValue) ||
            doctor.lastName?.toLowerCase().includes(searchValue) ||
            doctor.assignedLabs.some(it => it.customerId.toLowerCase().includes(searchValue))
          );
        }
        return true;
      }) ?? [];
    setFilteredDoctors(result);
  }, [debouncedSearch, data]);

  const columns: ColumnsType<Doctor> = [
    {
      title: 'Anzeigename',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      defaultSortOrder: 'ascend',
      width: 130,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Aktiv',
      dataIndex: 'disabled',
      key: 'disabled',
      width: 70,
      sorter: (a, b) => Number(b.disabled) - Number(a.disabled),
      render: value => (!value ? <CheckOutlined /> : ''),
    },
    {
      title: 'ME-Adresse',
      dataIndex: 'meAddress',
      key: 'meAddress',
      ellipsis: true,
      width: 120,
      sorter: (a, b) => a.meAddress.localeCompare(b.meAddress),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Kd.Nr.',
      dataIndex: 'assignedLabs',
      key: 'assignedLabs',
      width: 90,
      render: (_, record) => (
        <Markable tokens={debouncedSearch ?? ''}>
          {record.assignedLabs
            .filter(it => it.customerId)
            .map(v => v.customerId)
            .join(', ')}
        </Markable>
      ),
      ellipsis: true,
    },
    {
      title: 'Vorname',
      dataIndex: 'firstName',
      key: 'firstName',
      ellipsis: true,
      width: 100,
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Nachname / Firma',
      dataIndex: 'lastName',
      key: 'lastName',
      ellipsis: true,
      width: 160,
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'PLZ',
      dataIndex: 'zip',
      key: 'zip',
      ellipsis: true,
      width: 80,
      sorter: (a, b) => a.zip.localeCompare(b.zip),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Ort',
      dataIndex: 'city',
      key: 'city',
      ellipsis: true,
      width: 100,
      sorter: (a, b) => a.city.localeCompare(b.city),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
  ];

  return (
    <MainContent>
      <PageHeader
        title="Zuweiserauswahl"
        extra={
          <Flex wrap gap={token.paddingXS} align="center">
            <Input
              autoFocus
              allowClear
              placeholder="Suche"
              value={search ?? ''}
              onChange={e => setSearch(e.target.value)}
              prefix={<SearchOutlined />}
              suffix={
                <Tooltip title="Suche nach Anzeigename, Kd.Nr., ME-Adresse, Ort, PLZ, Vorname, oder Nachname">
                  <InfoCircleOutlined />
                </Tooltip>
              }
              style={{ width: '300px' }}
            />
            <Popconfirm
              title="Achtung"
              overlayClassName={css`
                max-width: 400px;
              `}
              description="Bitte vergewissern Sie sich, dass der gewünschte Zuweiser nicht schon angelegt wurde, um Duplikate zu vermeiden."
              okText="Verstanden, Zuweiser anlegen"
              placement="bottomRight"
              onConfirm={() => navigate('/zuweiserauswahl/create')}
            >
              <Button icon={<PlusCircleOutlined />} type="primary">
                Zuweiser anlegen
              </Button>
            </Popconfirm>
          </Flex>
        }
        style={{ padding: 0, paddingBottom: 'inherit' }}
      />
      <Table<Doctor>
        scroll={{ x: 'max-content' }}
        rowKey={record => record.id}
        sticky={true}
        size="middle"
        showSorterTooltip={false}
        dataSource={filteredDoctors}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} bis ${range[1]} von ${total} Zuweisern`,
        }}
        loading={loading}
        columns={columns}
        onRow={record => ({
          onClick: () => {
            setCurrentDoctorId(record.id);
            navigate('/pv-uebersicht');
          },
        })}
        rowClassName={tableHoverPointer}
      />
    </MainContent>
  );
};
