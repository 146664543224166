import React, { useEffect } from 'react';
import { App, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { ThemeForm } from './ThemeForm.tsx';
import { useForm } from 'antd/es/form/Form';
import { graphql } from '../../../graphql/generated';
import { ThemeInput } from '../../../graphql/generated/graphql.ts';
import { Theme } from '../Themes.tsx';

const UPDATE_THEME_MUTATION = graphql(`
  mutation UpdateTheme($id: ID!, $input: ThemeInput!) {
    updateTheme(id: $id, input: $input) {
      id
    }
  }
`);

export const UpdateThemeModal: React.FC<{
  onClose: () => void;
  theme: Theme | null;
}> = ({ onClose, theme }) => {
  const [updateThemeMutation, { loading }] = useMutation(UPDATE_THEME_MUTATION);
  const [form] = useForm<ThemeInput>();
  const { message } = App.useApp();

  const update = async (values: ThemeInput) => {
    try {
      await updateThemeMutation({
        variables: {
          id: theme?.id ?? '',
          input: values,
        },
      });

      message.success('Theme wurde aktualisiert');
      closeAndReset();
    } catch (e) {
      message.error('Theme konnte nicht aktualisiert werden: ' + e);
    }
  };

  const closeAndReset = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue({
      name: theme?.name ?? '',
      colorPrimary: theme?.colorPrimary ?? '',
      controlItemBgActive: theme?.controlItemBgActive ?? '',
      controlItemBgActiveHover: theme?.controlItemBgActiveHover ?? '',
      colorBgHeader: theme?.colorBgHeader ?? '',
      colorBgTrigger: theme?.colorBgTrigger ?? '',
      logoData: theme?.logoData ?? '',
    });
  }, [form, theme]);

  return (
    <Modal
      width={768}
      title="Theme bearbeiten"
      okText="Speichern"
      okButtonProps={{ icon: <EditOutlined /> }}
      open={!!theme}
      onCancel={closeAndReset}
      onOk={form.submit}
      confirmLoading={loading}
      destroyOnClose
      forceRender
    >
      <ThemeForm form={form} onFinish={update} />
    </Modal>
  );
};
