import React, { useCallback, useEffect } from 'react';
import { App, Modal, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { aisImport } from '../utils/aisImport';
import { CustomEventType } from '../@types/CustomEventType';
import { useAppStore } from '../hooks/store/useAppStore';
import { useShallow } from 'zustand/react/shallow';
import { useDoctorHasAis } from '../hooks/useDoctorHasAis';
import { useAuth } from 'react-oidc-context';
import { useCurrentContextStore } from '../hooks/store/useCurrentContextStore.ts';
import { hasFrontDeskRole } from '../utils/user.ts';

export const ImportFileWatcher: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { message } = App.useApp();

  const {
    patientData,
    setPatientData,
    setDiagnose,
    setAdditionalData,
    setAdditionalDataInvalidFields,
    reset,
    tempAisImportResult,
    setTempAisImportResult,
    setAisImportResult,
  } = useAppStore(
    useShallow(state => ({
      reset: state.reset,
      patientData: state.patientData,
      setPatientData: state.setPatientData,
      setDiagnose: state.setDiagnose,
      setAdditionalData: state.setAdditionalData,
      setAdditionalDataInvalidFields: state.setAdditionalDataInvalidFields,
      tempAisImportResult: state.tempAisImportResult,
      setTempAisImportResult: state.setTempAisImportResult,
      setAisImportResult: state.setAisImportResult,
    }))
  );

  const { currentDoctorId, setCurrentDoctorId, primaryDoctorId } = useCurrentContextStore();
  const hasAis = useDoctorHasAis(hasFrontDeskRole(auth.user) ? primaryDoctorId : currentDoctorId);

  useEffect(() => {
    if (!window.nativeApi) {
      return;
    }

    if (!hasAis) {
      console.log('unregistering watcher');
      window.nativeApi.unwatchImportFile();
      return;
    }

    console.log('registering watcher');
    window.nativeApi.watchImportFile(async () => {
      // the desktop api allows exactly one registered listener, so we simply broadcast the event here
      document.dispatchEvent(new Event(CustomEventType.AIS_IMPORT_FILE_CHANGE));

      try {
        const result = await aisImport(
          auth.user?.access_token ?? '',
          hasFrontDeskRole(auth.user) ? primaryDoctorId : currentDoctorId
        );

        setTempAisImportResult(result);
        window.nativeApi?.bringToFront();
      } catch (e) {
        if (e instanceof Error) {
          message.error(e.message);
          return;
        }
        if (e instanceof String) {
          message.error(e);
        }
      }
    });
  }, [primaryDoctorId, auth, hasAis, currentDoctorId, setTempAisImportResult, message]);

  const setStateAndNavigate = useCallback(() => {
    if (!tempAisImportResult) {
      return;
    }

    reset();
    setAisImportResult(tempAisImportResult);
    setPatientData(tempAisImportResult.patientData);
    setDiagnose(tempAisImportResult.diagnose);
    setAdditionalData(tempAisImportResult.additionalData);
    setAdditionalDataInvalidFields(tempAisImportResult.invalidAdditionalDataFields);

    if (tempAisImportResult.switchToDoctorId) {
      setCurrentDoctorId(tempAisImportResult.switchToDoctorId);
    }

    if (tempAisImportResult.invalidPatientFields.length > 0) {
      navigate('/anforderung/patient-erfassung', {
        state: { invalidFields: tempAisImportResult.invalidPatientFields, validateInitial: true },
      });
    } else {
      navigate('/anforderung/parameterauswahl');
    }
    setTempAisImportResult(null);
  }, [
    tempAisImportResult,
    reset,
    setAisImportResult,
    setPatientData,
    setDiagnose,
    setAdditionalData,
    setAdditionalDataInvalidFields,
    setTempAisImportResult,
    setCurrentDoctorId,
    navigate,
  ]);

  useEffect(() => {
    if (!patientData && tempAisImportResult) {
      setStateAndNavigate();
    }
  }, [patientData, tempAisImportResult, setStateAndNavigate]);

  return (
    <Modal
      title="Neuer Patient geladen"
      open={!!patientData && !!tempAisImportResult}
      onCancel={() => setTempAisImportResult(null)}
      onOk={() => setStateAndNavigate()}
      okText="Ja"
      cancelText="Nein"
    >
      Wollen Sie die aktuelle Anforderung für{' '}
      <Typography.Text strong>
        {patientData?.firstName} {patientData?.lastName}
      </Typography.Text>{' '}
      abbrechen und eine neue Anforderung für{' '}
      <Typography.Text strong>
        {tempAisImportResult?.patientData.firstName} {tempAisImportResult?.patientData.lastName}
      </Typography.Text>{' '}
      starten?
    </Modal>
  );
};
