import React, { CSSProperties } from 'react';
import { Select, Tag, Typography } from 'antd';
import { translateDoctorSalutation } from '../utils/enumHelpers.ts';
import { DoctorSalutation } from '../graphql/generated/graphql.ts';
import { WarningOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';

const { Option } = Select;

type Doctor = {
  id: string;
  meAddress: string;
  name: string;
  firstName: string;
  lastName: string;
  salutation: DoctorSalutation;
  preTitle: string;
  postTitle: string;
  disabled: boolean;
};

const doctorName = (doctor: Doctor): string => {
  const name = doctor.name !== doctor.lastName ? doctor.name + ' - ' : '';
  const salutation = translateDoctorSalutation(doctor.salutation) + ' ';
  const preTitle = doctor.preTitle ? doctor.preTitle + ' ' : '';
  const firstName = doctor.firstName ? doctor.firstName + ' ' : '';
  const lastName = doctor.lastName ? doctor.lastName + (doctor.postTitle ? '' : ' ') : '';
  const postTitle = doctor.postTitle ? ', ' + doctor.postTitle : '';

  return name + salutation + preTitle + firstName + lastName + postTitle;
};

interface DoctorSelectProps {
  id?: string;
  multiple?: boolean;
  style?: CSSProperties;
  doctors: Doctor[];
  selected?: string | string[];
  onChange?: (value: string | string[]) => void;
  allowClear?: boolean;
}

export const DoctorSelect: React.FC<DoctorSelectProps> = React.memo(
  ({ id, multiple, style, doctors, selected, onChange, allowClear = true }) => {
    return (
      <Select
        id={id}
        showSearch
        allowClear={allowClear}
        mode={multiple ? 'multiple' : undefined}
        style={style}
        onChange={onChange}
        value={selected}
        placeholder="Suche Zuweiser mit Name und ME-Adresse"
        optionFilterProp="search"
        optionLabelProp="taglabel"
      >
        {[...doctors]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(doctor => {
            const name = doctorName(doctor);
            return (
              <Option key={doctor.id} value={doctor.id} taglabel={name} search={`${name} ${doctor.meAddress}`}>
                <div>{name}</div>
                <Typography.Text type="secondary">
                  {doctor.disabled && (
                    <Tag
                      color="warning"
                      className={css`
                        margin: 0;
                      `}
                      icon={<WarningOutlined />}
                    >
                      Deaktiviert
                    </Tag>
                  )}{' '}
                  {doctor.meAddress}
                </Typography.Text>
              </Option>
            );
          })}
      </Select>
    );
  }
);
