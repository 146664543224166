import { useQuery } from '@apollo/client';
import { hasAnyRelevantRole } from '../utils/user';
import { Theme } from '../graphql/generated/graphql.ts';
import { graphql } from '../graphql/generated';
import { useAuth } from 'react-oidc-context';

export const DOCTOR_THEME_QUERY = graphql(`
  query DoctorTheme($doctorId: ID!) {
    doctor(id: $doctorId) {
      id
      theme {
        id
        name
        colorBgHeader
        colorBgTrigger
        colorPrimary
        controlItemBgActive
        controlItemBgActiveHover
        logoData
      }
    }
  }
`);

export const useDoctorTheme = (doctorId: string): Theme | null | undefined => {
  const auth = useAuth();
  const hasAnyRole = hasAnyRelevantRole(auth.user);

  const { data, previousData } = useQuery(DOCTOR_THEME_QUERY, {
    variables: {
      doctorId: doctorId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !doctorId || !hasAnyRole,
  });

  if (data) {
    // we actually have the current data
    return data.doctor?.theme;
  }

  // when we don't have the current data (e.g. loading state) return the prev data if there is one
  return previousData?.doctor?.theme;
};
