import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

export type SelectedParamsSortOrder =
  | 'lastAdded'
  | 'firstAdded'
  | 'shortNameAsc'
  | 'shortNameDesc'
  | 'longNameAsc'
  | 'longNameDesc'
  | 'priceAsc'
  | 'priceDesc'
  | 'billingType';

export interface TableColumnSetting {
  title: string;
  id: string;
  visible: boolean;
  checkable: boolean;
}

export interface UserSettingsState {
  selectedParamsSortOrder: SelectedParamsSortOrder;
  setSelectedParamsSortOrder: (selectedParamsSortOrder: SelectedParamsSortOrder) => void;

  tableColumnSettings: Record<string, TableColumnSetting[]>;
  updateTableColumnSettings: (tableKey: string, tableColumnSettings: TableColumnSetting[]) => void;
}

export const userSettingsStoreDefaultName = 'labrador-user-settings-store';

export const useUserSettingsStore = create<UserSettingsState>()(
  persist(
    devtools(set => ({
      selectedParamsSortOrder: 'lastAdded',
      setSelectedParamsSortOrder: selectedParamsSortOrder => set({ selectedParamsSortOrder }),

      tableColumnSettings: {},
      updateTableColumnSettings: (tableKey, tableColumnSettings) =>
        set(state => ({ tableColumnSettings: { ...state.tableColumnSettings, [tableKey]: tableColumnSettings } })),
    })),
    { name: userSettingsStoreDefaultName, storage: createJSONStorage(() => localStorage) }
  )
);
