import { Table } from 'antd';
import React from 'react';

export const AdditionalDataTable: React.FC<{ additionalData: Record<string, string>; inlineScroll?: boolean }> = ({
  additionalData,
  inlineScroll,
}) => {
  return (
    <Table
      scroll={inlineScroll ? { y: '300px' } : undefined}
      rowKey={row => row.key}
      size="small"
      showSorterTooltip={false}
      pagination={false}
      dataSource={Object.entries(additionalData).map(([k, v]) => ({
        key: k,
        value: v,
      }))}
      columns={[
        {
          title: 'Kennung',
          dataIndex: 'key',
          key: 'key',
          ellipsis: true,
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.key.localeCompare(b.key),
        },
        {
          title: 'Wert',
          dataIndex: 'value',
          key: 'value',
          ellipsis: true,
          sorter: (a, b) => a.value.localeCompare(b.value),
        },
      ]}
    />
  );
};
