import React from 'react';

export const AddPatientSvg: React.FC = () => (
  <svg viewBox="0 0 50 58" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.055 53.462c-11.186 0-18.561-3.671-20.556-4.797a.502.502 0 0 1-.244-.338 20.298 20.298 0 0 1-.251-3.668l.01-.277c.087-2.801.428-5.862 3.397-6.92 5.565-1.433 10.643-6.785 10.694-6.839a.5.5 0 1 1 .729.686c-.214.228-5.324 5.616-11.131 7.109-2.332.833-2.608 3.411-2.688 5.98 0 .048-.003.107-.006.165l-.004.107a20.64 20.64 0 0 0 .191 3.237c2.204 1.209 9.27 4.554 19.859 4.554 10.641 0 17.66-3.336 19.848-4.542a22.213 22.213 0 0 0 .192-3.232 2.036 2.036 0 0 1-.012-.293c-.079-2.553-.355-5.131-2.73-5.977-5.763-1.48-10.873-6.869-11.088-7.097a.5.5 0 1 1 .729-.685c.05.054 5.13 5.405 10.652 6.825 3.01 1.072 3.352 4.132 3.438 6.919a2.004 2.004 0 0 0 .008.24 21.206 21.206 0 0 1-.248 3.719.5.5 0 0 1-.244.337c-1.977 1.123-9.3 4.787-20.545 4.787zm20.298-5.222z" />
    <path d="M21.105 1a12.626 12.626 0 0 1 6.021 1.56c2.28 1.304 5.055 4.104 5.331 10.033l.002.026.002.027c.037.368.855 9.073-3.492 13.975a10.489 10.489 0 0 1-7.919 3.344 10.454 10.454 0 0 1-7.924-3.344c-4.253-4.797-3.582-13.075-3.492-14.003l.003-.026v-.025c.278-5.938 3.09-8.744 5.402-10.054A12.827 12.827 0 0 1 21.105 1M21.115 0a13.862 13.862 0 0 0-6.572 1.643c-2.53 1.433-5.607 4.489-5.905 10.878-.095.972-.8 9.646 3.739 14.764a11.425 11.425 0 0 0 8.656 3.681h.017a11.471 11.471 0 0 0 8.667-3.68c4.548-5.13 3.835-13.795 3.74-14.74-.298-6.383-3.337-9.426-5.834-10.855A13.617 13.617 0 0 0 21.115 0z" />
    <circle cx="37.992" cy="45.989" r="12.008" className="colored" />
    <path
      d="M44.497 45.489h-6.004v-6.005a.5.5 0 1 0-1 0v6.005h-6.005a.5.5 0 0 0 0 1h6.004v6.006a.5.5 0 1 0 1 0V46.49h6.005a.5.5 0 0 0 0-1z"
      fill="#fff"
    />
  </svg>
);
