import React from 'react';
import { Button, Col, Form, FormInstance, Input, Row } from 'antd';
import { DiagnoseCreateInput, DiagnoseUpdateInput } from '../../../../graphql/generated/graphql.ts';

export const DiagnoseForm: React.FC<{
  form: FormInstance;
  initialValues?: DiagnoseCreateInput;
  onFinish: (values: DiagnoseCreateInput | DiagnoseUpdateInput) => void;
  nameValidator: (name: string) => boolean;
}> = ({ form, initialValues, onFinish, nameValidator }) => {
  return (
    <Form layout="vertical" form={form} initialValues={initialValues} onFinish={onFinish}>
      <Row gutter={[16, 0]}>
        <Col span={6}>
          <Form.Item
            name="name"
            label="Bezeichnung"
            rules={[
              {
                required: true,
                whitespace: true,
              },
              {
                validator: (_, name) =>
                  nameValidator(name) ? Promise.resolve() : Promise.reject('Dieser Name ist bereits vergeben'),
              },
            ]}
          >
            <Input autoFocus />
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item
            name="text"
            label="Text"
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      {/* hack for enter button support */}
      <Button htmlType="submit" hidden />
    </Form>
  );
};
