import React, { ReactNode, useEffect, useState } from 'react';
import { App, Button, Flex, Modal, Popconfirm, Popover, Select, Tag, theme, Tooltip } from 'antd';
import {
  EditOutlined,
  FileTextOutlined,
  HomeOutlined,
  PhoneOutlined,
  PrinterOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { personAge, SHORT_DATE_FORMAT } from '../../utils/dateFormatUtils';
import { hasCompleteAddress, patientAddress } from '../../utils/patient';
import { PageHeader } from '@ant-design/pro-components';
import dayjs from 'dayjs';
import { css } from '@emotion/css';
import { usePrintEmptyLabel } from '../../hooks/usePrintEmptyLabel';
import { Forms } from '../forms/Forms.tsx';
import { BillingType, Gender, PatientData } from '../../graphql/generated/graphql.ts';
import { useCurrentContextStore } from '../../hooks/store/useCurrentContextStore.ts';
import { useAppStore } from '../../hooks/store/useAppStore.ts';
import { useShallow } from 'zustand/react/shallow';

const { useToken } = theme;

interface PatientHeaderProps {
  patientData: PatientData;
  insuranceName: string;
  patientBilling: boolean;
  availableBillingTypes: BillingType[];
  showDefaultBillingChooser: boolean;
  onEdit?: () => void;
  menu?: ReactNode;
}

export const PatientHeader: React.FC<PatientHeaderProps> = ({
  patientData,
  insuranceName,
  patientBilling,
  availableBillingTypes,
  showDefaultBillingChooser,
  onEdit,
  menu,
}) => {
  const { token } = useToken();
  const address = patientAddress(patientData);
  const addressComplete = hasCompleteAddress(patientData);
  const printEmptyLabel = usePrintEmptyLabel();
  const { currentDoctorId } = useCurrentContextStore();
  const { message } = App.useApp();

  const [formsOpen, setFormsOpen] = useState(false);

  const { preferredBillingType, setPreferredBillingType } = useAppStore(
    useShallow(state => ({
      preferredBillingType: state.preferredBillingType,
      setPreferredBillingType: state.setPreferredBillingType,
    }))
  );

  useEffect(() => {
    if (!preferredBillingType) {
      // auto
      return;
    }
    if (!availableBillingTypes.some(it => it === preferredBillingType)) {
      setPreferredBillingType(null);
    }
  }, [preferredBillingType, setPreferredBillingType, availableBillingTypes]);

  return (
    <PageHeader
      title={`${patientData.title} ${patientData.firstName} ${patientData.lastName}`}
      tags={[
        ...(onEdit
          ? [
              <Popconfirm
                key="edit"
                title={
                  <div style={{ maxWidth: '300px' }}>
                    Wenn Sie die Stammdaten des Patienten bearbeiten, dann werden sich dadurch möglicherweise die
                    anforderbaren Parameter und deren Verrechnung ändern.
                  </div>
                }
                onConfirm={onEdit}
                okText="Patient bearbeiten"
                cancelText="Abbrechen"
              >
                <Button key="edit" icon={<EditOutlined />} type="text" />
              </Popconfirm>,
            ]
          : []),
        ...(window.nativeApi
          ? [
              <Button
                key="empty-label"
                icon={<PrinterOutlined />}
                type="text"
                onClick={() => {
                  printEmptyLabel(
                    {
                      doctorId: currentDoctorId,
                      patientTitle: patientData.title,
                      patientLastName: patientData.lastName,
                      patientFirstName: patientData.firstName,
                      patientSvnr: patientData.svnr,
                    },
                    1,
                    () => message.error('Beim Drucken ist ein Fehler aufgetreten'),
                    () => message.success('Leer-Etikett wurde an den Drucker gesendet')
                  );
                }}
              />,
              <Button key="forms" icon={<FileTextOutlined />} type="text" onClick={() => setFormsOpen(true)} />,
            ]
          : []),
      ]}
      className={css`
        padding: ${token.paddingMD}px 0;
        .ant-page-header-heading {
          column-gap: ${token.marginMD}px;
        }
      `}
      avatar={{
        style: { flexShrink: 0 },
        children: patientData.firstName.charAt(0).toUpperCase() + patientData.lastName.charAt(0).toUpperCase(),
      }}
      extra={
        showDefaultBillingChooser ? (
          <Flex align="center" gap={token.paddingXS}>
            <span>Verrechnung</span>
            <Select
              options={[
                {
                  label: 'Automatisch',
                  value: '',
                },
                {
                  label: 'Privat an Zuweiser',
                  value: BillingType.DOCTOR,
                  disabled: !availableBillingTypes.some(it => it === BillingType.DOCTOR),
                },
                {
                  label: 'Privat an Patient',
                  value: BillingType.PATIENT,
                  disabled: !availableBillingTypes.some(it => it === BillingType.PATIENT),
                },
                {
                  label: 'Kassa (FA)',
                  value: BillingType.INSURANCE,
                  disabled: !availableBillingTypes.some(it => it === BillingType.INSURANCE),
                },
              ]}
              value={preferredBillingType ?? ''}
              onChange={billingType => setPreferredBillingType(billingType ? (billingType as BillingType) : null)}
              style={{ width: '170px' }}
            />
          </Flex>
        ) : undefined
      }
    >
      <div
        className={css`
          padding-bottom: ${token.paddingSM}px;
          .ant-tag {
            margin-bottom: ${token.marginXXS}px;
          }
        `}
      >
        <Tag>
          {patientData.gender === Gender.FEMALE ? 'W' : 'M'} {personAge(patientData.birthday).toLocaleString('de-AT')}{' '}
          Jahre
        </Tag>
        <Tag>
          {patientData.svnr ? (
            <span>
              {patientData.svnr} ({dayjs(patientData.birthday).format(SHORT_DATE_FORMAT)})
            </span>
          ) : (
            <span>{dayjs(patientData.birthday).format(SHORT_DATE_FORMAT)}</span>
          )}
        </Tag>
        <Tag>{insuranceName}</Tag>
        <Popover
          title="Mitversichert bei"
          overlayClassName={css`
            max-width: 500px;
            .ant-tag {
              margin: 0;
            }
          `}
          content={
            <Flex wrap gap={token.marginXS}>
              <Tag icon={<UserOutlined />}>
                {patientData.insuredPerson?.title} {patientData.insuredPerson?.firstName}{' '}
                {patientData.insuredPerson?.lastName}
              </Tag>
              <Tag>
                {patientData.insuredPerson?.gender
                  ? patientData.insuredPerson.gender === Gender.FEMALE
                    ? 'W '
                    : 'M '
                  : ''}
                {patientData.insuredPerson?.birthday &&
                  personAge(patientData.insuredPerson?.birthday).toLocaleString('de-AT')}{' '}
                Jahre
              </Tag>
              <Tag>
                {patientData.insuredPerson?.svnr} (
                {dayjs(patientData.insuredPerson?.birthday).format(SHORT_DATE_FORMAT)})
              </Tag>
              <Tag icon={<PhoneOutlined />}>{patientData.insuredPerson?.phone}</Tag>
              <Tag icon={<HomeOutlined />}>{patientAddress(patientData.insuredPerson)}</Tag>
            </Flex>
          }
        >
          <Tag hidden={!patientData.insuredPerson} icon={<TeamOutlined />}>
            Mitversichert
          </Tag>
        </Popover>
        <Tag hidden={!patientData.phone.length} icon={<PhoneOutlined />}>
          {patientData.phone}
        </Tag>
        <Tag hidden={(patientBilling && !addressComplete) || !address.length} icon={<HomeOutlined />}>
          {address}
        </Tag>
        <Tooltip
          title={
            <>
              Die Adresse ist unvollständig, daher ist <b>Privat an Patient</b> nicht möglich
            </>
          }
        >
          <Tag hidden={!patientBilling || addressComplete} color="orange" icon={<HomeOutlined />}>
            Privat an Patient <i>nicht möglich</i>
          </Tag>
        </Tooltip>
      </div>
      {menu}
      <Modal
        title={`Formulare für ${patientData.firstName} ${patientData.lastName}`}
        width={768}
        open={formsOpen}
        footer={null}
        onCancel={() => setFormsOpen(false)}
        destroyOnClose
      >
        <Forms patientData={patientData} doctorId={currentDoctorId} />
      </Modal>
    </PageHeader>
  );
};
