import React, { useState } from 'react';
import { Button, Flex, Modal, Radio, Space, Typography } from 'antd';
import { EditOutlined, PlusCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { SHORT_DATETIME_FORMAT } from '../../utils/dateFormatUtils';
import dayjs from 'dayjs';
import { FragmentType, graphql, getFragmentData } from '../../graphql/generated';

export const OpenRequestsBySvnrModalRequestFragment = graphql(`
  fragment OpenRequestsBySvnrModal_Request on Request {
    id
    createdAt
    orders {
      id
      orderParameters {
        id
        shortName
        longName
      }
    }
  }
`);

interface Props {
  flipParamNames: boolean;
  open: boolean;
  requestsFragment: FragmentType<typeof OpenRequestsBySvnrModalRequestFragment>[];
  overrideRequestEnabled: boolean;
  onNew: () => void;
  onEdit: (requestId: string) => void;
  overrideRequest: (requestId: string) => void;
}

export const OpenRequestsBySvnrModal: React.FC<Props> = ({
  flipParamNames,
  open,
  requestsFragment,
  overrideRequestEnabled,
  onNew,
  onEdit,
  overrideRequest,
}) => {
  const requests = getFragmentData(OpenRequestsBySvnrModalRequestFragment, requestsFragment);
  const [requestId, setRequestId] = useState('');

  return (
    <Modal
      title="Es existieren bereits offene Anforderungen für den Patienten"
      open={open}
      onCancel={onNew}
      width={600}
      destroyOnClose
      footer={
        <Flex gap={10} wrap justify="end">
          <Button
            onClick={() => onEdit(requestId)}
            disabled={!requests.map(it => it.id).includes(requestId)}
            icon={<EditOutlined />}
          >
            Ausgewählte Anforderung bearbeiten
          </Button>
          {overrideRequestEnabled && (
            <Button
              onClick={() => overrideRequest(requestId)}
              disabled={!requests.map(it => it.id).includes(requestId)}
              icon={<SyncOutlined />}
            >
              Ausgewählte Anforderung überschreiben
            </Button>
          )}
          <Button type="primary" onClick={onNew} icon={<PlusCircleOutlined />}>
            Neue Anforderung erstellen
          </Button>
        </Flex>
      }
    >
      <p>
        Bitte wählen Sie aus, ob eine neue Anforderung erstellt werden soll, oder ob eine bestehe Anforderung
        geladen/bearbeitet werden soll:
      </p>
      <Radio.Group onChange={e => setRequestId(e.target.value)} value={requestId}>
        <Space direction="vertical">
          {[...requests]
            .sort((a, b) => (dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 1))
            .map(r => (
              <Radio key={r.id} value={r.id}>
                {dayjs(r.createdAt).format(SHORT_DATETIME_FORMAT)}
                <br />
                <Typography.Text type="secondary">
                  {r.orders
                    .flatMap(o => o.orderParameters)
                    .map(p => (flipParamNames ? p.longName : p.shortName))
                    .sort()
                    .join(', ')}
                </Typography.Text>
              </Radio>
            ))}
        </Space>
      </Radio.Group>
    </Modal>
  );
};
