import { FC } from 'react';
import { SelectedParameter } from '../../../hooks/store/useAppStore.ts';
import { Flex, theme, Typography } from 'antd';
import { css } from '@emotion/css';
import { TestTubeColor } from '../../../components/TestTubeColor.tsx';
import { testTubeColorProperties } from '../../../utils/enumHelpers.ts';
import { currency } from '../../../utils/currency.ts';
import { Billing } from './Billing.tsx';
import { ClockCircleOutlined } from '@ant-design/icons';

export const ParamEntry: FC<{
  param: SelectedParameter;
  swap: boolean;
  showLab: boolean;
  showPrice: boolean;
  onInfo: (param: SelectedParameter) => void;
}> = ({ param, swap, showLab, showPrice, onInfo }) => {
  const { token } = theme.useToken();

  const ellipsis = css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `;

  return (
    <Flex
      gap={token.paddingSM}
      onClick={() => onInfo(param)}
      className={css`
        border: 1px solid ${token.Layout?.bodyBg};
        border-radius: ${token.borderRadiusSM}px;
        padding: ${token.paddingXXS}px;
        background-color: ${token.Layout?.bodyBg};
        cursor: pointer;

        :hover {
          border-color: ${token.colorPrimary};
        }

        transition: border-color 200ms linear;
      `}
    >
      <Flex
        vertical
        flex="max-content"
        className={css`
          min-width: 0;
        `}
      >
        <span className={ellipsis} style={{ fontWeight: 500 }}>
          {swap ? param.longName : param.shortName}
        </span>
        <Typography.Text
          type="secondary"
          className={css`
            ${ellipsis};
            line-height: 12px;
          `}
        >
          <small>{swap ? param.shortName : param.longName}</small>
        </Typography.Text>
      </Flex>
      <Flex vertical>
        <Flex gap={4}>
          <Billing billingInfo={param.billingInfo} />
          {param.specimens
            .flatMap(it => it.testTubes)
            .map((tt, i) => (
              <TestTubeColor key={tt.id + '_' + i} withText={false} properties={testTubeColorProperties(tt.color)} />
            ))}
        </Flex>
        <Typography.Text
          type="secondary"
          className={css`
            text-align: right;
            padding-right: 4px;
            line-height: 12px;
          `}
        >
          <small>
            {showPrice ? currency(param.billingInfo.price) : ''} {showLab ? param.lab.shortName : ''}
          </small>
          {param.acute ? <ClockCircleOutlined className={css(`font-size: 12px`)} /> : null}
        </Typography.Text>
      </Flex>
    </Flex>
  );
};
