import React from 'react';
import { theme } from 'antd';
import { css } from '@emotion/css';
import { PlusCircleOutlined } from '@ant-design/icons';

export const ReorderTabEntry: React.FC<{ tabKey: string; selectedTabKeys: string[] }> = ({
  tabKey,
  selectedTabKeys,
}) => {
  const { token } = theme.useToken();

  return (
    <span
      className={css`
        font-weight: 500;
        animation: ${selectedTabKeys.includes(tabKey) ? 'none' : 'pulsate 3s ease-out'};
        animation-iteration-count: infinite;
        opacity: 1;
        color: ${token.colorPrimary};

        @keyframes pulsate {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
          100% {
            opacity: 1;
          }
        }
      `}
    >
      <PlusCircleOutlined
        className={css`
          margin-right: ${token.paddingXS}px;
        `}
      />
      Nachforderung möglich
    </span>
  );
};
