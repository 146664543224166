import React, { ReactNode } from 'react';
import { Flex, Popover, Tag, theme } from 'antd';
import { css } from '@emotion/css';

export const TableList: React.FC<{ entries: ReactNode[]; maxEntries?: number; withinModal?: boolean }> = ({
  entries,
  maxEntries = 5,
  withinModal,
}) => {
  const { token } = theme.useToken();

  const mainEntries = entries.slice(0, maxEntries);
  const restEntries = entries.slice(maxEntries, entries.length);

  return (
    <Flex>
      {mainEntries.map((entry, index) => (
        <Tag key={index}>{entry}</Tag>
      ))}
      {!!restEntries.length && (
        <Popover
          zIndex={withinModal ? 9999 : 900 /* behind modal */}
          overlayClassName={css`
            max-width: 500px;
            .ant-popover-inner {
              padding: 0;
            }
          `}
          content={
            <div
              className={css`
                padding: ${token.paddingSM}px;
                padding-bottom: calc(${token.paddingSM}px - ${token.paddingXXS}px);
              `}
              onClick={e => e.stopPropagation()}
            >
              {restEntries.map((entry, index) => (
                <Tag
                  key={index}
                  className={css`
                    margin-bottom: ${token.paddingXXS}px;
                  `}
                >
                  {entry}
                </Tag>
              ))}
            </div>
          }
          trigger="click"
        >
          <Tag
            className={css`
              color: ${token.colorLink};
              cursor: pointer;
            `}
            onClick={e => {
              document.body.click();
              e.stopPropagation();
            }}
          >
            +{restEntries.length}
          </Tag>
        </Popover>
      )}
    </Flex>
  );
};
