import React from 'react';
import { Button, Col, Form, FormInstance, Input, InputNumber, Row, Select } from 'antd';
import { translatePeriod } from '../../../../utils/enumHelpers';
import { Period, PeriodicGroupCreateInput, PeriodicGroupUpdateInput } from '../../../../graphql/generated/graphql.ts';

const { Option } = Select;

export const PeriodicGroupForm: React.FC<{
  form: FormInstance;
  initialValues?: PeriodicGroupCreateInput;
  onFinish: (values: PeriodicGroupCreateInput | PeriodicGroupUpdateInput) => void;
}> = ({ form, initialValues, onFinish }) => {
  return (
    <Form layout="vertical" form={form} initialValues={initialValues} onFinish={onFinish}>
      <Form.Item
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            whitespace: true,
          },
        ]}
      >
        <Input autoFocus />
      </Form.Item>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Form.Item
            name="maxCount"
            label="Max. Anzahl"
            rules={[
              {
                type: 'integer',
              },
            ]}
          >
            <InputNumber style={{ width: '100%' }} inputMode="numeric" precision={0} max={9999999} min={1} step={1} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="period" label="Zeitraum">
            <Select>
              {Object.keys(Period).map(it => {
                return (
                  <Option key={it} value={it}>
                    {translatePeriod(it)}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {/* hack for enter button support */}
      <Button htmlType="submit" hidden />
    </Form>
  );
};
