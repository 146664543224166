import { List } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import { NotificationInstance } from 'antd/es/notification/interface';
import { gold } from '@ant-design/colors';

export const showParamWarning = (
  notification: NotificationInstance,
  title: string,
  message: string,
  params: string[],
  key?: string
) => {
  notification.open({
    message: (
      <>
        <WarningTwoTone twoToneColor={gold[5]} /> {title}
      </>
    ),
    duration: 8,
    key: key,
    description: (
      <>
        {message}
        <List
          size="small"
          itemLayout="horizontal"
          dataSource={params.sort((a, b) => a.localeCompare(b))}
          renderItem={param => (
            <List.Item>
              <List.Item.Meta title={param} />
            </List.Item>
          )}
        />
      </>
    ),
  });
};
