import React, { useEffect, useState } from 'react';
import yaml from 'js-yaml';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { Alert, Button, Flex, theme } from 'antd';
import { AppleFilled, WindowsFilled } from '@ant-design/icons';
import { SHORT_DATE_FORMAT } from '../../utils/dateFormatUtils';
import dayjs from 'dayjs';

const { useToken } = theme;

interface UpdateFileInfo {
  readonly size: number;
  readonly url: string;
  readonly sha512: string;
}

interface UpdateInfo {
  readonly version: string;
  readonly files: Array<UpdateFileInfo>;
  readonly sha512: string;
  readonly releaseDate: string;
  readonly path: string;
}

const baseUrl = 'https://desktop.medcom.at';

export const DesktopVersionInfo: React.FC = () => {
  const { token } = useToken();
  const [macUpdateInfo, setMacUpdateInfo] = useState<UpdateInfo | null>(null);
  const [winUpdateInfo, setWinUpdateInfo] = useState<UpdateInfo | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentVersion, setCurrentVersion] = useState<string>('');
  const [isMacOs, setMacOs] = useState<boolean>(false);

  const updateInfo = isMacOs ? macUpdateInfo : winUpdateInfo;

  useEffect(() => {
    setLoading(true);

    if (window.nativeApi) {
      window.nativeApi.version().then(it => setCurrentVersion(it));
      window.nativeApi.isMacOs().then(it => setMacOs(it));
    }

    const macRequest = fetch(`${baseUrl}/latest-mac.yml`, { cache: 'no-store' })
      .then(it => it.text())
      .then(it => {
        const updateInfo = yaml.load(it) as UpdateInfo;
        if (updateInfo.files) {
          setMacUpdateInfo(updateInfo);
        }
      });
    const winRequest = fetch(`${baseUrl}/latest.yml`, { cache: 'no-store' })
      .then(it => it.text())
      .then(it => {
        const updateInfo = yaml.load(it) as UpdateInfo;
        if (updateInfo.files) {
          setWinUpdateInfo(updateInfo);
        }
      });

    Promise.all([macRequest, winRequest]).finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      {currentVersion && updateInfo && currentVersion !== updateInfo.version && (
        <Alert
          type="warning"
          showIcon
          message={`Sie verwenden eine veraltete Version ${currentVersion}. Bitte aktualisieren Sie Medcom Desktop auf Version ${updateInfo.version}!`}
        />
      )}
      {currentVersion && updateInfo && currentVersion === updateInfo.version && (
        <Alert type="success" showIcon message="Sie verwenden die aktuelle Version" />
      )}
      {!currentVersion && (
        <>
          <h4>Auf Ihren Computer herunterladen</h4>
          <p>Verwenden Sie unseren Desktop-Client, um die Anwendung mit vollem Funktionsumfang nutzen zu können.</p>
          <Flex wrap="wrap" gap={token.paddingXS}>
            {winUpdateInfo && (
              <a download href={`${baseUrl}/${winUpdateInfo.files.find(it => it.url.endsWith('.exe'))?.url}`}>
                <Button icon={<WindowsFilled />}>
                  Windows
                  <small style={{ opacity: 0.75, paddingLeft: token.paddingXS }}>
                    v{winUpdateInfo.version} - {dayjs(winUpdateInfo.releaseDate).format(SHORT_DATE_FORMAT)}
                  </small>
                </Button>
              </a>
            )}{' '}
            {macUpdateInfo && (
              <a download href={`${baseUrl}/${macUpdateInfo.files.find(it => it.url.endsWith('.dmg'))?.url}`}>
                <Button icon={<AppleFilled />}>
                  macOS{' '}
                  <small style={{ opacity: 0.75, paddingLeft: token.paddingXS }}>
                    v{macUpdateInfo.version} - {dayjs(macUpdateInfo.releaseDate).format(SHORT_DATE_FORMAT)}
                  </small>
                </Button>
              </a>
            )}
          </Flex>
        </>
      )}
    </div>
  );
};
