import { FC, ReactElement } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { hasAnyRelevantRole, hasSomeRole, Role } from '../utils/user';
import { useAuth } from 'react-oidc-context';
import { useCurrentContextStore } from '../hooks/store/useCurrentContextStore.ts';

export const PrivateRoute: FC<{ roles?: Role[]; children?: ReactElement }> = ({ roles, children }) => {
  const location = useLocation();
  const auth = useAuth();
  const { currentDoctorId } = useCurrentContextStore();

  if (
    !auth.isAuthenticated ||
    (!currentDoctorId && !hasSomeRole([Role.ROLE_LR_MEDCOM], auth.user)) ||
    (roles ? !hasSomeRole(roles, auth.user) : !hasAnyRelevantRole(auth.user))
  ) {
    return <Navigate to="/no-access" state={{ from: location }} replace />;
  }

  return children ? children : <Outlet />;
};
