import React, { useEffect, useState } from 'react';
import { MainContent } from '../../../components/MainContent';
import { useParams } from 'react-router-dom';
import { App, Button, Dropdown, Input, Table, theme, Tooltip } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  DiffOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { useDebounce } from 'use-debounce';
import { Markable } from '../../../components/Markable';
import { CreateCatalogModal } from './catalogs/CreateCatalogModal';
import { UpdateCatalogModal } from './catalogs/UpdateCatalogModal';
import { CatalogParameterModal } from './catalogs/CatalogParameterModal';
import { tableActionCell } from '../../../styles/globalCss';
import { graphql } from '../../../graphql/generated';
import { CatalogsQuery } from '../../../graphql/generated/graphql.ts';
import { CopyCatalogParametersModal } from './catalogs/CopyCatalogParametersModal.tsx';

export const CATALOGS_QUERY = graphql(`
  query Catalogs($labId: ID!) {
    labCatalogs(labId: $labId) {
      id
      name
      description
    }

    labParameters(labId: $labId) {
      ...CatalogForm_Parameter
    }
  }
`);

const CLONE_CATALOG_MUTATION = graphql(`
  mutation CloneCatalog($id: ID!) {
    cloneCatalog(id: $id) {
      id
    }
  }
`);

const DELETE_CATALOG_MUTATION = graphql(`
  mutation DeleteCatalog($id: ID!) {
    deleteCatalog(id: $id)
  }
`);

type Catalog = NonNullable<CatalogsQuery['labCatalogs']>[number];

export const Catalogs: React.FC = () => {
  const { token } = theme.useToken();
  const { message, modal } = App.useApp();

  const { id: labId } = useParams<{ id: string }>();
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch] = useDebounce(search, 250);
  const [filteredCatalogs, setFilteredCatalogs] = useState<Catalog[]>([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editCatalogId, setEditCatalogId] = useState<string | null>(null);
  const [copyParamsSourceCatalogId, setCopyParamsSourceCatalogId] = useState<string | null>(null);
  const [paramViewCatalog, setParamViewCatalog] = useState<Catalog | null>(null);

  const { data, loading, refetch } = useQuery(CATALOGS_QUERY, {
    variables: {
      labId: labId ?? '',
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000,
  });

  const [cloneCatalogMutation, { loading: cloneLoading }] = useMutation(CLONE_CATALOG_MUTATION);
  const [deleteCatalogMutation, { loading: deleteLoading }] = useMutation(DELETE_CATALOG_MUTATION);

  const clone = async (id: string) => {
    try {
      await cloneCatalogMutation({
        variables: {
          id: id,
        },
      });

      message.success('Parameterkatalog wurde geklont');
      refetch();
    } catch {
      message.error('Parameterkatalog konnte nicht geklont werden');
    }
  };

  const deleteCatalog = async (id: string) => {
    try {
      const response = await deleteCatalogMutation({
        variables: {
          id: id,
        },
      });

      if (response.data?.deleteCatalog) {
        message.success('Parameterkatalog wurde gelöscht');
        refetch();
      } else {
        message.warning('Löschen nicht möglich - Der Katalog wird noch verwendet');
      }
    } catch {
      message.error('Parameterkatalog konnte nicht gelöscht werden');
    }
  };

  const columns: ColumnsType<Catalog> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Beschreibung',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      sorter: (a, b) => a.description.localeCompare(b.description),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '50px',
      className: tableActionCell,
      render: (_, record) => {
        return (
          <Dropdown
            menu={{
              items: [
                {
                  key: 'edit',
                  icon: <EditOutlined />,
                  onClick: () => setEditCatalogId(record.id),
                  label: 'Bearbeiten',
                },
                {
                  key: 'view-params',
                  icon: <TableOutlined />,
                  onClick: () => setParamViewCatalog(record),
                  label: 'Parameterliste',
                },
                {
                  key: 'clone',
                  icon: <CopyOutlined />,
                  onClick: () => clone(record.id),
                  label: 'Klonen',
                },
                {
                  key: 'copyParams',
                  icon: <DiffOutlined />,
                  onClick: () => setCopyParamsSourceCatalogId(record.id),
                  label: 'Parameter kopieren',
                },
                {
                  key: 'delete',
                  icon: <DeleteOutlined />,
                  danger: true,
                  onClick: () => {
                    modal.confirm({
                      maskClosable: true,
                      title: 'Katalog löschen?',
                      content: 'Wollen Sie den Katalog wirklich löschen?',
                      okText: 'Ja, Katalog löschen',
                      cancelText: 'Nein',
                      okButtonProps: { icon: <DeleteOutlined /> },
                      onOk: () => deleteCatalog(record.id),
                    });
                  },
                  label: 'Löschen',
                },
              ],
            }}
            trigger={['click']}
            placement="bottomRight"
          >
            <Button icon={<EllipsisOutlined style={{ fontSize: '20px' }} />} type="text" />
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    const filtered = data?.labCatalogs?.filter(c => {
      if (debouncedSearch && debouncedSearch.length) {
        const searchValue = debouncedSearch.toLowerCase();
        return c.name.toLowerCase().includes(searchValue) || c.description.toLowerCase().includes(searchValue);
      }
      return true;
    });
    setFilteredCatalogs(filtered ?? []);
  }, [debouncedSearch, data]);

  return (
    <MainContent>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: token.paddingSM }}>
        <Input
          allowClear
          autoFocus
          placeholder="Suche"
          value={search ?? ''}
          onChange={e => setSearch(e.target.value)}
          prefix={<SearchOutlined />}
          suffix={
            <Tooltip title="Suche nach Name oder Beschreibung">
              <InfoCircleOutlined />
            </Tooltip>
          }
          style={{ width: '300px', marginRight: token.paddingSM }}
        />
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          disabled={loading}
          onClick={() => setCreateModalOpen(true)}
        >
          Neuer Parameterkatalog
        </Button>
      </div>
      <Table<Catalog>
        scroll={{ x: 'max-content' }}
        rowKey={record => record.id}
        size="middle"
        showSorterTooltip={false}
        dataSource={filteredCatalogs}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} bis ${range[1]} von ${total} Katalogen`,
        }}
        loading={loading || cloneLoading || deleteLoading}
        columns={columns}
      />
      <CreateCatalogModal
        open={createModalOpen}
        onSuccess={() => refetch()}
        onClose={() => setCreateModalOpen(false)}
        labId={labId ?? ''}
        parameterFragments={data?.labParameters ?? []}
      />
      <UpdateCatalogModal
        catalogId={editCatalogId}
        parameterFragments={data?.labParameters ?? []}
        onSuccess={() => refetch()}
        onClose={() => setEditCatalogId(null)}
      />
      <CatalogParameterModal
        catalogId={paramViewCatalog?.id ?? null}
        labId={labId ?? ''}
        onClose={() => setParamViewCatalog(null)}
      />
      <CopyCatalogParametersModal
        sourceCatalogId={copyParamsSourceCatalogId}
        labId={labId}
        onClose={() => setCopyParamsSourceCatalogId(null)}
      />
    </MainContent>
  );
};
