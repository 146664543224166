import React, { ReactNode, useCallback } from 'react';
import { Alert, Button, Flex } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { openExternalLink } from './utils/linkOpener.ts';
import { css } from '@emotion/css';
import { graphql } from './graphql/generated';
import { SystemStatusType } from './graphql/generated/graphql.ts';
import { UpdateEvent } from './socket/types.ts';
import { useSubscription } from './socket/useSubscription.ts';

const SYSTEM_STATUS_WRAPPER_QUERY = graphql(`
  query SystemStatusWrapper {
    systemStatus {
      id
      active
      heading
      content
      link
      type
    }
  }
`);

export const SystemStatusWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { data, refetch } = useQuery(SYSTEM_STATUS_WRAPPER_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const callback = useCallback(
    (event: UpdateEvent) => {
      console.debug('received UI update: ' + JSON.stringify(event));
      refetch();
    },
    [refetch]
  );

  useSubscription({ type: 'system-status-updated' }, callback);

  let type: 'info' | 'warning' | 'error' = 'info';
  if (data?.systemStatus?.type === SystemStatusType.ERROR) {
    type = 'error';
  } else if (data?.systemStatus?.type === SystemStatusType.WARNING) {
    type = 'warning';
  }

  return (
    <Flex
      className={css`
        height: 100%;
      `}
      vertical
    >
      <div
        className={css`
          max-height: ${data?.systemStatus?.active ? '300px' : 0};
          opacity: ${data?.systemStatus?.active ? 1 : 0};
          transition-property: max-height, opacity;
          transition-duration: 0.5s;
          transition-timing-function: linear;
        `}
      >
        <Alert
          showIcon
          banner
          type={type}
          message={data?.systemStatus?.heading}
          description={
            <div
              className={css`
                white-space: pre-wrap;
              `}
            >
              {data?.systemStatus?.content}
            </div>
          }
          action={
            data?.systemStatus?.link ? (
              <Button
                icon={<ExportOutlined />}
                onClick={() => (data?.systemStatus?.link ? openExternalLink(data.systemStatus.link) : null)}
              >
                Weitere Informationen
              </Button>
            ) : null
          }
          className={css`
            .ant-alert-description {
              max-height: 100px;
              overflow: auto;
            }
          `}
        />
      </div>
      <div
        className={css`
          flex-grow: 1;
          overflow-y: auto;
        `}
      >
        {children}
      </div>
    </Flex>
  );
};
