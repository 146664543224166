import React from 'react';
import { hasAnyRelevantRole, hasSomeRole, Role } from '../utils/user';
import { MainMenu } from './MainMenu';
import { Layout, theme } from 'antd';
import { css } from '@emotion/css';
import { useAuth } from 'react-oidc-context';
import { useCurrentContextStore } from '../hooks/store/useCurrentContextStore.ts';

const { useToken } = theme;
const { Sider } = Layout;

export const Sidebar: React.FC = () => {
  const { token } = useToken();
  const auth = useAuth();
  const { currentDoctorId } = useCurrentContextStore();

  if (!hasAnyRelevantRole(auth.user) || (!currentDoctorId && !hasSomeRole([Role.ROLE_LR_MEDCOM], auth.user))) {
    return null;
  }

  const styles = css`
    overflow: auto;
    background-color: ${token.Menu?.subMenuItemBg} !important;
    border-right: 1px solid ${token.colorBorderSecondary};

    .ant-layout-sider-trigger {
      height: 40px !important;
      line-height: 40px !important;
    }
  `;
  return (
    <Sider width={256} collapsedWidth={81} collapsible breakpoint="xl" className={styles}>
      <MainMenu />
    </Sider>
  );
};
