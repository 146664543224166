import React, { CSSProperties } from 'react';
import { Select } from 'antd';

const { Option } = Select;

type Specimen = {
  id: string;
  name: string;
  classification: string;
};

interface SpecimenSelectProps {
  id?: string;
  multiple?: boolean;
  style?: CSSProperties;
  specimens: readonly Specimen[];
  selected?: string | string[] | undefined;
  onChange?: (value: string | string[]) => void;
}

export const SpecimenSelect: React.FC<SpecimenSelectProps> = React.memo(
  ({ id, multiple, style, specimens, selected, onChange }) => {
    return (
      <Select
        id={id}
        showSearch
        allowClear
        mode={multiple ? 'multiple' : undefined}
        style={style}
        onChange={onChange}
        value={selected}
        placeholder="Suche nach Material"
        optionFilterProp="search"
        optionLabelProp="taglabel"
      >
        {[...specimens]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(specimen => {
            const label = `${specimen.name} - ${specimen.classification}`;
            return (
              <Option key={specimen.id} value={specimen.id} search={label} taglabel={specimen.name}>
                {label}
              </Option>
            );
          })}
      </Select>
    );
  }
);
