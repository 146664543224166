import React, { useEffect, useState } from 'react';
import { Menu, Select, theme } from 'antd';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { FileDoneOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Sheets } from './Sheets';
import { PageHeader } from '@ant-design/pro-components';
import { css } from '@emotion/css';
import { graphql } from '../../../graphql/generated';
import { LoadingIndicator } from '../../../components/LoadingIndicator.tsx';
import { LabAdminLabQuery } from '../../../graphql/generated/graphql.ts';

const LAB_ADMIN_LAB_QUERY = graphql(`
  query LabAdminLab {
    assignmentForCurrentUser {
      id
      primaryDoctor {
        id
        assignedLabs {
          lab {
            id
            name
            longName
            shortName
          }
        }
      }
    }
  }
`);

type LabType = NonNullable<
  LabAdminLabQuery['assignmentForCurrentUser']
>['primaryDoctor']['assignedLabs'][number]['lab'];

export const LabAdminLab: React.FC = () => {
  const { token } = theme.useToken();

  const location = useLocation();
  const levels = location.pathname.split('/');
  const level1 = levels[3];

  const [labs, setLabs] = useState<LabType[]>([]);
  const [selectedLab, setSelectedLab] = useState<LabType | null>(null);

  const { data } = useQuery(LAB_ADMIN_LAB_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    setLabs(data?.assignmentForCurrentUser?.primaryDoctor?.assignedLabs?.map(it => it.lab) ?? []);
  }, [data]);

  useEffect(() => {
    if (labs.length && !selectedLab) {
      setSelectedLab(labs[0]);
    }
  }, [labs, selectedLab]);

  if (!selectedLab) {
    return <LoadingIndicator />;
  }

  const styles = {
    wrapper: css`
      display: flex;
      flex-direction: column;
      height: 100%;
    `,
    pageHeader: css`
      border-bottom: 1px solid ${token.colorBorderSecondary};
      background-color: white !important;
      padding: ${token.paddingMD}px ${token.paddingLG}px 0 ${token.paddingLG}px;
    `,
    content: css`
      overflow-y: auto;
      height: 100%;
    `,
  };

  return (
    <div className={styles.wrapper}>
      <PageHeader
        title={`${selectedLab.name} (${selectedLab.shortName})`}
        subTitle={selectedLab.longName}
        extra={
          labs.length > 1 ? (
            <Select
              style={{ width: '300px' }}
              options={labs.map(it => ({ label: it.name, value: it.id }))}
              value={selectedLab.id}
              onChange={id => setSelectedLab(labs.find(it => it.id === id)!)}
            />
          ) : null
        }
        className={styles.pageHeader}
      >
        <Menu
          defaultSelectedKeys={['sheets']}
          selectedKeys={[level1]}
          mode="horizontal"
          style={{ borderBottom: 0, backgroundColor: 'white' }}
          items={[
            {
              key: 'sheets',
              icon: <FileDoneOutlined />,
              label: <Link to="sheets">Sheets</Link>,
            },
          ]}
        />
      </PageHeader>
      <div className={styles.content}>
        <Routes>
          <Route path="sheets" element={<Sheets labId={selectedLab.id} />} />
          <Route path="*" element={<Navigate replace to="sheets" />} />
        </Routes>
      </div>
    </div>
  );
};
