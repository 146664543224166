import { saveAs } from 'file-saver';
import { Rule } from 'rc-field-form/lib/interface';
import { loadUser } from '../oidcConfig.ts';

export const stripTypename = <T>(object: T): T => {
  // @ts-expect-error __typename not defined
  const { __typename, ...result } = object;
  return result as T;
};

export const saveUrl = async (url: string, defaultFilename?: string, onError: () => void = () => {}) => {
  try {
    const response = await fetch(url);
    if (response.ok) {
      const blob = await response.blob();
      saveAs(blob, response.headers.get('x-amz-meta-original_name') ?? defaultFilename);
    } else {
      onError();
    }
  } catch {
    onError();
  }
};

export const blobToDataUrl = (file: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

export const currentUrlWithoutQueryString = () => window.location.href.split('?')[0];

export const serverSideEmailRule: Rule = {
  validator: async (_, value) => {
    if (!value) {
      return Promise.resolve();
    }

    const endpoint =
      window._env_.API_URL +
      `/rest/validation/email?` +
      new URLSearchParams({
        email: value,
      });
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: { authorization: `Bearer ${loadUser()?.access_token}` },
    });
    if (!response.ok) {
      return Promise.reject('Serverantwort nicht OK');
    }
    const isValid = await response.json();
    return isValid ? Promise.resolve() : Promise.reject('Die E-Mail-Adresse ist ungültig');
  },
};
