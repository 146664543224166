import { useCallback, useState } from 'react';
import { useAuth } from 'react-oidc-context';

export const useObjectUrlResource = (
  onError: () => void,
  mimeType: string
): {
  objectUrl: string;
  blob: Blob | null;
  fetchAndSet: (endpoint: string) => void;
  cleanup: () => void;
  loading: boolean;
} => {
  const auth = useAuth();
  const [blob, setBlob] = useState<Blob | null>(null);
  const [objectUrl, setObjectUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchAndSet = useCallback(
    async (endpoint: string) => {
      setLoading(true);
      try {
        const response = await fetch(endpoint, {
          headers: {
            authorization: `Bearer ${auth.user?.access_token}`,
          },
        });

        if (!response.ok) {
          onError();
          return;
        }

        const blob = await response.blob();
        setBlob(blob);
        setObjectUrl(URL.createObjectURL(new Blob([blob], { type: mimeType })));
      } catch {
        onError();
      } finally {
        setLoading(false);
      }
    },
    [auth, mimeType, onError]
  );

  const cleanup = useCallback(() => {
    if (objectUrl) {
      URL.revokeObjectURL(objectUrl);
    }
    setObjectUrl('');
    setBlob(null);
  }, [objectUrl]);

  return { objectUrl, blob, fetchAndSet, cleanup, loading };
};
