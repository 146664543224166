import React, { useEffect, useState } from 'react';
import { Alert, Modal, Space } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { DoctorSelect } from '../../components/DoctorSelect';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { graphql } from '../../graphql/generated';

const DOCTORS_FOR_CURRENT_USER_QUERY = graphql(`
  query DoctorsForCurrentUser {
    doctorsForCurrentUser {
      id
      name
      meAddress
      firstName
      lastName
      postTitle
      preTitle
      salutation
      disabled
    }
  }
`);

export const AssignCurrentDoctorModal: React.FC<{
  currentDoctorId: string;
  open: boolean;
  onClose: () => void;
  onSuccess: (doctorId: string) => void;
}> = ({ currentDoctorId, open, onClose, onSuccess }) => {
  const [fetchDoctors, { loading, data }] = useLazyQuery(DOCTORS_FOR_CURRENT_USER_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const [selectedDoctorId, setSelectedDoctorId] = useState<string>(currentDoctorId);

  useEffect(() => {
    if (open) {
      fetchDoctors();
    }
  }, [open, fetchDoctors]);

  return (
    <Modal
      title="Zuweiser festlegen"
      okText="Zuweiser festlegen"
      okButtonProps={{ icon: <SwapOutlined />, disabled: !selectedDoctorId }}
      open={open}
      onCancel={onClose}
      onOk={() => onSuccess(selectedDoctorId)}
      confirmLoading={loading}
      destroyOnClose
    >
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Space direction="vertical" style={{ width: '100%' }}>
          <DoctorSelect
            style={{ width: '100%' }}
            onChange={value => setSelectedDoctorId(value as string)}
            doctors={data?.doctorsForCurrentUser ?? []}
            selected={selectedDoctorId}
          />
          {!!data?.doctorsForCurrentUser?.find(it => it.id === selectedDoctorId)?.disabled && (
            <Alert
              type="warning"
              showIcon
              message="Achtung! Der ausgewählte Zuweiser ist als deaktiviert markiert. Für deaktivierte Zuweiser sollten keine neuen Anforderungen erstellt werden."
            />
          )}
        </Space>
      )}
    </Modal>
  );
};
