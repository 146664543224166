import React, { ReactNode, useEffect, useRef } from 'react';
import Mark from 'mark.js';
import { css } from '@emotion/css';
import { theme } from 'antd';

const { useToken } = theme;

export interface MarkableProps {
  children: ReactNode;
  tokens: string[] | string;
}

export const Markable: React.FC<MarkableProps> = ({ tokens, children }) => {
  const { token } = useToken();
  const container = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const instance = new Mark(container.current!);
    instance.mark(typeof tokens === 'string' ? tokens.split(' ') : tokens);
    return () => {
      instance.unmark();
    };
  }, [tokens, children]);

  return (
    <span
      ref={container}
      className={css`
        mark {
          background-color: ${token['gold-3']};
          padding: 0;
        }
      `}
    >
      {children}
    </span>
  );
};
