import React from 'react';
import { App, Modal } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { ParameterForm } from './ParameterForm';
import { useForm } from 'antd/es/form/Form';
import { graphql } from '../../../../graphql/generated';
import { ParameterCreateInput, PartnerLab } from '../../../../graphql/generated/graphql.ts';
import { ParamType } from './ParamType';

const CREATE_PARAMETER_MUTATION = graphql(`
  mutation CreateParameter($input: ParameterCreateInput!) {
    createParameter(input: $input) {
      id
    }
  }
`);

export const CreateParameterModal: React.FC<{
  labId: string;
  open: boolean;
  parameters: ParamType[];
  onClose: () => void;
  onSuccess: () => void;
}> = ({ labId, open, parameters, onClose, onSuccess }) => {
  const [createParameterMutation, { loading }] = useMutation(CREATE_PARAMETER_MUTATION);
  const [form] = useForm<ParameterCreateInput>();
  const { message } = App.useApp();

  const create = async (values: ParameterCreateInput) => {
    try {
      await createParameterMutation({
        variables: {
          input: {
            ...values,
            labId: labId,
            insuranceDefinitions: form.getFieldValue('insuranceDefinitions'),
            link: values.link.trim(),
            description: values.description.trim(),
          },
        },
      });

      message.success('Parameter wurde erstellt');
      onSuccess();
      onClose();
    } catch (e) {
      message.error('Parameter konnte nicht erstellt werden');
    }
  };

  return (
    <Modal
      width={1024}
      title="Neuen Parameter erstellen"
      okText="Erstellen"
      okButtonProps={{ icon: <PlusCircleOutlined /> }}
      open={open}
      onCancel={onClose}
      onOk={form.submit}
      confirmLoading={loading}
      destroyOnClose
      afterClose={() => form.resetFields()}
    >
      <ParameterForm
        form={form}
        onFinish={create}
        initialValues={{
          labId: labId,
          shortName: '',
          longName: '',
          specimenIds: [],
          withParameterIds: [],
          withoutParameterIds: [],
          specialRateIds: [],
          insuranceDefinitions: [],
          formIds: [],
          deactivated: false,
          doctorBilling: false,
          patientBilling: false,
          description: '',
          edifactNumber: null,
          link: '',
          lgDoctorBilling: false,
          storagePeriod: -1,
          priceDoctor: null,
          pricePatient: null,
          priceLgDoctor: null,
          partnerLab: PartnerLab.NONE,
          synonyms: [],
          acute: false,
          volume: 0,
        }}
        mode="create"
        parameters={parameters}
        labId={labId}
        shortNameValidator={shortName => parameters.length === 0 || !parameters.some(p => p.shortName === shortName)}
        edifactNumberValidator={edifactNumber => {
          if (!edifactNumber) {
            return true;
          }
          return parameters.length === 0 || !parameters.some(p => p.edifactNumber === edifactNumber);
        }}
      />
    </Modal>
  );
};
