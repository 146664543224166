import React from 'react';
import { Avatar, Dropdown, Tag, theme, Typography } from 'antd';
import { CloseOutlined, LogoutOutlined, SwapOutlined, UserOutlined, WarningOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/interface';
import { css } from '@emotion/css';

const { useToken } = theme;
const { Text } = Typography;

interface UserMenuProps {
  roleName?: string;
  username?: string;
  pictureUrl?: string;
  doctorName: string;
  userFullName: string;
  disabled: boolean;
  showDoctorSelect: boolean;
  showDoctorReset: boolean;
  onDoctorSelect: () => void;
  onDoctorReset: () => void;
  onAccountOpen: () => void;
  onLogout: () => void;
}

export const UserMenu: React.FC<UserMenuProps> = ({
  roleName,
  username,
  pictureUrl,
  doctorName,
  disabled,
  userFullName,
  showDoctorSelect,
  showDoctorReset,
  onDoctorSelect,
  onDoctorReset,
  onAccountOpen,
  onLogout,
}) => {
  const { token } = useToken();
  const styles = {
    role: css`
      font-size: ${token.fontSizeSM}px;
    `,
    bold: css`
      font-weight: 500;
    `,
    innerWrapper: css`
      display: flex;
      align-items: center;
      cursor: pointer;
    `,
    userIcon: css`
      font-size: ${token.fontSizeLG}px;
      margin-right: ${token.paddingXS}px;
    `,
    name: css`
      text-align: right;
      display: inline-block;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: ${token.fontSizeSM}px;
    `,
  };

  const pictureTitle = 'Sie können Ihr Profilbild unter https://gravatar.com konfigurieren';
  let items: ItemType[] = [
    {
      key: 'info',
      disabled: true,
      style: { cursor: 'default', whiteSpace: 'normal' },
      label: (
        <>
          <Text type="secondary">Eingeloggt als</Text>
          <p />
          <Text className={styles.bold}>{userFullName}</Text>
          <br />
          <Text className={styles.role} type="secondary">
            {roleName}
          </Text>
        </>
      ),
    },
    { type: 'divider' },
  ];

  if (showDoctorSelect) {
    items = [
      ...items,
      {
        key: 'selectDoctor',
        icon: <SwapOutlined />,
        onClick: onDoctorSelect,
        label: 'Zuweiser festlegen',
      },
    ];
  }

  if (showDoctorReset) {
    items = [
      ...items,
      {
        key: 'resetDoctor',
        icon: <CloseOutlined />,
        onClick: onDoctorReset,
        label: 'Zuweiser zurücksetzen',
      },
    ];
  }

  if (showDoctorSelect || showDoctorReset) {
    items = [...items, { type: 'divider' }];
  }

  items = [
    ...items,
    {
      key: 'account',
      icon: <UserOutlined />,
      onClick: onAccountOpen,
      label: 'Benutzerkonto',
    },
  ];

  items = [
    ...items,
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      danger: true,
      onClick: onLogout,
      label: 'Abmelden',
    },
  ];

  return (
    <Dropdown
      menu={{ items: items }}
      trigger={['click']}
      arrow
      placement="bottomRight"
      overlayStyle={{ minWidth: 250, width: 250, maxWidth: 250 }}
    >
      <div className={styles.innerWrapper}>
        {pictureUrl ? (
          <div title={pictureTitle}>
            <Avatar className={styles.userIcon} src={pictureUrl} />
          </div>
        ) : (
          <UserOutlined className={styles.userIcon} title={pictureTitle} />
        )}
        <div className={styles.name}>
          <span>{username}</span>
          <br />
          <span>
            {disabled && (
              <Tag
                color="warning"
                className={css`
                  margin: 0;
                `}
                icon={<WarningOutlined />}
              >
                Deaktiviert
              </Tag>
            )}{' '}
            {doctorName}
          </span>
        </div>
      </div>
    </Dropdown>
  );
};
