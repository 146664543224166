import React, { ReactNode } from 'react';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { LoadingIndicator } from './LoadingIndicator';
import { css } from '@emotion/css';
import { PdfIframe } from './PdfIframe';

interface Props {
  title: string;
  url: string;
  loading: boolean;
  footer?: ReactNode;
  onClose: () => void;
}

export const PdfPreviewModal: React.FC<Props> = ({ title, url, loading, footer, onClose }) => {
  return (
    <Modal
      className={css`
        top: 16px;
        padding-bottom: 0;
        user-select: none;

        .ant-modal-body {
          height: calc(100vh - 140px);
          padding: 0;
        }
      `}
      title={title}
      destroyOnClose
      open={!!url || loading}
      width="100%"
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ icon: <CloseOutlined /> }}
      okText="Schließen"
      onOk={onClose}
      onCancel={onClose}
      footer={footer}
    >
      {loading ? <LoadingIndicator /> : <PdfIframe url={url} />}
    </Modal>
  );
};
