import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Dropdown, Flex, Row, Space, theme, Typography } from 'antd';
import { SelectedParameter } from '../../../hooks/store/useAppStore.ts';
import { css } from '@emotion/css';
import { DownOutlined, SortAscendingOutlined } from '@ant-design/icons';
import { ParamEntry } from './ParamEntry.tsx';
import { billingTypePriority } from '../../../utils/enumHelpers.ts';
import { useUserSettingsStore } from '../../../hooks/store/useUserSettingsStore.ts';

export const ParamList: FC<{
  params: SelectedParameter[];
  onInfo: (param: SelectedParameter) => void;
  flipParamNames: boolean;
  showPrices: boolean;
  limit: number;
}> = ({ params, onInfo, flipParamNames, showPrices, limit }) => {
  const { token } = theme.useToken();

  const [expanded, setExpanded] = useState(false);
  const [height, setHeight] = useState('auto'); // no initial animation
  const { selectedParamsSortOrder, setSelectedParamsSortOrder } = useUserSettingsStore();

  const container = useRef<HTMLDivElement>(null);

  const sortedParams = useMemo(() => {
    if (selectedParamsSortOrder === 'lastAdded') {
      // no need to sort
      return params.slice(0, !expanded ? limit : undefined);
    }
    if (selectedParamsSortOrder === 'firstAdded') {
      return [...params].reverse().slice(0, !expanded ? limit : undefined);
    }
    return [...params]
      .sort((a, b) => {
        if (selectedParamsSortOrder === 'shortNameAsc') {
          return a.shortName.localeCompare(b.shortName);
        }
        if (selectedParamsSortOrder === 'shortNameDesc') {
          return b.shortName.localeCompare(a.shortName);
        }
        if (selectedParamsSortOrder === 'longNameAsc') {
          return a.longName.localeCompare(b.longName);
        }
        if (selectedParamsSortOrder === 'longNameDesc') {
          return b.longName.localeCompare(a.longName);
        }
        if (selectedParamsSortOrder === 'priceAsc') {
          return (a.billingInfo.price ?? -1) - (b.billingInfo.price ?? -1);
        }
        if (selectedParamsSortOrder === 'priceDesc') {
          return (b.billingInfo.price ?? -1) - (a.billingInfo.price ?? -1);
        }
        if (selectedParamsSortOrder === 'billingType') {
          return billingTypePriority(a.billingInfo.billingType) - billingTypePriority(b.billingInfo.billingType);
        }
        return flipParamNames ? a.longName.localeCompare(b.longName) : a.shortName.localeCompare(b.shortName);
      })
      .slice(0, !expanded ? limit : undefined);
  }, [params, flipParamNames, limit, expanded, selectedParamsSortOrder]);

  const availableLabIds = useMemo(
    () =>
      params.reduce<{ id: string }[]>(
        (labs, param) => (labs.some(l => l.id === param.lab.id) ? labs : [...labs, param.lab]),
        []
      ),
    [params]
  );

  const calculateHeight = () => {
    if (container.current) {
      setHeight(container.current.offsetHeight + 'px');
    }
  };

  useEffect(() => {
    calculateHeight();
  }, [sortedParams]);

  useEffect(() => {
    const listener = () => calculateHeight();
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  return (
    <div
      className={css`
        height: ${height};
        overflow: hidden;
        transition: height 0.3s ease-in-out;
      `}
    >
      <div ref={container}>
        <Row gutter={[8, 8]}>
          {sortedParams.map(param => (
            <Col key={param.id} xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
              <ParamEntry
                param={param}
                swap={flipParamNames}
                onInfo={onInfo}
                showLab={availableLabIds.length > 1}
                showPrice={showPrices}
              />
            </Col>
          ))}
        </Row>
        <Flex align="center" justify="space-between">
          <Space
            className={css`
              padding-top: ${token.paddingXS}px;
            `}
          >
            <Typography.Text type="secondary">{params.length} Parameter insgesamt</Typography.Text>
            <Dropdown
              menu={{
                items: [
                  {
                    label: 'Zuletzt hinzugefügt oben',
                    key: 'lastAdded',
                    onClick: () => setSelectedParamsSortOrder('lastAdded'),
                  },
                  {
                    label: 'Zuletzt hinzugefügt unten',
                    key: 'firstAdded',
                    onClick: () => setSelectedParamsSortOrder('firstAdded'),
                  },
                  {
                    type: 'divider',
                  },
                  {
                    label: 'Kurzname aufsteigend',
                    key: 'shortNameAsc',
                    onClick: () => setSelectedParamsSortOrder('shortNameAsc'),
                  },
                  {
                    label: 'Kurzname absteigend',
                    key: 'shortNameDesc',
                    onClick: () => setSelectedParamsSortOrder('shortNameDesc'),
                  },
                  {
                    type: 'divider',
                  },
                  {
                    label: 'Langbezeichnung aufsteigend',
                    key: 'longNameAsc',
                    onClick: () => setSelectedParamsSortOrder('longNameAsc'),
                  },
                  {
                    label: 'Langbezeichnung absteigend',
                    key: 'longNameDesc',
                    onClick: () => setSelectedParamsSortOrder('longNameDesc'),
                  },
                  {
                    type: 'divider',
                  },
                  {
                    label: 'Preis aufsteigend',
                    key: 'priceAsc',
                    onClick: () => setSelectedParamsSortOrder('priceAsc'),
                  },
                  {
                    label: 'Preis absteigend',
                    key: 'priceDesc',
                    onClick: () => setSelectedParamsSortOrder('priceDesc'),
                  },
                  {
                    type: 'divider',
                  },
                  {
                    label: 'Verrechnungstyp',
                    key: 'billingType',
                    onClick: () => setSelectedParamsSortOrder('billingType'),
                  },
                ],
                selectable: true,
                selectedKeys: [selectedParamsSortOrder],
              }}
              trigger={['click']}
            >
              <SortAscendingOutlined
                className={css`
                  color: ${token.colorPrimary};
                  font-size: 18px;
                `}
              />
            </Dropdown>
          </Space>
          {!expanded && params.length > limit && (
            <Button
              className={css`
                margin: 0;
                height: auto;
                padding: ${token.paddingXS}px 0 0;
              `}
              type="link"
              icon={<DownOutlined />}
              onClick={() => setExpanded(true)}
            >
              Alle Parameter anzeigen
            </Button>
          )}
        </Flex>

        {/*{expanded && <Button onClick={() => setExpanded(false)}>Weniger anzeigen</Button>}*/}
      </div>
    </div>
  );
};
