import React from 'react';
import { App, Modal } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { SpecimenForm } from './SpecimenForm';
import { useForm } from 'antd/es/form/Form';
import { graphql } from '../../../../graphql/generated';
import { Form, Specimen, TestTube } from '../Specimens.tsx';
import { SpecimenCreateInput, SpecimenGroup } from '../../../../graphql/generated/graphql.ts';

const CREATE_SPECIMEN_MUTATION = graphql(`
  mutation CreateSpecimen($input: SpecimenCreateInput!) {
    createSpecimen(input: $input) {
      id
    }
  }
`);

export const CreateSpecimenModal: React.FC<{
  labId: string;
  specimens: Specimen[];
  testTubes: TestTube[];
  forms: Form[];
  modalVisible: boolean;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ labId, specimens, testTubes, forms, modalVisible, onClose, onSuccess }) => {
  const [createSpecimenMutation, { loading }] = useMutation(CREATE_SPECIMEN_MUTATION);
  const [form] = useForm<SpecimenCreateInput>();
  const { message } = App.useApp();

  const create = async (values: SpecimenCreateInput) => {
    try {
      await createSpecimenMutation({
        variables: {
          input: {
            ...values,
            labId: labId,
          },
        },
      });

      message.success('Material wurde erstellt');
      onSuccess();
      closeAndReset();
    } catch (e) {
      message.error('Material konnte nicht erstellt werden');
    }
  };

  const closeAndReset = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width={768}
      title="Neues Material erstellen"
      okText="Erstellen"
      okButtonProps={{ icon: <PlusCircleOutlined /> }}
      open={modalVisible}
      onCancel={closeAndReset}
      onOk={form.submit}
      confirmLoading={loading}
      destroyOnClose
    >
      <SpecimenForm
        form={form}
        initialValues={{
          labId: labId,
          name: '',
          classification: '',
          testTubeIds: [],
          formIds: [],
          group: SpecimenGroup.NONE,
          features: [],
          storagePeriod: 0,
          requiresLocalizations: false,
          localizationInputEnabled: false,
          predefinedLocalizations: [],
        }}
        onFinish={values => create(values as SpecimenCreateInput)}
        nameValidator={name => specimens.length === 0 || !specimens.some(s => s.name === name)}
        testTubes={testTubes}
        forms={forms}
      />
    </Modal>
  );
};
