import React from 'react';
import { Button, Col, Form, FormInstance, Input, InputNumber, Row, Select } from 'antd';
import { TestTubeColor as TestTubeColorComponent } from '../../../../components/TestTubeColor';
import { testTubeColorProperties, translateTestTubeSupplier } from '../../../../utils/enumHelpers';
import {
  TestTubeColor,
  TestTubeCreateInput,
  TestTubeSupplier,
  TestTubeUpdateInput,
} from '../../../../graphql/generated/graphql.ts';
import { LinkOutlined } from '@ant-design/icons';

const { Option } = Select;

export const TestTubeForm: React.FC<{
  form: FormInstance;
  initialValues?: TestTubeCreateInput;
  onFinish: (values: TestTubeCreateInput | TestTubeUpdateInput) => void;
}> = ({ form, initialValues, onFinish }) => {
  return (
    <Form layout="vertical" form={form} initialValues={initialValues} onFinish={onFinish}>
      <Row gutter={[16, 0]}>
        <Col span={8}>
          <Form.Item name="name" label="Name" rules={[{ required: true, whitespace: true }]}>
            <Input autoFocus />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="labelText" label="Etiketten-Text" rules={[{ required: true, whitespace: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="labelText2" label="Etiketten-Text 2">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Form.Item
            name="color"
            label="Farbe"
            rules={[{ required: true, type: 'enum', enum: Object.keys(TestTubeColor) }]}
          >
            <Select showSearch optionFilterProp="search">
              {Object.keys(TestTubeColor).map(color => {
                const properties = testTubeColorProperties(color);
                return (
                  <Option key={color} value={color} search={properties.translation}>
                    <TestTubeColorComponent properties={properties} />
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="supplier"
            label="Lieferant"
            rules={[{ required: true, type: 'enum', enum: Object.keys(TestTubeSupplier) }]}
          >
            <Select>
              {Object.keys(TestTubeSupplier).map(supplier => (
                <Option key={supplier} value={supplier}>
                  {translateTestTubeSupplier(supplier)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Form.Item name="link" label="Link" rules={[{ type: 'url' }]}>
            <Input addonBefore={<LinkOutlined />} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="volume"
            label="Volumen"
            rules={[{ type: 'integer', required: true, min: 0, max: 9999999, message: 'Bitte Zahl angeben' }]}
          >
            <InputNumber inputMode="numeric" required min={0} max={9999999} step={1} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      {/* hack for enter button support */}
      <Button htmlType="submit" hidden />
    </Form>
  );
};
