import React from 'react';
import { Col, Row, Space, theme, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined, WarningTwoTone } from '@ant-design/icons';
import { css, cx } from '@emotion/css';
import { currency } from '../../utils/currency';
import { BillingType } from '../../graphql/generated/graphql.ts';
import { gold } from '@ant-design/colors';

type CostEntry = {
  billingType: BillingType;
  price: number | null;
  pricePatient: number | null;
  text: string;
};

export const CostsSummary: React.FC<{
  entries: CostEntry[];
  showPriceSuggestion: boolean;
  compact?: boolean;
}> = ({ entries, showPriceSuggestion, compact }) => {
  const { token } = theme.useToken();

  const unknownPatientCosts = entries.some(it => it.billingType === BillingType.PATIENT && it.price === null);

  const unknownDoctorCosts = entries.some(
    it => (it.billingType === BillingType.DOCTOR || it.billingType === BillingType.LG_DOCTOR) && it.price === null
  );

  const unknownPriceSuggestion = entries.some(
    it =>
      (it.billingType === BillingType.DOCTOR || it.billingType === BillingType.LG_DOCTOR) && it.pricePatient === null
  );

  const sumPatient = entries
    .filter(it => it.billingType === BillingType.PATIENT && it.price !== null)
    .map(it => it.price!)
    .reduce((sum, current) => sum + current, 0);

  const sumDoctor = entries
    .filter(
      it => (it.billingType === BillingType.DOCTOR || it.billingType === BillingType.LG_DOCTOR) && it.price !== null
    )
    .map(it => it.price!)
    .reduce((sum, current) => sum + current, 0);

  const sumPriceSuggestion = entries
    .filter(
      it =>
        (it.billingType === BillingType.DOCTOR || it.billingType === BillingType.LG_DOCTOR) && it.pricePatient !== null
    )
    .map(it => it.pricePatient!)
    .reduce((sum, current) => sum + current, 0);

  const specialRates = entries
    .filter(it => it.billingType === BillingType.SPECIAL_RATE && it.price !== null)
    .map(it => it)
    .reduce(
      (map, billingInfo) => map.set(billingInfo.text, (map.get(billingInfo.text) ?? 0) + billingInfo.price!),
      new Map<string, number>()
    );

  return (
    <div
      className={cx(
        compact &&
          css`
            max-width: 400px;
          `
      )}
    >
      <Row gutter={[16, 0]}>
        <Col flex="auto">
          <Space>
            <Typography.Text type="secondary">Kosten Patient</Typography.Text>
            {unknownPatientCosts && (
              <Tooltip title="Kosten können nicht genau bestimmt werden. Es sind nicht alle Preisinformationen verfügbar.">
                <WarningTwoTone twoToneColor={gold[5]} />
              </Tooltip>
            )}
          </Space>
        </Col>
        <Col
          flex="100px"
          className={css`
            text-align: right;
          `}
        >
          {currency(sumPatient, '0 €')}
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col flex="auto">
          <Space>
            <Typography.Text type="secondary">Kosten Zuweiser</Typography.Text>
            {unknownDoctorCosts && (
              <Tooltip title="Kosten können nicht genau bestimmt werden. Es sind nicht alle Preisinformationen verfügbar.">
                <WarningTwoTone twoToneColor={gold[5]} />
              </Tooltip>
            )}
          </Space>
        </Col>
        <Col
          flex="100px"
          className={css`
            text-align: right;
          `}
        >
          {currency(sumDoctor, '0 €')}
        </Col>
      </Row>
      {Array.from(specialRates.keys()).map(name => (
        <Row gutter={[16, 0]} key={name}>
          <Col flex="auto">
            <Space>
              <Typography.Text type="secondary">Kosten {name}</Typography.Text>
            </Space>
          </Col>
          <Col
            flex="100px"
            className={css`
              text-align: right;
            `}
          >
            {currency(specialRates.get(name), '0 €')}
          </Col>
        </Row>
      ))}
      {showPriceSuggestion && (
        <Row gutter={[16, 0]}>
          <Col flex="auto">
            <Space>
              <Typography.Text type="secondary">Preisvorschlag</Typography.Text>
              <Tooltip title="Die Summe der Preise an den Patienten über alle Parameter, die mit der Verrechnung „Privat an Zuweiser“ ausgewählt wurden.">
                <InfoCircleOutlined
                  className={css`
                    color: ${token.colorTextSecondary};
                  `}
                />
              </Tooltip>
              {unknownPriceSuggestion && (
                <Tooltip title="Kosten können nicht genau bestimmt werden. Es sind nicht alle Preisinformationen verfügbar.">
                  <WarningTwoTone twoToneColor={gold[5]} />
                </Tooltip>
              )}
            </Space>
          </Col>
          <Col
            flex="100px"
            className={css`
              text-align: right;
            `}
          >
            {currency(sumPriceSuggestion, '0 €')}
          </Col>
        </Row>
      )}
    </div>
  );
};
