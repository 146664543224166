import React from 'react';
import { Button, Checkbox, Col, Form as AntForm, FormInstance, Input, InputNumber, Row, Select, Space } from 'antd';
import { translateSpecimenFeature, translateSpecimenGroup } from '../../../../utils/enumHelpers';
import { TestTubeSelect } from '../../../../components/TestTubeSelect';
import {
  SpecimenCreateInput,
  SpecimenFeature,
  SpecimenGroup,
  SpecimenUpdateInput,
} from '../../../../graphql/generated/graphql.ts';
import { Form, TestTube } from '../Specimens.tsx';
import { EditableTagGroup } from '../../../../components/EditableTagGroup.tsx';
import { useWatch } from 'antd/es/form/Form';

const { Option } = Select;

export const SpecimenForm: React.FC<{
  form: FormInstance;
  initialValues?: SpecimenCreateInput;
  onFinish: (values: SpecimenCreateInput | SpecimenUpdateInput) => void;
  nameValidator: (name: string) => boolean;
  testTubes: TestTube[];
  forms: Form[];
}> = ({ form, initialValues, onFinish, nameValidator, testTubes, forms }) => {
  const localizationInputEnabled = useWatch('localizationInputEnabled', form);
  const requiresLocalizations = useWatch('requiresLocalizations', form);

  return (
    <AntForm layout="vertical" form={form} initialValues={initialValues} onFinish={onFinish}>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <AntForm.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                whitespace: true,
              },
              {
                validator: (_, name) =>
                  nameValidator(name) ? Promise.resolve() : Promise.reject('Dieser Name ist bereits vergeben'),
              },
            ]}
          >
            <Input autoFocus />
          </AntForm.Item>
        </Col>
        <Col span={12}>
          <AntForm.Item
            name="classification"
            label="Materialkennung"
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input />
          </AntForm.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <AntForm.Item
            name="group"
            label="Gruppe"
            rules={[{ type: 'enum', enum: Object.keys(SpecimenGroup), required: true }]}
          >
            <Select>
              {Object.keys(SpecimenGroup).map(group => (
                <Option key={group} value={group}>
                  {translateSpecimenGroup(group)}
                </Option>
              ))}
            </Select>
          </AntForm.Item>
        </Col>
        <Col span={12}>
          <AntForm.Item
            name="storagePeriod"
            label="Lagerdauer (Stunden)"
            rules={[{ type: 'integer', min: 0, max: 1000, message: 'Bitte Anzahl angeben', required: true }]}
          >
            <InputNumber inputMode="numeric" required min={0} max={1000} step={1} style={{ width: '100%' }} />
          </AntForm.Item>
        </Col>
      </Row>
      <AntForm.Item name="testTubeIds" label="Probenbehälter" valuePropName="selected">
        <TestTubeSelect style={{ width: '100%' }} multiple testTubes={testTubes} />
      </AntForm.Item>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <AntForm.Item name="features" label="Eigenschaften">
            <Select
              showSearch
              allowClear
              mode="multiple"
              placeholder="Suche nach Eigenschaften"
              optionFilterProp="search"
              options={Object.keys(SpecimenFeature)
                .sort((a, b) => translateSpecimenFeature(a).localeCompare(translateSpecimenFeature(b)))
                .map(it => ({
                  label: translateSpecimenFeature(it),
                  value: it,
                  search: translateSpecimenFeature(it),
                }))}
            />
          </AntForm.Item>
        </Col>
        <Col span={12}>
          <AntForm.Item name="formIds" label="Formulare">
            <Select
              showSearch
              allowClear
              mode="multiple"
              placeholder="Suche nach Formularen"
              optionFilterProp="search"
              options={[...forms]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(it => ({
                  label: it.name,
                  value: it.id,
                  search: it.name,
                }))}
            />
          </AntForm.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Space direction="vertical">
            <AntForm.Item name="requiresLocalizations" valuePropName="checked" noStyle>
              <Checkbox>Benötigt Lokalisationen</Checkbox>
            </AntForm.Item>
            <AntForm.Item name="localizationInputEnabled" valuePropName="checked" noStyle>
              <Checkbox>Freitext-Lokalisationen erlaubt</Checkbox>
            </AntForm.Item>
          </Space>
        </Col>
        <Col span={12}>
          <AntForm.Item
            name="predefinedLocalizations"
            label="Vorgegebene Lokalisationen"
            valuePropName="tags"
            rules={[
              {
                type: 'array',
                min: requiresLocalizations ? (localizationInputEnabled ? 0 : 1) : 0,
                message: 'Wenn keine Freitext-Lokalisationen erlaubt sind, dann ist mind. eine Vorgabe nötig.',
              },
            ]}
          >
            <EditableTagGroup addLabel="Neue Lokalisation" inputWidth="120px" inputMaxLength={15} />
          </AntForm.Item>
        </Col>
      </Row>
      {/* hack for enter button support */}
      <Button htmlType="submit" hidden />
    </AntForm>
  );
};
