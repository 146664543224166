import React, { useEffect, useState } from 'react';
import { Button, Flex, Input, Select, Table, Tag, theme, Tooltip } from 'antd';
import { MainContent } from '../../components/MainContent';
import { useQuery } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import { useDebounce } from 'use-debounce';
import { Markable } from '../../components/Markable';
import { CheckOutlined, EditOutlined, InfoCircleOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { CreateDoctorModal } from './doctors/CreateDoctorModal';
import { DoctorFragment, UpdateDoctorModal } from './doctors/UpdateDoctorModal';
import { PageHeader } from '@ant-design/pro-components';
import { tableActionCell } from '../../styles/globalCss';
import { getFragmentData, graphql } from '../../graphql/generated';
import { CatalogFragment, LabFragment } from './doctors/DoctorForm.tsx';
import { UpdateDoctorModal_DoctorFragment } from '../../graphql/generated/graphql.ts';

type StateFilter = 'all' | 'active' | 'inactive';

const { Option } = Select;

const DOCTORS_QUERY = graphql(`
  query Doctors {
    doctors {
      ...UpdateDoctorModal_Doctor
    }

    labs {
      ...DoctorForm_Lab
    }

    catalogs {
      ...DoctorForm_Catalog
    }

    insurances {
      ...DoctorForm_Insurance
    }

    specialRates {
      ...DoctorForm_SpecialRate
    }

    themes {
      ...DoctorForm_Theme
    }
  }
`);

export const Doctors: React.FC = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editDoctor, setEditDoctor] = useState<UpdateDoctorModal_DoctorFragment | null>(null);
  const [selectedLabId, setSelectedLabId] = useState<string | null>(null);
  const [selectedCatalogId, setSelectedCatalogId] = useState<string | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [stateFilter, setStateFilter] = useState<StateFilter>('all');
  const [debouncedSearch] = useDebounce(search, 250);
  const [filteredDoctors, setFilteredDoctors] = useState<UpdateDoctorModal_DoctorFragment[]>([]);
  const { token } = theme.useToken();

  const { data, loading, refetch } = useQuery(DOCTORS_QUERY, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000,
  });

  useEffect(() => {
    let result =
      getFragmentData(DoctorFragment, data?.doctors)?.filter(doctor => {
        if (debouncedSearch && debouncedSearch.length) {
          const searchValue = debouncedSearch.toLowerCase();
          return (
            doctor.name.toLowerCase().includes(searchValue) ||
            doctor.meAddress.toLowerCase().includes(searchValue) ||
            doctor.city.toLowerCase().includes(searchValue) ||
            doctor.firstName?.toLowerCase().includes(searchValue) ||
            doctor.lastName?.toLowerCase().includes(searchValue) ||
            doctor.aisImportDoctorId?.toLowerCase().includes(searchValue)
          );
        }
        return true;
      }) ?? [];

    if (selectedLabId && selectedLabId.length) {
      result = result.filter(doctor => doctor.assignedLabs.some(assignment => assignment.lab.id === selectedLabId));
    }

    if (selectedCatalogId && selectedCatalogId.length) {
      result = result.filter(doctor => doctor.catalogs.some(it => it.id === selectedCatalogId));
    }

    if (stateFilter !== 'all') {
      result = result.filter(doctor => (stateFilter === 'active' ? !doctor.disabled : doctor.disabled));
    }

    setFilteredDoctors(result);
  }, [debouncedSearch, selectedLabId, selectedCatalogId, data, stateFilter]);

  const columns: ColumnsType<UpdateDoctorModal_DoctorFragment> = [
    {
      title: 'Anzeigename',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      defaultSortOrder: 'ascend',
      width: 130,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Aktiv',
      dataIndex: 'disabled',
      key: 'disabled',
      width: 70,
      sorter: (a, b) => Number(b.disabled) - Number(a.disabled),
      render: value => (!value ? <CheckOutlined /> : ''),
    },
    {
      title: 'ME-Adresse',
      dataIndex: 'meAddress',
      key: 'meAddress',
      ellipsis: true,
      width: 120,
      sorter: (a, b) => a.meAddress.localeCompare(b.meAddress),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Ort',
      dataIndex: 'city',
      key: 'city',
      ellipsis: true,
      width: 100,
      sorter: (a, b) => a.city.localeCompare(b.city),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Vorname',
      dataIndex: 'firstName',
      key: 'firstName',
      ellipsis: true,
      width: 100,
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Nachname / Firma',
      dataIndex: 'lastName',
      key: 'lastName',
      ellipsis: true,
      width: 160,
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Labors',
      dataIndex: 'assignedLabs',
      key: 'assignedLabs',
      width: 90,
      render: (_, record) => record.assignedLabs.map(v => <Tag key={v.lab.id}>{v.lab.name}</Tag>),
      ellipsis: true,
    },
    {
      title: 'Theme',
      dataIndex: 'theme',
      key: 'theme',
      width: 90,
      render: (_, record) => record?.theme?.name,
      ellipsis: true,
    },
    {
      title: 'Kataloge',
      dataIndex: 'catalogs',
      key: 'catalogs',
      width: 100,
      render: (_, record) =>
        record.catalogs.map(v => (
          <Tag key={v.id}>
            {v.lab.shortName} - {v.name}
          </Tag>
        )),
      ellipsis: true,
    },
    {
      title: 'Multi-Seat X-Arzt',
      dataIndex: 'aisImportDoctorId',
      key: 'aisImportDoctorId',
      width: 160,
      ellipsis: true,
      sorter: (a, b) => a.aisImportDoctorId.localeCompare(b.aisImportDoctorId),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '50px',
      className: tableActionCell,
      render: (_, record) => <Button icon={<EditOutlined />} type="text" onClick={() => setEditDoctor(record)} />,
    },
  ];

  return (
    <MainContent>
      <PageHeader
        title="Zuweiser"
        extra={
          <Flex wrap gap={token.paddingXS} align="center">
            <Select
              style={{ width: '130px' }}
              onChange={setStateFilter}
              value={stateFilter}
              options={[
                {
                  label: 'Alle',
                  value: 'all',
                },
                {
                  label: 'Nur Aktive',
                  value: 'active',
                },
                {
                  label: 'Nur Inaktive',
                  value: 'inactive',
                },
              ]}
            />
            <Input
              autoFocus
              allowClear
              placeholder="Suche"
              value={search ?? ''}
              onChange={e => setSearch(e.target.value)}
              prefix={<SearchOutlined />}
              suffix={
                <Tooltip title="Suche nach Anzeigename, ME-Adresse, Ort, Vorname, Nachname oder Multi-Seat-X-Arzt">
                  <InfoCircleOutlined />
                </Tooltip>
              }
              style={{ width: '250px' }}
            />
            <Select
              allowClear
              showSearch
              optionFilterProp="search"
              placeholder="Labor-Filter"
              popupMatchSelectWidth={false}
              style={{ width: '250px' }}
              onChange={setSelectedLabId}
              value={selectedLabId || undefined}
            >
              {data?.labs?.map(l => {
                const lab = getFragmentData(LabFragment, l);
                return (
                  <Option key={lab.id} value={lab.id} search={lab.name}>
                    {lab.name}
                  </Option>
                );
              })}
            </Select>
            <Select
              allowClear
              showSearch
              optionFilterProp="search"
              placeholder="Katalog-Filter"
              popupMatchSelectWidth={false}
              style={{ width: '250px' }}
              onChange={setSelectedCatalogId}
              value={selectedCatalogId || undefined}
            >
              {data?.catalogs?.map(it => {
                const catalog = getFragmentData(CatalogFragment, it);
                return (
                  <Option key={catalog.id} value={catalog.id} search={`${catalog.lab.shortName} - ${catalog.name}`}>
                    {catalog.lab.shortName} - {catalog.name}
                  </Option>
                );
              })}
            </Select>
            <Button icon={<PlusCircleOutlined />} type="primary" onClick={() => setCreateModalOpen(true)}>
              Neuer Zuweiser
            </Button>
          </Flex>
        }
        style={{ padding: 0, paddingBottom: 'inherit' }}
      />
      <Table<UpdateDoctorModal_DoctorFragment>
        scroll={{ x: 'max-content' }}
        rowKey={record => record.id}
        sticky={true}
        size="middle"
        showSorterTooltip={false}
        dataSource={filteredDoctors}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} bis ${range[1]} von ${total} Zuweisern`,
        }}
        loading={loading}
        columns={columns}
      />
      <CreateDoctorModal
        open={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false);
          refetch();
        }}
        labFragments={data?.labs ?? []}
        catalogFragments={data?.catalogs ?? []}
        insuranceFragments={data?.insurances ?? []}
        specialRateFragments={data?.specialRates ?? []}
        themeFragments={data?.themes ?? []}
      />
      <UpdateDoctorModal
        doctor={editDoctor}
        onClose={() => {
          setEditDoctor(null);
          refetch();
        }}
        labFragments={data?.labs ?? []}
        catalogFragments={data?.catalogs ?? []}
        insuranceFragments={data?.insurances ?? []}
        specialRateFragments={data?.specialRates ?? []}
        themeFragments={data?.themes ?? []}
      />
    </MainContent>
  );
};
