import React from 'react';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  FormInstance,
  InputNumber,
  Row,
  Select,
  Tabs,
  Transfer,
  Typography,
} from 'antd';
import { SpecimenSelect } from '../../../../components/SpecimenSelect';
import { ParameterInsuranceEditor } from '../../../../components/ParameterInsuranceEditor';
import { CatalogParameterUpdateInput } from '../../../../graphql/generated/graphql.ts';
import { graphql } from '../../../../graphql/generated';
import { useQuery } from '@apollo/client';
import { LoadingIndicator } from '../../../../components/LoadingIndicator.tsx';

const CATALOG_PARAMETER_FORM_QUERY = graphql(`
  query CatalogParameterForm($labId: ID!) {
    insurances {
      id
      shortName
    }

    labSpecialRates(labId: $labId) {
      id
      name
      shortName
      code
    }

    labDiagnoses(labId: $labId) {
      id
      name
    }

    labSpecimens(labId: $labId) {
      id
      name
      classification
    }

    labForms(labId: $labId) {
      id
      name
    }

    labPeriodicGroups(labId: $labId) {
      id
      name
    }
  }
`);

export const CatalogParameterForm: React.FC<{
  form: FormInstance<CatalogParameterUpdateInput>;
  labId: string;
  onFinish: (values: CatalogParameterUpdateInput) => void;
}> = ({ form, labId, onFinish }) => {
  const { data, loading } = useQuery(CATALOG_PARAMETER_FORM_QUERY, {
    variables: {
      labId: labId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !labId,
  });

  if (loading) {
    return <LoadingIndicator height="200px" />;
  }

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Tabs
        defaultActiveKey="grunddaten"
        size="small"
        items={[
          {
            key: 'grunddaten',
            label: 'Grunddaten',
            forceRender: true,
            children: (
              <>
                <Divider orientation="left">Privat an Zuweiser</Divider>
                <Row gutter={[16, 0]}>
                  <Col span={6}>
                    <Form.Item name="doctorBilling" valuePropName="checked">
                      <Checkbox>Anforderbar</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="useDoctorBilling" valuePropName="checked">
                      <Checkbox>Überschreiben</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="priceDoctor"
                      label="Preis Zuweiser"
                      rules={[
                        {
                          type: 'number',
                          required: false,
                          min: 0,
                          max: 9999.99,
                          message: 'Bitte validen Betrag angeben',
                        },
                      ]}
                    >
                      <InputNumber
                        inputMode="decimal"
                        precision={2}
                        decimalSeparator=","
                        min={0}
                        max={9999.99}
                        step={0.01}
                        style={{ width: '100%' }}
                        addonAfter="€"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="usePriceDoctor" valuePropName="checked">
                      <Checkbox>Überschreiben</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider orientation="left">Privat an Patient</Divider>
                <Row gutter={[16, 0]}>
                  <Col span={6}>
                    <Form.Item name="patientBilling" valuePropName="checked">
                      <Checkbox>Anforderbar</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="usePatientBilling" valuePropName="checked">
                      <Checkbox>Überschreiben</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="pricePatient"
                      label="Preis Patient"
                      rules={[
                        {
                          type: 'number',
                          required: false,
                          min: 0,
                          max: 9999.99,
                          message: 'Bitte validen Betrag angeben',
                        },
                      ]}
                    >
                      <InputNumber
                        inputMode="decimal"
                        precision={2}
                        decimalSeparator=","
                        min={0}
                        max={9999.99}
                        step={0.01}
                        style={{ width: '100%' }}
                        addonAfter="€"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="usePricePatient" valuePropName="checked">
                      <Checkbox>Überschreiben</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider orientation="left">LG - Privat an Zuweiser</Divider>
                <Row gutter={[16, 0]}>
                  <Col span={6}>
                    <Form.Item name="lgDoctorBilling" valuePropName="checked">
                      <Checkbox>Anforderbar</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="useLgDoctorBilling" valuePropName="checked">
                      <Checkbox>Überschreiben</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="priceLgDoctor"
                      label="Preis LG-Zuweiser"
                      rules={[
                        {
                          type: 'number',
                          required: false,
                          min: 0,
                          max: 9999.99,
                          message: 'Bitte validen Betrag angeben',
                        },
                      ]}
                    >
                      <InputNumber
                        inputMode="decimal"
                        precision={2}
                        decimalSeparator=","
                        min={0}
                        max={9999.99}
                        step={0.01}
                        style={{ width: '100%' }}
                        addonAfter="€"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="usePriceLgDoctor" valuePropName="checked">
                      <Checkbox>Überschreiben</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider orientation="left">Material</Divider>
                <Form.Item name="specimenIds" valuePropName="selected">
                  <SpecimenSelect style={{ width: '100%' }} multiple specimens={data?.labSpecimens ?? []} />
                </Form.Item>
                <Form.Item name="useSpecimens" valuePropName="checked">
                  <Checkbox>Überschreiben</Checkbox>
                </Form.Item>
                <Divider orientation="left">Formulare</Divider>
                <Form.Item name="formIds">
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    placeholder="Suche nach Formularen"
                    optionFilterProp="search"
                    options={[...(data?.labForms ?? [])]
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(it => ({
                        label: it.name,
                        value: it.id,
                        search: it.name,
                      }))}
                  />
                </Form.Item>
                <Form.Item name="useForms" valuePropName="checked">
                  <Checkbox>Überschreiben</Checkbox>
                </Form.Item>
              </>
            ),
          },
          {
            key: 'krankenkassen',
            label: 'Krankenkassen',
            forceRender: true,
            children: (
              <>
                <Form.Item name="useInsuranceDefinitions" valuePropName="checked">
                  <Checkbox>Überschreiben</Checkbox>
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.insuranceDefinitions !== curValues.insuranceDefinitions
                  }
                >
                  {() => {
                    return (
                      <ParameterInsuranceEditor
                        periodicGroups={data?.labPeriodicGroups ?? []}
                        insurances={data?.insurances ?? []}
                        diagnoses={data?.labDiagnoses ?? []}
                        lg={false}
                      />
                    );
                  }}
                </Form.Item>
              </>
            ),
          },
          {
            key: 'lg-krankenkassen',
            label: 'LG-Krankenkassen',
            forceRender: true,
            children: (
              <>
                <Form.Item name="useLgInsuranceDefinitions" valuePropName="checked">
                  <Checkbox>Überschreiben</Checkbox>
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.insuranceDefinitions !== curValues.insuranceDefinitions
                  }
                >
                  {() => {
                    return (
                      <ParameterInsuranceEditor
                        periodicGroups={data?.labPeriodicGroups ?? []}
                        insurances={data?.insurances ?? []}
                        diagnoses={data?.labDiagnoses ?? []}
                        lg={true}
                      />
                    );
                  }}
                </Form.Item>
              </>
            ),
          },
          {
            key: 'sondertarife',
            label: 'Sondertarife',
            forceRender: true,
            children: (
              <>
                <Form.Item name="useSpecialRates" valuePropName="checked">
                  <Checkbox>Überschreiben</Checkbox>
                </Form.Item>
                <Form.Item name="specialRateIds" valuePropName="targetKeys">
                  <Transfer
                    dataSource={[...(data?.labSpecialRates ?? [])].sort((a, b) =>
                      a.shortName.localeCompare(b.shortName)
                    )}
                    titles={['Nicht anforderbar', 'Anforderbar']}
                    render={sr => (
                      <>
                        {sr.shortName} <Typography.Text type="secondary">{sr.code}</Typography.Text>
                      </>
                    )}
                    showSearch
                    filterOption={(inputValue, specialRate) =>
                      specialRate.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                      specialRate.shortName.toLowerCase().includes(inputValue.toLowerCase())
                    }
                    listStyle={{ height: '300px', width: '100%', overflow: 'hidden' }}
                    rowKey={sr => sr.id}
                  />
                </Form.Item>
              </>
            ),
          },
        ]}
      />
      {/* hack for enter button support */}
      <Button htmlType="submit" hidden />
    </Form>
  );
};
