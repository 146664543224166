import React from 'react';
import { Checkbox, DatePicker, Descriptions, Input, Modal, Radio, Select, Space, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FormLabel } from '../../../../components/FormLabel';
import { SHORT_DATE_FORMAT } from '../../../../utils/dateFormatUtils';
import { css } from '@emotion/css';
import { FormFieldInput, FormFieldType } from '../../../../graphql/generated/graphql.ts';

const renderPreviewComponent = (field: FormFieldInput) => {
  switch (field?.type) {
    case FormFieldType.INPUT:
      return <Input placeholder={field.placeholder} defaultValue={field.defaultValue} />;
    case FormFieldType.DATE:
      return <DatePicker style={{ width: '100%' }} format={SHORT_DATE_FORMAT} placeholder={field.placeholder} />;
    case FormFieldType.SELECT:
      return (
        <Select
          style={{ width: '100%' }}
          defaultValue={field.options.find(it => it.selected)?.value}
          placeholder={field.placeholder}
          allowClear
          options={field.options.map(option => ({
            label: option.label,
            value: option.value,
          }))}
        />
      );
    case FormFieldType.OPTION_GROUP:
      return (
        <Radio.Group
          defaultValue={field.options.find(it => it.selected)?.value}
          options={field.options.map(option => ({
            label: option.label,
            value: option.value,
          }))}
        />
      );
    case FormFieldType.CHECKBOX_GROUP:
      return (
        <Checkbox.Group defaultValue={field.options.filter(it => it.selected).map(it => it.value)}>
          <Space direction="vertical">
            {field.options.map((option, index) => (
              <Checkbox key={index} value={option.value}>
                {option.label}
              </Checkbox>
            ))}
          </Space>
        </Checkbox.Group>
      );
    default:
      return <Typography.Text type="danger">Unbekannter Feldtyp {field?.type}</Typography.Text>;
  }
};

export const FormPreviewModal: React.FC<{
  header: string;
  teaser: string;
  fields: FormFieldInput[];
  open: boolean;
  onClose: () => void;
}> = ({ header, teaser, fields, open, onClose }) => {
  return (
    <Modal
      title={header}
      open={open}
      onCancel={onClose}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ icon: <CloseOutlined /> }}
      cancelText="Schließen"
      width={768}
      destroyOnClose
    >
      <>
        <p
          className={css`
            white-space: pre-wrap;
          `}
        >
          {teaser}
        </p>
        <Descriptions column={1} size="small" bordered labelStyle={{ width: '40%' }}>
          {fields?.map((field, index) => {
            return (
              <Descriptions.Item
                key={index}
                label={<FormLabel label={field?.label} help={field?.help} required={field?.required} />}
              >
                {renderPreviewComponent(field)}
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      </>
    </Modal>
  );
};
