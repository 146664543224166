import { User } from 'oidc-client-ts';

export enum Role {
  ROLE_LR_MEDCOM = 'ROLE_LR_MEDCOM',
  ROLE_LR_LAB_ADMIN = 'ROLE_LR_LAB_ADMIN',
  ROLE_LR_USER = 'ROLE_LR_USER',
  ROLE_LR_LAB_STAFF = 'ROLE_LR_LAB_STAFF',
  ROLE_LR_FRONT_DESK = 'ROLE_LR_FRONT_DESK',
}

export const realmRoles = (user: User | null | undefined): string[] => {
  if (!user?.profile) {
    return [];
  }
  const realmAccess = user.profile.realm_access as { roles: string[] };
  return realmAccess.roles;
};

const hasRealmRole = (user: User, role: Role | string) => realmRoles(user).includes(role);

export const hasSomeRole = (roles: Role[], user: User | null | undefined): boolean =>
  !!user && roles.some(r => hasRealmRole(user, r));

export const hasAnyRelevantRole = (user: User | null | undefined): boolean =>
  !!user && Object.keys(Role).some(r => hasRealmRole(user, r));

export const userFullname = (user: User | undefined | null, fallbackName: string = '') => {
  const salutation = user?.profile?.salutation ? user?.profile?.salutation + ' ' : '';
  const preTitle = user?.profile?.pre_title ? user?.profile?.pre_title + ' ' : '';
  const firstName = user?.profile?.given_name ? user?.profile?.given_name + ' ' : '';
  const lastName = user?.profile?.family_name
    ? user?.profile?.family_name + (user?.profile?.post_title ? '' : ' ')
    : '';
  const postTitle = user?.profile?.post_title ? ', ' + user?.profile?.post_title : '';

  if (firstName && lastName) {
    return salutation + preTitle + firstName + lastName + postTitle;
  }
  return salutation + preTitle + fallbackName + postTitle;
};

export const hasFrontDeskRole = (user: User | undefined | null) => hasSomeRole([Role.ROLE_LR_FRONT_DESK], user);

export const translateRole = (role: Role | string) => {
  switch (role) {
    case Role.ROLE_LR_MEDCOM:
      return 'Medcom Administrator';
    case Role.ROLE_LR_LAB_ADMIN:
      return 'Labor Administrator';
    case Role.ROLE_LR_USER:
      return 'Zuweiser';
    case Role.ROLE_LR_LAB_STAFF:
      return 'Labor Mitarbeiter';
    case Role.ROLE_LR_FRONT_DESK:
      return 'Labor Patientenverkehr';
    default:
      return 'Unbekannte Rolle';
  }
};
