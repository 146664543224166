import { useMutation } from '@apollo/client';
import { useDoctorAisExportTypes } from './useDoctorAisExportTypes';
import { graphql } from '../graphql/generated';
import { useAuth } from 'react-oidc-context';
import { useCurrentContextStore } from './store/useCurrentContextStore.ts';
import { AisExportType } from '../graphql/generated/graphql.ts';

type AisExportResult = {
  encodedFile: string;
  filename: string;
  mimeType: string;
};

type AisExportableRequest = {
  id: string;
  orders: Array<{
    aisExportedAt?: string | null;
  }>;
  additionalData: Record<string, string>;
};

const MARK_AIS_EXPORTED_MUTATION = graphql(`
  mutation MarkAisExported($requestId: ID!) {
    markAisExported(requestId: $requestId) {
      id
    }
  }
`);

const OUT_PDF_KEY = 'Out-Pdf';

export const useAisExportRequest = (): ((
  request: AisExportableRequest,
  onError: (msg: string) => void,
  onSuccess: () => void
) => void) => {
  const auth = useAuth();
  const { currentDoctorId } = useCurrentContextStore();
  const doctorAisExportTypes = useDoctorAisExportTypes(currentDoctorId);
  const [markAisExportedMutation] = useMutation(MARK_AIS_EXPORTED_MUTATION);

  return async (request: AisExportableRequest, onError: (msg: string) => void, onSuccess: () => void) => {
    const exportNeeded = request.orders.some(it => !it.aisExportedAt);
    if (!exportNeeded || !window.nativeApi) {
      return;
    }

    const aisExportTypes = [...doctorAisExportTypes];
    const commonPdfExportEnabled = aisExportTypes.includes(AisExportType.PDF);
    if (!commonPdfExportEnabled && request.additionalData[OUT_PDF_KEY]) {
      aisExportTypes.push(AisExportType.PDF);
    }

    if (!aisExportTypes.length) {
      return;
    }

    for (const aisExportType of aisExportTypes) {
      const endpoint = window._env_.API_URL + `/rest/ais-export?requestId=${request.id}&type=${aisExportType}`;
      try {
        const response = await fetch(endpoint, {
          headers: {
            authorization: `Bearer ${auth.user?.access_token}`,
          },
        });

        if (!response.ok) {
          onError('Server-Antwort ist nicht OK');
          return;
        }

        const result = (await response.json()) as AisExportResult[];
        for (const aisExport of result) {
          const buffer = Uint8Array.from(window.atob(aisExport.encodedFile), c => c.charCodeAt(0));

          if (aisExportType === AisExportType.PDF) {
            if (commonPdfExportEnabled) {
              await window.nativeApi.aisExport(aisExport.filename, buffer);
            }
            if (request.additionalData[OUT_PDF_KEY]) {
              await window.nativeApi.aisExportPdf(request.additionalData[OUT_PDF_KEY], buffer);
            }
          } else {
            await window.nativeApi.aisExport(aisExport.filename, buffer);
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          onError(e.message);
        } else {
          onError('Details unbekannt');
        }
        return;
      }
    }

    try {
      await markAisExportedMutation({
        variables: {
          requestId: request.id,
        },
      });

      onSuccess();
    } catch {
      onError('Anforderung konnte nicht als exportiert markiert werden');
    }
  };
};
