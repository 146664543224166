import Icon, { TableOutlined } from '@ant-design/icons';
import { Badge, Button, Popover, theme, Tree, TreeDataNode } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NodeDragEventParams } from 'rc-tree/lib/contextTypes';
import { EventDataNode, Key } from 'rc-tree/lib/interface';
import DragDotsSvg from './icons/DragDotsSvg';
import { moveInArray } from '../utils/array';
import { css } from '@emotion/css';
import { TableColumnSetting, useUserSettingsStore } from '../hooks/store/useUserSettingsStore.ts';

const { useToken } = theme;

export const TableColumnSettings: React.FC<{
  tableKey: string;
  initialColumnSettings: TableColumnSetting[];
  onChange: (columnSettings: TableColumnSetting[]) => void;
}> = ({ tableKey, initialColumnSettings, onChange }) => {
  const { token } = useToken();
  const styles = {
    tree: css`
      .ant-tree-switcher {
        display: none;
      }
      .ant-tree-checkbox {
        margin-right: 0;
      }
    `,
    button: css`
      padding-right: ${token.paddingXS}px;
      padding-left: ${token.paddingXS}px;
    `,
  };
  const [cachedInitialColumnSettings] = useState(initialColumnSettings);
  const [initialized, setInitialized] = useState(false);
  const [defaultUsed, setDefaultUsed] = useState(true);
  const { tableColumnSettings, updateTableColumnSettings } = useUserSettingsStore();
  const columnSettings = useMemo(() => tableColumnSettings[tableKey] ?? [], [tableKey, tableColumnSettings]);

  /** trigger change if the value has been loaded from localStorage */
  useEffect(() => {
    if (initialized) {
      return;
    }
    setInitialized(true);

    if (JSON.stringify(columnSettings) !== JSON.stringify(cachedInitialColumnSettings)) {
      // checks id object contents are identical
      onChange(columnSettings);
      setDefaultUsed(false);
    }
  }, [initialized, onChange, columnSettings, cachedInitialColumnSettings]);

  const triggerChangeAndUpdate = useCallback(
    (updatedColumnSettings: TableColumnSetting[], defaultUsed: boolean) => {
      const copy = [...updatedColumnSettings]; // prevents mutation
      updateTableColumnSettings(tableKey, copy);
      onChange(copy);
      setDefaultUsed(defaultUsed);
    },
    [tableKey, updateTableColumnSettings, onChange]
  );

  /** check if stored column settings are valid and fix them if not */
  useEffect(() => {
    const icsIds = cachedInitialColumnSettings.map(cs => cs.id);
    const csIds = columnSettings.map(cs => cs.id);
    let reinitialize = false;

    icsIds.forEach(icsId => {
      if (!csIds.includes(icsId)) {
        reinitialize = true;
      }
    });
    csIds.forEach(csId => {
      if (!icsIds.includes(csId)) {
        reinitialize = true;
      }
    });

    if (reinitialize) {
      triggerChangeAndUpdate(cachedInitialColumnSettings, true);
    }
  }, [cachedInitialColumnSettings, columnSettings, triggerChangeAndUpdate]);

  const onDrop = (info: NodeDragEventParams & { dragNode: EventDataNode<unknown>; dropPosition: number }) => {
    const fromPosition = treeData.findIndex(node => node.key === info.dragNode.key);
    let toPosition = info.dropPosition;
    if (toPosition === -1) {
      toPosition = 0;
    }
    if (toPosition > fromPosition) {
      --toPosition;
    }

    moveInArray(columnSettings, fromPosition, toPosition);
    triggerChangeAndUpdate(columnSettings, false);
  };

  const onCheck = (checked: { checked: Key[]; halfChecked: Key[] } | Key[]) => {
    triggerChangeAndUpdate(
      columnSettings.map(cs => ({ ...cs, visible: (checked as Key[]).includes(cs.id) })),
      false
    );
  };

  const checkAll = () => {
    triggerChangeAndUpdate(
      columnSettings.map(cs => ({ ...cs, visible: true })),
      false
    );
  };

  const reset = () => {
    triggerChangeAndUpdate(cachedInitialColumnSettings, true);
  };

  const treeData: TreeDataNode[] = columnSettings.map(cs => ({
    title: cs.title,
    key: cs.id,
    disableCheckbox: !cs.checkable,
  }));
  const checkedKeys = columnSettings.filter(cs => cs.visible).map(cs => cs.id);

  return (
    <Popover
      placement="bottomRight"
      title="Anzeige anpassen"
      content={
        <React.Fragment>
          <Button size="small" type="link" onClick={checkAll}>
            Alle
          </Button>{' '}
          <Button size="small" type="link" onClick={reset}>
            Zurücksetzen
          </Button>
          <p />
          <Tree
            className={styles.tree}
            icon={<Icon component={DragDotsSvg} />}
            checkable
            draggable
            showIcon
            selectable={false}
            onDrop={onDrop}
            onCheck={onCheck}
            treeData={treeData}
            checkedKeys={checkedKeys}
          />
        </React.Fragment>
      }
      trigger="click"
    >
      <Badge status="success" dot={!defaultUsed}>
        <Button className={styles.button}>
          <TableOutlined />
        </Button>
      </Badge>
    </Popover>
  );
};
