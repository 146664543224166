import React, { useMemo } from 'react';
import { Button, Form, FormInstance, Input, Transfer, Typography } from 'antd';
import {
  CatalogCreateInput,
  CatalogForm_ParameterFragment,
  CatalogUpdateInput,
} from '../../../../graphql/generated/graphql.ts';
import { FragmentType, getFragmentData, graphql } from '../../../../graphql/generated';

export const ParameterFragment = graphql(`
  fragment CatalogForm_Parameter on Parameter {
    id
    shortName
    longName
    synonyms
  }
`);

export const CatalogForm: React.FC<{
  form: FormInstance<CatalogCreateInput | CatalogUpdateInput>;
  parameterFragments: FragmentType<typeof ParameterFragment>[];
  onFinish: (values: CatalogCreateInput | CatalogUpdateInput) => void;
  initialValues?: CatalogCreateInput;
}> = ({ form, parameterFragments, onFinish, initialValues }) => {
  const parameters = getFragmentData(ParameterFragment, parameterFragments);

  const sortedParameters = useMemo(() => {
    return [...parameters].sort((a, b) => a.shortName.localeCompare(b.shortName));
  }, [parameters]);

  return (
    <Form layout="vertical" form={form} onFinish={onFinish} initialValues={initialValues}>
      <Form.Item name="name" label="Name" rules={[{ required: true, whitespace: true }]}>
        <Input autoFocus />
      </Form.Item>
      <Form.Item name="description" label="Beschreibung">
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item name="parameterIds" label="Parameter" valuePropName="targetKeys">
        <Transfer<CatalogForm_ParameterFragment>
          dataSource={sortedParameters}
          titles={['Verfügbare Parameter', 'Ausgewählte Parameter']}
          render={param => (
            <>
              {param.shortName} <Typography.Text type="secondary">{param.longName}</Typography.Text>
            </>
          )}
          showSearch
          filterOption={(inputValue, param) =>
            param.shortName.toLowerCase().includes(inputValue.toLowerCase()) ||
            param.longName.toLowerCase().includes(inputValue.toLowerCase()) ||
            param.synonyms.some(it => it.toLowerCase().includes(inputValue.toLowerCase()))
          }
          listStyle={{ height: '400px', width: '100%', overflow: 'hidden' }}
          rowKey={param => param.id}
        />
      </Form.Item>
      {/* hack for enter button support */}
      <Button htmlType="submit" hidden />
    </Form>
  );
};
