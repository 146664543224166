import React, { useEffect } from 'react';
import { App, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { TestTubeForm } from './TestTubeForm';
import { useForm } from 'antd/es/form/Form';
import { graphql } from '../../../../graphql/generated';
import { TestTube } from '../TestTubes.tsx';
import { TestTubeColor, TestTubeSupplier, TestTubeUpdateInput } from '../../../../graphql/generated/graphql.ts';

const UPDATE_TEST_TUBE_MUTATION = graphql(`
  mutation UpdateTestTube($id: ID!, $input: TestTubeUpdateInput!) {
    updateTestTube(id: $id, input: $input) {
      id
    }
  }
`);

export const UpdateTestTubeModal: React.FC<{
  testTube: TestTube | null;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ testTube, onClose, onSuccess }) => {
  const [updateTestTubeMutation, { loading }] = useMutation(UPDATE_TEST_TUBE_MUTATION);
  const [form] = useForm<TestTubeUpdateInput>();
  const { message } = App.useApp();

  const update = async (values: TestTubeUpdateInput) => {
    try {
      await updateTestTubeMutation({
        variables: {
          id: testTube?.id ?? '',
          input: { ...values },
        },
      });

      message.success('Probenbehälter wurde aktualisiert');
      onSuccess();
      closeAndReset();
    } catch (e) {
      message.error('Probenbehälter konnte nicht aktualisiert werden');
    }
  };

  const closeAndReset = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue({
      name: testTube?.name ?? '',
      color: testTube?.color ?? TestTubeColor.NONE,
      supplier: testTube?.supplier ?? TestTubeSupplier.VACUETTE,
      labelText: testTube?.labelText ?? '',
      labelText2: testTube?.labelText2 ?? '',
      link: testTube?.link ?? '',
      volume: testTube?.volume ?? 0,
    });
  }, [form, testTube]);

  return (
    <Modal
      width={768}
      title="Probenbehälter bearbeiten"
      okText="Aktualisieren"
      okButtonProps={{ icon: <EditOutlined /> }}
      open={!!testTube}
      onCancel={closeAndReset}
      onOk={form.submit}
      confirmLoading={loading}
      destroyOnClose
      forceRender
    >
      <TestTubeForm form={form} onFinish={update} />
    </Modal>
  );
};
