import { useCallback, useState } from 'react';
import { useAuth } from 'react-oidc-context';

export const useTextResource = (
  onError: () => void
): [value: string, fetchAndSet: (endpoint: string) => void, reset: () => void, loading: boolean] => {
  const auth = useAuth();
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchAndSet = useCallback(
    async (endpoint: string) => {
      setLoading(true);
      try {
        const response = await fetch(endpoint, {
          headers: {
            authorization: `Bearer ${auth.user?.access_token}`,
          },
        });

        if (!response.ok) {
          onError();
          return;
        }

        const value = await response.text();
        setValue(value);
      } catch {
        onError();
      } finally {
        setLoading(false);
      }
    },
    [auth, onError]
  );

  const reset = () => setValue('');

  return [value, fetchAndSet, reset, loading];
};
