import React from 'react';

export const SearchPatientSvg: React.FC = () => (
  <svg viewBox="0 0 50 58" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.254 53.879c-11.292 0-18.737-3.7-20.75-4.835a.506.506 0 0 1-.247-.34 20.429 20.429 0 0 1-.253-3.697l.01-.28c.088-2.823.432-5.907 3.429-6.974 5.617-1.444 10.744-6.837 10.795-6.891a.505.505 0 1 1 .736.69c-.216.23-5.374 5.66-11.236 7.165-2.354.84-2.633 3.438-2.713 6.027 0 .048-.003.108-.007.166l-.004.108a20.766 20.766 0 0 0 .193 3.262c2.225 1.218 9.359 4.59 20.047 4.59 10.742 0 17.827-3.362 20.035-4.577a22.34 22.34 0 0 0 .194-3.258 2.048 2.048 0 0 1-.012-.295c-.08-2.573-.359-5.172-2.756-6.024-5.818-1.492-10.976-6.922-11.193-7.152a.504.504 0 1 1 .736-.69c.051.054 5.179 5.447 10.753 6.878 3.04 1.08 3.384 4.164 3.47 6.972a2.01 2.01 0 0 0 .008.242 21.342 21.342 0 0 1-.25 3.749.503.503 0 0 1-.246.34c-1.995 1.131-9.389 4.824-20.74 4.824zm20.49-5.263z" />
    <path d="M21.305 1.009a12.762 12.762 0 0 1 6.077 1.57c2.301 1.314 5.103 4.136 5.382 10.111l.001.027.003.027c.037.371.862 9.144-3.525 14.083a10.597 10.597 0 0 1-7.994 3.371 10.561 10.561 0 0 1-8-3.37c-4.293-4.834-3.615-13.177-3.524-14.112l.003-.025v-.026c.28-5.984 3.119-8.812 5.453-10.133a12.965 12.965 0 0 1 6.124-1.523M21.315 0a14.01 14.01 0 0 0-6.634 1.656C12.127 3.1 9.02 6.179 8.72 12.618c-.096.98-.807 9.722 3.774 14.88a11.542 11.542 0 0 0 8.738 3.71h.018a11.588 11.588 0 0 0 8.748-3.71c4.591-5.168 3.87-13.902 3.775-14.854-.3-6.433-3.368-9.5-5.889-10.94A13.764 13.764 0 0 0 21.314 0z" />
    <ellipse cx="37.879" cy="45.896" rx="12.121" ry="12.104" className="colored" />
    <path
      d="M44.937 52.297l-3.744-3.739a6.468 6.468 0 1 0-.717.71l3.746 3.742a.505.505 0 0 0 .715-.713zM30.81 44.312a5.485 5.485 0 1 1 5.486 5.478 5.488 5.488 0 0 1-5.486-5.478z"
      fill="#fff"
    />
  </svg>
);
