import React from 'react';
import { App, Modal } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { InsuranceForm } from './InsuranceForm';
import { useForm } from 'antd/es/form/Form';
import { graphql } from '../../../graphql/generated';
import { Insurance } from '../Insurances.tsx';
import { InsuranceInput } from '../../../graphql/generated/graphql.ts';

const CREATE_INSURANCE_MUTATION = graphql(`
  mutation CreateInsurance($input: InsuranceInput!) {
    createInsurance(input: $input) {
      id
    }
  }
`);

export const CreateInsuranceModal: React.FC<{
  open: boolean;
  onClose: () => void;
  allInsurances: Insurance[];
}> = ({ open, onClose, allInsurances }) => {
  const [createInsuranceMutation, { loading }] = useMutation(CREATE_INSURANCE_MUTATION);
  const [form] = useForm<InsuranceInput>();
  const { message } = App.useApp();

  const create = async (values: InsuranceInput) => {
    try {
      await createInsuranceMutation({
        variables: {
          input: values,
        },
      });

      message.success('Krankenkasse wurde erstellt');
      closeAndReset();
    } catch (e) {
      message.error('Krankenkasse konnte nicht erstellt werden: ' + e);
    }
  };

  const closeAndReset = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width={768}
      title="Neue Krankenkasse erstellen"
      okText="Erstellen"
      okButtonProps={{ icon: <PlusCircleOutlined /> }}
      open={open}
      onCancel={closeAndReset}
      onOk={form.submit}
      confirmLoading={loading}
      destroyOnClose
    >
      <InsuranceForm
        codeValidator={code => allInsurances.length === 0 || !allInsurances.some(i => i.code === code)}
        form={form}
        onFinish={create}
      />
    </Modal>
  );
};
