import React from 'react';
import { Button, Col, Form, FormInstance, Input, Row } from 'antd';
import { SpecialRateCreateInput, SpecialRateUpdateInput } from '../../../../graphql/generated/graphql.ts';

export const SpecialRateForm: React.FC<{
  form: FormInstance;
  initialValues?: SpecialRateCreateInput;
  codeValidator: (code: string) => boolean;
  onFinish: (values: SpecialRateCreateInput | SpecialRateUpdateInput) => void;
}> = ({ form, initialValues, codeValidator, onFinish }) => {
  return (
    <Form layout="vertical" form={form} initialValues={initialValues} onFinish={onFinish}>
      <Row gutter={[16, 0]}>
        <Col span={8}>
          <Form.Item
            name="shortName"
            label="Kurzbezeichnung"
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input autoFocus />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Bezeichnung"
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="code"
            label="Code"
            rules={[
              {
                required: true,
                whitespace: true,
              },
              {
                validator: (_, code) =>
                  codeValidator(code) ? Promise.resolve() : Promise.reject('Dieser Code ist bereits vergeben'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      {/* hack for enter button support */}
      <Button htmlType="submit" hidden />
    </Form>
  );
};
