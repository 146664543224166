import React, { useEffect } from 'react';
import { App, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { PeriodicGroupForm } from './PeriodicGroupForm.tsx';
import { useForm } from 'antd/es/form/Form';
import { graphql } from '../../../../graphql/generated';
import { PeriodicGroup } from '../PeriodicGroups.tsx';
import { Period, PeriodicGroupUpdateInput } from '../../../../graphql/generated/graphql.ts';

const UPDATE_PERIODIC_GROUP_MUTATION = graphql(`
  mutation UpdatePeriodicGroup($id: ID!, $input: PeriodicGroupUpdateInput!) {
    updatePeriodicGroup(id: $id, input: $input) {
      id
    }
  }
`);

export const UpdatePeriodicGroupModal: React.FC<{
  group: PeriodicGroup | null;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ group, onClose, onSuccess }) => {
  const [updatePeriodicGroupMutation, { loading }] = useMutation(UPDATE_PERIODIC_GROUP_MUTATION);
  const [form] = useForm<PeriodicGroupUpdateInput>();
  const { message } = App.useApp();

  const update = async (values: PeriodicGroupUpdateInput) => {
    try {
      await updatePeriodicGroupMutation({
        variables: {
          id: group?.id ?? '',
          input: { ...values },
        },
      });

      message.success('Periodische Verrechnungsgruppe wurde aktualisiert');
      onSuccess();
      closeAndReset();
    } catch (e) {
      message.error('Periodische Verrechnungsgruppe konnte nicht aktualisiert werden');
    }
  };

  const closeAndReset = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue({
      name: group?.name ?? '',
      maxCount: group?.maxCount ?? 1,
      period: group?.period ?? Period.CURRENT_REQUEST,
    });
  }, [form, group]);

  return (
    <Modal
      width={768}
      title="Periodische Verrechnungsgruppe bearbeiten"
      okText="Aktualisieren"
      okButtonProps={{ icon: <EditOutlined /> }}
      open={!!group}
      onCancel={closeAndReset}
      onOk={form.submit}
      confirmLoading={loading}
      destroyOnClose
      forceRender
    >
      <PeriodicGroupForm form={form} onFinish={update} />
    </Modal>
  );
};
