import React, { DragEvent, useCallback, useEffect, useState } from 'react';
import { App, Button, Image, Modal, Popconfirm, Space, Upload } from 'antd';
import {
  CloseOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { css } from '@emotion/css';
import { saveUrl } from '../../../../utils/helpers';
import { graphql } from '../../../../graphql/generated';
import { TestTube } from '../TestTubes.tsx';
import { useAuth } from 'react-oidc-context';

const { Dragger } = Upload;

const allowedMimeTypes = ['image/jpeg', 'image/jpg'];

const DELETE_TEST_TUBE_IMAGE_MUTATION = graphql(`
  mutation DeleteTestTubeImage($id: ID!) {
    deleteTestTubeImage(id: $id) {
      id
    }
  }
`);

export const TestTubeImageModal: React.FC<{
  testTube: TestTube | null;
  onClose: () => void;
}> = ({ testTube, onClose }) => {
  const [uploading, setUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const auth = useAuth();
  const { message } = App.useApp();

  const loadImage = useCallback(async () => {
    setImageUrl('');
    if (testTube === null) {
      return;
    }
    const endpoint = window._env_.API_URL + `/rest/test-tube/${testTube.id}/imageUrl`;
    const response = await fetch(endpoint, {
      headers: { authorization: `Bearer ${auth.user?.access_token}` },
    });
    const url = await response.text();
    setImageUrl(url);
  }, [auth, testTube]);

  useEffect(() => {
    loadImage();
  }, [testTube, loadImage, auth]);

  const [deleteTestTubeImageMutation] = useMutation(DELETE_TEST_TUBE_IMAGE_MUTATION);

  const deleteImage = async () => {
    try {
      await deleteTestTubeImageMutation({
        variables: {
          id: testTube?.id ?? '',
        },
      });

      message.success('Bild wurde gelöscht');
      loadImage();
    } catch (e) {
      message.error('Bild konnte nicht gelöscht werden');
    }
  };

  const onDrop = (event: DragEvent) => {
    if (Array.from(event.dataTransfer.files).some(f => !allowedMimeTypes.includes(f.type))) {
      message.error('Nur JPEG-Dateien sind erlaubt');
    }
  };

  const beforeUpload = (file: File) => {
    const isJpeg = allowedMimeTypes.find(mimeType => mimeType === file.type);
    if (!isJpeg) {
      message.error('Nur JPEG-Dateien sind erlaubt');
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error('Datei muss kleiner als 1 MB sein');
    }
    if (isJpeg && isLt1M) {
      upload(file);
    }
    return false;
  };

  const upload = async (file: File) => {
    setUploading(true);
    const endpoint = window._env_.API_URL + `/rest/test-tube/${testTube?.id}/image`;
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        body: formData,
        headers: { authorization: `Bearer ${auth.user?.access_token}` },
      });
      const body = await response.text();
      response.ok ? message.success('Upload erfolgreich') : message.error('Upload fehlgeschlagen: ' + body);
      loadImage();
    } catch (e) {
      message.error('Upload fehlgeschlagen: ' + e);
    } finally {
      setUploading(false);
    }
  };

  const download = () =>
    saveUrl(imageUrl, 'probenbehaelter.jpg', () => message.error('Datei konnte nicht heruntergeladen werden'));

  return (
    <Modal
      width={768}
      title={`Bild für Probenbehälter »${testTube?.name}« bearbeiten`}
      open={!!testTube}
      onCancel={onClose}
      footer={
        <Space direction="horizontal">
          {imageUrl && (
            <>
              <Button icon={<CloudDownloadOutlined />} onClick={download}>
                Download
              </Button>
              <Popconfirm
                title="Bild wirklich löschen?"
                onConfirm={deleteImage}
                okText="Ja"
                okButtonProps={{ danger: true }}
                cancelText="Nein"
              >
                <Button danger icon={<DeleteOutlined />}>
                  Löschen
                </Button>
              </Popconfirm>
            </>
          )}
          <Button type="primary" icon={<CloseOutlined />} onClick={onClose}>
            Schließen
          </Button>
        </Space>
      }
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Dragger showUploadList={false} beforeUpload={beforeUpload} accept=".jpg,.jpeg">
          <div
            onDrop={onDrop}
            style={{
              display: 'flex',
              height: '150px',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <p className="ant-upload-drag-icon">{uploading ? <LoadingOutlined /> : <CloudUploadOutlined />}</p>
            <p className="ant-upload-text">Neues Bild hochladen</p>
            <p className="ant-upload-hint">
              Klicken Sie hier, oder ziehen Sie die gewünschte Datei in diesen Bereich, um den Upload zu starten.
              Erlaubter Dateityp: JPEG oder JPG
            </p>
          </div>
        </Dragger>

        {imageUrl && (
          <Image
            crossOrigin="anonymous" // fixes download: https://stackoverflow.com/questions/12648809/cors-policy-on-cached-image
            className={css`
              width: 300px !important;
            `}
            src={imageUrl}
          />
        )}
      </Space>
    </Modal>
  );
};
