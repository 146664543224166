import React, { useCallback, useEffect, useState } from 'react';
import { App, Button, Col, Menu, Result, Row, Steps, theme } from 'antd';
import { MainContent } from '../../components/MainContent';
import {
  InfoCircleOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  RightOutlined,
  SendOutlined,
  TableOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Navigate, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Parameters } from './selection/Parameters';
import { PatientHeader } from './PatientHeader';
import { SelectedParams } from './selection/SelectedParams';
import { SelectBillingModal } from './selection/SelectBillingModal';
import { ParameterInfoModal } from './ParameterInfoModal';
import { insuranceName } from '../../utils/patient';
import { OpenRequestsBySvnrModal } from './OpenRequestsBySvnrModal';
import { useRequestCopy } from '../../hooks/useRequestCopy';
import { showParamWarning } from '../../components/showParamWarning';
import { css } from '@emotion/css';
import { grey } from '@ant-design/colors';
import { initialParamQueue, SelectedParameter, useAppStore } from '../../hooks/store/useAppStore';
import { Requests } from './selection/Requests';
import { Profiles } from './selection/Profiles';
import { useHotkeys } from 'react-hotkeys-hook';
import { NewProfileModal } from './selection/NewProfileModal';
import { InvalidAdditionalDataDialog } from './selection/InvalidAdditionalDataDialog.tsx';
import { ReorderTabEntry } from './selection/ReorderTabEntry.tsx';
import { Sheets } from './selection/Sheets.tsx';
import { getFragmentData, graphql } from '../../graphql/generated';
import {
  BillingInfo,
  HotKey,
  ParameterSelection_ProfileFragment,
  ParameterSelection_RequestableParameterFragment,
  ParameterToAddInput,
  PatientData,
} from '../../graphql/generated/graphql.ts';
import { LoadingIndicator } from '../../components/LoadingIndicator.tsx';
import { useCurrentContextStore } from '../../hooks/store/useCurrentContextStore.ts';
import { LocalizationsChooserModal, validateLocalizations } from './selection/LocalizationsChooserModal.tsx';
import { useAuth } from 'react-oidc-context';
import { hasFrontDeskRole } from '../../utils/user.ts';

const { useToken } = theme;

type MenuKey = 'profile' | 'sheets' | 'parameter' | 'anforderungen' | 'reorder';

const PARAMETER_SELECTION_QUERY = graphql(`
  query ParameterSelection($patientData: PatientDataInput!, $doctorId: ID!, $requestIdForReorder: ID) {
    requestableParameters(patientData: $patientData, doctorId: $doctorId, requestIdForReorder: $requestIdForReorder) {
      ...ParameterSelection_RequestableParameter
    }

    insurances {
      id
      code
      shortName
    }

    profiles(doctorId: $doctorId) {
      ...ParameterSelection_Profile
    }

    doctorSheets(doctorId: $doctorId, withLabSheets: true) {
      ...ParameterSelection_DoctorSheet
    }

    doctor(id: $doctorId) {
      id
      showPriceSuggestion
      patientBilling
      flipParamNames
      showPrices
      overrideRequestEnabled
    }
  }
`);

const RequestableParameterFragment = graphql(`
  fragment ParameterSelection_RequestableParameter on RequestableParameter {
    id
    mainParameterId
    shortName
    longName
    withoutParameterIds
    withoutParameterNames
    withParameterNames
    billingInfos {
      billingType
      price
      text
      additionalText
      specialRateId
      diagnoseId
    }
    pricePatient
    specimens {
      id
      classification
      name
      storagePeriod
      requiresLocalizations
      localizationInputEnabled
      predefinedLocalizations
      testTubes {
        id
        color
        name
        hasImage
        labelText
        labelText2
        link
        volume
      }
    }
    lab {
      id
      name
      shortName
      diagnoseRequired
    }
    link
    description
    synonyms
    acute
    volume
  }
`);

const ProfileFragment = graphql(`
  fragment ParameterSelection_Profile on Profile {
    id
    name
    hotKey
    parameters {
      id
      parameter {
        id
        shortName
        longName
        acute
        lab {
          id
          shortName
        }
      }
      preferredBillingType
    }
    diagnose
    diagnoses {
      id
      name
    }
  }
`);

const DoctorSheetFragment = graphql(`
  fragment ParameterSelection_DoctorSheet on Sheet {
    id
    doctorId
    labId
    lab {
      id
      name
    }
    data
  }
`);

const OPEN_REQUESTS_BY_SVNR_QUERY = graphql(`
  query OpenRequestsBySvnr($doctorId: ID!, $svnr: String!) {
    openRequests(doctorId: $doctorId, svnr: $svnr) {
      ...OpenRequestsBySvnrModal_Request
    }
  }
`);

const PATIENT_REQUESTS_QUERY = graphql(`
  query QueryParameterSelectionPatientRequests($doctorId: ID!, $svnr: String!) {
    patientRequests(doctorId: $doctorId, svnr: $svnr) {
      id
      reorderable
    }
  }
`);

const ADD_PARAMETERS_MUTATION = graphql(`
  mutation AddParameters($input: AddParametersInput!) {
    addParameters(input: $input) {
      incompatibleParams
      paramsToAdd {
        parameterId
        billingInfo {
          billingType
          price
          text
          additionalText
          specialRateId
          diagnoseId
        }
      }
    }
  }
`);

export const ParameterSelection: React.FC = () => {
  const { token } = useToken();
  const styles = {
    flexContainer: css`
      display: flex;
      flex-direction: column;
      height: 100%;
    `,
    growZero: css`
      flex-grow: 0;
    `,
    growFull: css`
      flex-grow: 1;
      overflow: hidden;
    `,
    leftCol: css`
      height: 100%;
    `,
    rightCol: css`
      height: 100%;
      border-left: 1px solid ${token.colorBorderSecondary};
    `,
  };

  const {
    patientData,
    parameterIdsWithUnknownBilling,
    setParameterIdsWithUnknownBilling,
    requestIdForUpdate,
    setRequestIdForUpdate,
    requestIdForReorder,
    openRequestsModalEnabled,
    setOpenRequestsModalEnabled,
    paramQueue,
    setParamQueue,
    setExecuteRequestCopy,
    setRequestIdForReorder,
    setPatientData,
    additionalDataInvalidFields,
    localizations,
    preferredBillingType,
    diagnose,
    setDiagnose,
    aisImportResult,
    setAisImportResult,
  } = useAppStore();
  const { currentDoctorId, primaryDoctorId } = useCurrentContextStore();

  const navigate = useNavigate();
  const auth = useAuth();
  const [selectedMenuKeys, setSelectedMenuKeys] = useState<MenuKey[]>(['parameter']);
  const [newProfileModalOpen, setNewProfileModalOpen] = useState(false);
  const [localizationsChooserModalOpen, setLocalizationsChooserModalOpen] = useState(false);
  const { notification } = App.useApp();

  const isFrontDesk = hasFrontDeskRole(auth.user);

  const { data, loading, error, refetch } = useQuery(PARAMETER_SELECTION_QUERY, {
    variables: {
      patientData: patientData!,
      doctorId: isFrontDesk ? primaryDoctorId : currentDoctorId,
      requestIdForReorder: requestIdForReorder,
    },
    skip: !patientData,
    fetchPolicy: 'cache-and-network',
  });

  const profiles = getFragmentData(ProfileFragment, data?.profiles);
  const requestableParameters = getFragmentData(RequestableParameterFragment, data?.requestableParameters);
  const doctorSheets = getFragmentData(DoctorSheetFragment, data?.doctorSheets);

  const { data: patientRequests } = useQuery(PATIENT_REQUESTS_QUERY, {
    variables: {
      doctorId: currentDoctorId,
      svnr: patientData?.svnr ?? '',
    },
    skip: !patientData?.svnr,
    fetchPolicy: 'no-cache',
  });

  const reorderPossible =
    !requestIdForReorder &&
    !requestIdForUpdate &&
    (patientRequests?.patientRequests?.some(it => it.reorderable) ?? false);

  const flipParamNames = data?.doctor?.flipParamNames ?? false;

  useRequestCopy('update', false, patientData, copyData => {
    if (copyData.unknownBillingParameters.length) {
      showParamWarning(
        notification,
        'Verrechnungssituation geändert',
        'Für folgende Parameter hat sich die Verrechnungssituation geändert:',
        copyData.unknownBillingParameters.map(it => (flipParamNames ? it.longName : it.shortName))
      );
    }
    if (copyData.unavailableParameters.length) {
      showParamWarning(
        notification,
        'Parameter nicht verfügbar',
        'Folgende Parameter stehen nicht zur Verfügung:',
        copyData.unavailableParameters
      );
    }
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    const hasSheets = (data.doctorSheets?.length ?? 0) > 0;
    const hasProfiles = (data.profiles?.length ?? 0) > 0;
    let menuKey: MenuKey = 'parameter';

    if (hasSheets) {
      menuKey = 'sheets';
    }
    if (hasProfiles) {
      menuKey = 'profile';
    }
    setSelectedMenuKeys([menuKey]);
  }, [data]);

  useHotkeys(
    Object.keys(HotKey).map(it => it.toLowerCase()),
    (_, event) => {
      if (event?.keys?.length && profiles?.length) {
        const profile = profiles.find(it => it.hotKey === event.keys![0].toUpperCase());
        if (profile) {
          addProfile(profile);
        }
      }
    },
    {
      preventDefault: true,
      enableOnFormTags: ['input', 'select', 'textarea'],
      enableOnContentEditable: true,
    }
  );

  const [openRequestModalVisible, setOpenRequestModalVisible] = useState(false);
  const { data: openRequestsBySvnrData } = useQuery(OPEN_REQUESTS_BY_SVNR_QUERY, {
    variables: {
      doctorId: currentDoctorId,
      svnr: patientData?.svnr ?? '',
    },
    skip: !!requestIdForUpdate || !!requestIdForReorder || !patientData?.svnr || isFrontDesk,
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    setOpenRequestModalVisible(
      !!openRequestsBySvnrData?.openRequests?.length && openRequestsModalEnabled && !additionalDataInvalidFields.length
    );
  }, [openRequestsBySvnrData, openRequestsModalEnabled, additionalDataInvalidFields]);

  const [addParameterMutation] = useMutation(ADD_PARAMETERS_MUTATION);

  const [chooseBillingParam, setChooseBillingParam] = useState<ParameterSelection_RequestableParameterFragment | null>(
    null
  );
  const [billingModalCloseable, setBillingModalCloseable] = useState(true);
  const [infoParam, setInfoParam] = useState<ParameterSelection_RequestableParameterFragment | null>(null);
  const [defaultBillingInfo, setDefaultBillingInfo] = useState<BillingInfo | null>(null);

  useEffect(() => {
    // abort when billing chooser is required
    if (chooseBillingParam !== null) {
      return;
    }

    // abort when there are no more parameters to add
    if (!paramQueue.parameters.length) {
      if (paramQueue.ignoredParameters.length) {
        setParamQueue(state => ({ ...state.paramQueue, ignoredParameters: [] }));
      }
      return;
    }

    (async () => {
      const result = await addParameterMutation({
        variables: {
          input: {
            parameterInputs: paramQueue.parameters,
            selectedParameters: paramQueue.selectedParameters.map(sp => ({
              parameterId: sp.id,
              parameterShortName: sp.shortName,
              parameterLongName: sp.longName,
              billingType: sp.billingInfo.billingType,
              specialRateId: sp.billingInfo.specialRateId,
              diagnoseId: sp.billingInfo.diagnoseId,
            })),
            requestIdForReorder: requestIdForReorder,
            doctorId: isFrontDesk ? primaryDoctorId : currentDoctorId,
            patientData: patientData!,
            chooseBilling: paramQueue.chooseBilling,
          },
        },
      });

      if (result.data?.addParameters?.incompatibleParams?.length) {
        showParamWarning(
          notification,
          'Inkompatible Parameter',
          'Folgende Parameter können nicht angefordert werden, da sie mit der aktuellen Auswahl nicht kompatibel sind:',
          result.data.addParameters.incompatibleParams,
          result.data.addParameters.incompatibleParams.join() // computed key to show the notification only once with the same entries
        );
      }

      const paramsToAdd =
        result.data?.addParameters?.paramsToAdd?.filter(
          paramToAdd => !paramQueue.ignoredParameters.some(ignoredParam => ignoredParam.id === paramToAdd.parameterId)
        ) ?? [];

      const firstParamWithoutBilling = paramsToAdd.find(it => !it.billingInfo);
      if (firstParamWithoutBilling) {
        const requestableParam = requestableParameters?.find(p => p.id === firstParamWithoutBilling.parameterId);
        if (requestableParam) {
          setChooseBillingParam(requestableParam);
        }
      }

      setParamQueue(state => {
        const selectedParameterIds = state.paramQueue.selectedParameters.map(it => it.id);

        return {
          ...state.paramQueue,
          parameters: paramsToAdd
            .filter(p => p.billingInfo === null)
            .map(p => ({ parameterId: p.parameterId, preferredBillingType: null, preferredDiagnoseIds: [] })),
          selectedParameters: [
            // prepend new params -> default sorting
            ...paramsToAdd
              .filter(p => p.billingInfo !== null)
              .filter(p => !selectedParameterIds.includes(p.parameterId)) // do not allow duplicates
              .flatMap(p => {
                const param = requestableParameters?.find(rp => rp.id === p.parameterId);
                return param
                  ? [
                      {
                        ...param,
                        billingInfo: p.billingInfo!,
                      },
                    ]
                  : [];
              })
              .sort((a, b) =>
                flipParamNames ? a.longName.localeCompare(b.longName) : a.shortName.localeCompare(b.shortName)
              ),
            ...state.paramQueue.selectedParameters,
          ],
        };
      });
    })();
  }, [
    paramQueue,
    setParamQueue,
    currentDoctorId,
    primaryDoctorId,
    isFrontDesk,
    chooseBillingParam,
    patientData,
    addParameterMutation,
    requestIdForReorder,
    notification,
    requestableParameters,
    flipParamNames,
  ]);

  useEffect(() => {
    // remove already selected params which cannot be requested
    const withoutParamIds = paramQueue.selectedParameters.flatMap(it => it.withoutParameterIds);
    const filteredParams = paramQueue.selectedParameters.filter(sp => !withoutParamIds.includes(sp.id));
    const invalidParams = paramQueue.selectedParameters
      .filter(sp => withoutParamIds.includes(sp.id))
      .map(it => (flipParamNames ? it.longName : it.shortName));

    if (paramQueue.selectedParameters.length !== filteredParams.length) {
      showParamWarning(
        notification,
        'Inkompatible Parameter',
        'Folgende Parameter wurden entfernt, da sie mit den ausgewählten Parametern inkompatibel sind:',
        invalidParams,
        'incompatible-params'
      );
      setParamQueue(state => ({ ...state.paramQueue, selectedParameters: filteredParams }));
    }
  }, [data, paramQueue, setParamQueue, notification, flipParamNames]);

  const onEnqueue = useCallback(
    (params: ParameterToAddInput[], chooseBilling: boolean) => {
      setParamQueue(state => {
        const selectedParamIds = state.paramQueue.selectedParameters.map(it => it.id);
        return {
          ...state.paramQueue,
          parameters: [
            ...state.paramQueue.parameters,
            ...params.filter(it => !selectedParamIds.includes(it.parameterId)),
          ],
          chooseBilling: chooseBilling,
        };
      });
    },
    [setParamQueue]
  );

  const addProfile = useCallback(
    (profile: ParameterSelection_ProfileFragment) => {
      const filteredInputs: ParameterToAddInput[] = [];
      const unavailableParams: string[] = [];
      profile.parameters.forEach(profileParameter => {
        const reqParam = requestableParameters?.find(
          it => it.lab.id === profileParameter.parameter.lab.id && it.shortName === profileParameter.parameter.shortName
        );
        if (reqParam) {
          filteredInputs.push({
            parameterId: reqParam.id,
            preferredBillingType: preferredBillingType ?? profileParameter.preferredBillingType,
            preferredDiagnoseIds: profile.diagnoses.map(it => it.id),
          });
        } else {
          unavailableParams.push(
            flipParamNames ? profileParameter.parameter.longName : profileParameter.parameter.shortName
          );
        }
      });

      if (unavailableParams.length) {
        showParamWarning(
          notification,
          'Parameter nicht verfügbar',
          'Folgende Parameter stehen nicht zur Verfügung:',
          unavailableParams
        );
      }

      if (profile.diagnose && !diagnose.includes(profile.diagnose)) {
        if (!diagnose) {
          setDiagnose(profile.diagnose);
        } else {
          setDiagnose(`${profile.diagnose}\n${diagnose}`);
        }
      }

      onEnqueue(filteredInputs, false);
    },
    [diagnose, flipParamNames, notification, onEnqueue, preferredBillingType, requestableParameters, setDiagnose]
  );

  useEffect(() => {
    if (!data || !aisImportResult || !profiles || !requestableParameters) {
      return;
    }

    if (!aisImportResult.profile && !aisImportResult.paramShortNames.length) {
      return;
    }

    const unavailableParams = aisImportResult.paramShortNames.filter(
      shortName => !requestableParameters.find(it => it.shortName === shortName)
    );
    const availableParams = aisImportResult.paramShortNames.map(shortName =>
      requestableParameters.find(it => it.shortName === shortName)
    );
    const paramsToAdd = availableParams
      .filter(it => !!it)
      .map(reqParam => {
        return {
          parameterId: reqParam!.id,
          preferredBillingType: preferredBillingType,
          preferredDiagnoseIds: [],
        };
      });

    if (paramsToAdd.length) {
      onEnqueue(paramsToAdd, false);
    }

    if (unavailableParams.length) {
      showParamWarning(
        notification,
        'Extern übergebene Parameter nicht verfügbar',
        'Die vom externen System übergebenen Parameter sind nicht verfügbar',
        unavailableParams
      );
    }

    if (aisImportResult.profile) {
      const profile = profiles.find(it => it.name === aisImportResult.profile);
      if (profile) {
        addProfile(profile);
      } else {
        showParamWarning(
          notification,
          'Extern übergebenes Profil nicht verfügbar',
          'Das vom externen System übergebene Profil ist nicht verfügbar',
          [aisImportResult.profile]
        );
      }
    }

    setAisImportResult({
      ...aisImportResult,
      profile: null,
      paramShortNames: [],
    });
  }, [
    data,
    aisImportResult,
    profiles,
    requestableParameters,
    setAisImportResult,
    preferredBillingType,
    onEnqueue,
    addProfile,
    notification,
  ]);

  const onRemove = (paramId: string) => {
    setParamQueue(state => ({
      ...state.paramQueue,
      selectedParameters: state.paramQueue.selectedParameters.filter(p => p.id !== paramId),
    }));
  };

  const onClear = () => {
    setParamQueue(() => initialParamQueue);
  };

  const onEdit = (param: SelectedParameter) => {
    setParamQueue(state => ({
      ...state.paramQueue,
      selectedParameters: state.paramQueue.selectedParameters.filter(p => p.id !== param.id),
    }));
    setDefaultBillingInfo(param.billingInfo);
    setBillingModalCloseable(false);
    const requestableParamId = requestableParameters?.find(p => p.id === param.id)?.id;
    if (requestableParamId) {
      onEnqueue([{ parameterId: requestableParamId, preferredBillingType: null, preferredDiagnoseIds: [] }], true);
    }
  };

  const onEditPatient = () => {
    navigate('/anforderung/patient-erfassung');
  };

  const onNext = () => {
    if (validateLocalizations(paramQueue.selectedParameters, localizations).length > 0) {
      setLocalizationsChooserModalOpen(true);
      return;
    }
    navigate('/anforderung/uebersicht');
  };

  const onInfo = (param: ParameterSelection_RequestableParameterFragment) => {
    setInfoParam(param);
  };

  const onEditRequest = (requestId: string) => {
    setRequestIdForReorder('');
    setExecuteRequestCopy(true);
    setOpenRequestsModalEnabled(false);
    setRequestIdForUpdate(requestId);
  };

  const onOverrideRequest = (requestId: string) => {
    setRequestIdForReorder('');
    setOpenRequestsModalEnabled(false);
    setRequestIdForUpdate(requestId);
  };

  const onReorderRequest = (requestId: string, patientData: PatientData) => {
    setPatientData(patientData);
    setParamQueue(() => initialParamQueue);
    setRequestIdForUpdate('');
    setRequestIdForReorder(requestId);
  };

  useEffect(() => {
    if (data?.requestableParameters && !!parameterIdsWithUnknownBilling.length) {
      onEnqueue(
        parameterIdsWithUnknownBilling.map(it => ({
          parameterId: it,
          preferredBillingType: null,
          preferredDiagnoseIds: [],
        })),
        false
      );
      setParameterIdsWithUnknownBilling([]);
    }
  }, [data, onEnqueue, parameterIdsWithUnknownBilling, setParameterIdsWithUnknownBilling]);

  if (patientData === null) {
    return <Navigate replace to="/" />;
  }

  const insurance = insuranceName(patientData, data?.insurances ?? []);

  return (
    <MainContent size="large" heightType="fullHeight">
      <div className={styles.flexContainer}>
        <Steps
          className={styles.growZero}
          items={[
            {
              status: 'finish',
              title: 'Patient',
              icon: <UserOutlined />,
            },
            {
              status: 'process',
              title: 'Parameterauswahl',
              icon: <TableOutlined />,
            },
            {
              status: 'wait',
              title: requestIdForUpdate
                ? 'Bestehende Anforderung abschließen'
                : requestIdForReorder
                  ? 'Nachforderung abschließen'
                  : 'Neue Anforderung abschließen',
              icon: <SendOutlined />,
            },
          ]}
        />

        {error ? (
          <Result
            status={500}
            title="Leider ist ein Fehler aufgetreten"
            subTitle="Bitte versuchen Sie eine neue Anforderung zu erstellen. Kontaktieren Sie bitte unseren Support, wenn das Problem weiterhin auftritt."
            extra={
              <Button icon={<ReloadOutlined />} onClick={() => navigate('/anforderung')} type="primary">
                Neue Anforderung erstellen
              </Button>
            }
          />
        ) : loading ? (
          <LoadingIndicator height="200px" />
        ) : (
          <Row gutter={[32, 0]} className={styles.growFull}>
            <Col xs={12} sm={12} md={12} lg={16} xl={16} className={styles.leftCol}>
              <div className={styles.flexContainer}>
                <PatientHeader
                  patientData={patientData}
                  insuranceName={insurance}
                  patientBilling={data?.doctor?.patientBilling || false}
                  showDefaultBillingChooser={true}
                  availableBillingTypes={
                    requestableParameters?.flatMap(it => it.billingInfos).map(it => it.billingType) ?? []
                  }
                  onEdit={!requestIdForReorder ? onEditPatient : undefined}
                  menu={
                    <Menu
                      selectedKeys={selectedMenuKeys}
                      onSelect={info => setSelectedMenuKeys(info.selectedKeys as MenuKey[])}
                      mode="horizontal"
                      className={css`
                        background-color: ${token.Layout?.bodyBg};
                      `}
                      items={[
                        {
                          key: 'profile',
                          label: 'Profile',
                        },
                        {
                          key: 'sheets',
                          label: 'Sheets',
                        },
                        {
                          key: 'parameter',
                          label: 'Alle Parameter',
                        },
                        ...(patientData.svnr
                          ? [
                              {
                                key: 'anforderungen',
                                label: 'Zuletzt angefordert',
                              },
                            ]
                          : []),
                        ...(reorderPossible
                          ? [
                              {
                                key: 'reorder',
                                label: <ReorderTabEntry tabKey="reorder" selectedTabKeys={selectedMenuKeys} />,
                              },
                            ]
                          : []),
                      ]}
                    />
                  }
                />
                {data?.requestableParameters?.length ? (
                  <>
                    {selectedMenuKeys[0] === 'profile' && (
                      <Profiles
                        params={requestableParameters ?? []}
                        selectedParams={paramQueue.selectedParameters}
                        onAddProfile={addProfile}
                        flipParamNames={flipParamNames}
                        profiles={profiles ?? []}
                        onRemove={onRemove}
                        onAddParam={param =>
                          onEnqueue(
                            [{ ...param, preferredBillingType: preferredBillingType ?? param.preferredBillingType }],
                            false
                          )
                        }
                        onInfo={onInfo}
                        processing={!!paramQueue.parameters.length}
                      />
                    )}
                    {selectedMenuKeys[0] === 'sheets' && (
                      <Sheets
                        sheets={doctorSheets ?? []}
                        params={requestableParameters ?? []}
                        selectedParams={paramQueue.selectedParameters}
                        reorder={!!requestIdForReorder}
                        flipParamNames={flipParamNames}
                        onAdd={params =>
                          onEnqueue(
                            params.map(it => ({
                              ...it,
                              preferredBillingType: preferredBillingType ?? it.preferredBillingType,
                            })),
                            false
                          )
                        }
                        onRemove={onRemove}
                        onInfo={onInfo}
                      />
                    )}
                    {(selectedMenuKeys[0] === 'anforderungen' || selectedMenuKeys[0] === 'reorder') && (
                      <Requests
                        params={requestableParameters ?? []}
                        onAdd={params =>
                          onEnqueue(
                            params.map(it => ({
                              ...it,
                              preferredBillingType: preferredBillingType ?? it.preferredBillingType,
                            })),
                            false
                          )
                        }
                        onReorder={onReorderRequest}
                        onUpdate={onEditRequest}
                        processing={!!paramQueue.parameters.length}
                        reordersOnly={selectedMenuKeys[0] === 'reorder'}
                        flipParamNames={flipParamNames}
                      />
                    )}
                    {selectedMenuKeys[0] === 'parameter' && (
                      <Parameters
                        flipParamNames={flipParamNames}
                        params={requestableParameters ?? []}
                        selectedParams={paramQueue.selectedParameters}
                        onAdd={(param, chooseBilling) =>
                          onEnqueue(
                            [{ ...param, preferredBillingType: preferredBillingType ?? param.preferredBillingType }],
                            chooseBilling
                          )
                        }
                        onRemove={onRemove}
                        onInfo={onInfo}
                      />
                    )}
                  </>
                ) : requestIdForReorder ? (
                  <Result
                    status="info"
                    icon={<InfoCircleOutlined style={{ color: grey[3] }} />}
                    extra={
                      <Button
                        type="primary"
                        icon={<PlusCircleOutlined />}
                        onClick={() => {
                          setRequestIdForReorder('');
                        }}
                      >
                        Neue Anforderung für Patient starten
                      </Button>
                    }
                    title="Keine Parameter nachforderbar"
                    subTitle="Bitte starten Sie eine neue Anforderung, um für den Patienten Parameter auszuwählen"
                  />
                ) : (
                  <Result
                    status="info"
                    icon={<InfoCircleOutlined style={{ color: grey[3] }} />}
                    extra={
                      <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => navigate('/anforderung')}>
                        Neue Anforderung starten
                      </Button>
                    }
                    title="Keine Parameter anforderbar"
                    subTitle="Bitte starten Sie eine neue Anforderung"
                  />
                )}
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={8} xl={8} className={styles.rightCol}>
              <div className={styles.flexContainer}>
                <SelectedParams
                  showPrices={data?.doctor?.showPrices ?? true}
                  flipParamNames={flipParamNames}
                  requestIdForReorder={requestIdForReorder}
                  selectedParams={paramQueue.selectedParameters}
                  showPriceSuggestion={data?.doctor?.showPriceSuggestion ?? false}
                  onNext={onNext}
                  onClear={onClear}
                  onEdit={onEdit}
                  onRemove={onRemove}
                  onInfo={paramId => {
                    const param = requestableParameters?.find(rp => rp.id === paramId);
                    if (param) {
                      onInfo(param);
                    }
                  }}
                  onNewProfile={() => setNewProfileModalOpen(true)}
                />
              </div>
            </Col>
          </Row>
        )}
      </div>
      <SelectBillingModal
        parameter={chooseBillingParam}
        defaultSelected={defaultBillingInfo}
        forceSelection={!billingModalCloseable}
        skipEnabled={paramQueue.parameters.length > 1}
        onCancel={() => {
          setParamQueue(state => ({ ...state.paramQueue, parameters: [] }));
          setChooseBillingParam(null);
          setDefaultBillingInfo(null);
          setBillingModalCloseable(true);
        }}
        onSkip={() => {
          setParamQueue(state => ({
            ...state.paramQueue,
            ignoredParameters: [...state.paramQueue.ignoredParameters, chooseBillingParam!],
          }));
          setChooseBillingParam(null);
          setDefaultBillingInfo(null);
          setBillingModalCloseable(true);
        }}
        onSelect={(parameter, billingInfo) => {
          const selectedParameter = { ...parameter, billingInfo: billingInfo };
          setParamQueue(state => ({
            ...state.paramQueue,
            selectedParameters: [
              // prepend new params -> default sorting
              selectedParameter,
              ...state.paramQueue.selectedParameters.filter(it => it.id !== selectedParameter.id),
            ],
          }));
          setChooseBillingParam(null);
          setDefaultBillingInfo(null);
          setBillingModalCloseable(true);
        }}
        flipParamNames={flipParamNames}
        showPrices={data?.doctor?.showPrices ?? true}
      />
      <ParameterInfoModal
        parameter={infoParam}
        onClose={() => setInfoParam(null)}
        flipParamNames={flipParamNames}
        showPrices={data?.doctor?.showPrices ?? true}
      />
      <OpenRequestsBySvnrModal
        flipParamNames={flipParamNames}
        open={openRequestModalVisible}
        requestsFragment={openRequestsBySvnrData?.openRequests ?? []}
        overrideRequestEnabled={data?.doctor?.overrideRequestEnabled ?? false}
        onNew={() => {
          setOpenRequestsModalEnabled(false);
          setOpenRequestModalVisible(false);
        }}
        onEdit={onEditRequest}
        overrideRequest={onOverrideRequest}
      />
      <NewProfileModal
        selectedParams={paramQueue.selectedParameters}
        usedHotKeys={profiles?.filter(it => it.hotKey)?.map(it => it.hotKey!) ?? []}
        doctorId={isFrontDesk ? primaryDoctorId : currentDoctorId}
        open={newProfileModalOpen}
        onClose={() => setNewProfileModalOpen(false)}
        onSuccess={() => {
          setNewProfileModalOpen(false);
          refetch();
        }}
      />
      <InvalidAdditionalDataDialog
        invalidFields={additionalDataInvalidFields}
        onCancel={() => navigate('/anforderung')}
      />
      <LocalizationsChooserModal
        selectedParams={paramQueue.selectedParameters}
        requestIdForReorder={requestIdForReorder}
        open={localizationsChooserModalOpen}
        okText="Weiter zur Übersicht"
        okIcon={<RightOutlined />}
        closable={true}
        flipParamNames={flipParamNames}
        onClose={() => setLocalizationsChooserModalOpen(false)}
        onSuccess={() => {
          setLocalizationsChooserModalOpen(false);
          navigate('/anforderung/uebersicht');
        }}
      />
    </MainContent>
  );
};
