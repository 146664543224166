import { App } from './App';
import { datadogRum } from '@datadog/browser-rum';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { oidcConfig } from './oidcConfig.ts';

const env = window._env_;
const runtime = window._runtime_;

if (env.DD_RUM_APPLICATION_ID && env.DD_RUM_CLIENT_TOKEN) {
  datadogRum.init({
    site: 'datadoghq.eu',
    service: 'labrador-ui',
    applicationId: env.DD_RUM_APPLICATION_ID,
    clientToken: env.DD_RUM_CLIENT_TOKEN,
    env: env.ENV_TAG,
    version: runtime.VERSION,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0, // too expensive, disabled for now
    defaultPrivacyLevel: 'mask',
    startSessionReplayRecordingManually: true,
  });

  if (window.nativeApi) {
    window.nativeApi.version().then(it => {
      datadogRum.setGlobalContextProperty('medcom-desktop', {
        version: it,
      });
    });
  }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  //strict mode breaks apollo polling (every component gets rendered twice)
  // see https://github.com/apollographql/apollo-client/issues/6634
  // <StrictMode>
  <AuthProvider {...oidcConfig}>
    <App />
  </AuthProvider>
  // </StrictMode>
);
