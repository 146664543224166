import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppStore } from '../hooks/store/useAppStore';

export const AppStateResetter: React.FC = () => {
  const location = useLocation();
  const reset = useAppStore(state => state.reset);

  useEffect(() => {
    if (
      location.pathname !== '/anforderung/parameterauswahl' &&
      location.pathname !== '/anforderung/patient-erfassung' &&
      location.pathname !== '/anforderung/uebersicht'
    ) {
      reset();
    }
  }, [location, reset]);

  return null;
};
