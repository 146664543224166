import React from 'react';
import { App, Form, Input, Modal, Select } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useForm } from 'antd/es/form/Form';
import { SelectedParameter } from '../../../hooks/store/useAppStore';
import { graphql } from '../../../graphql/generated';
import { HotKey } from '../../../graphql/generated/graphql.ts';

type FormModel = {
  hotKey: HotKey | null | undefined;
  name: string;
};

const CREATE_PROFILE_MUTATION = graphql(`
  mutation CreateProfile($input: ProfileInput!) {
    createProfile(input: $input) {
      id
    }
  }
`);

export const NewProfileModal: React.FC<{
  selectedParams: SelectedParameter[];
  usedHotKeys: HotKey[];
  doctorId: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ selectedParams, usedHotKeys, doctorId, open, onClose, onSuccess }) => {
  const [createProfileMutation, { loading }] = useMutation(CREATE_PROFILE_MUTATION);
  const [form] = useForm<FormModel>();
  const { message } = App.useApp();

  const create = async (model: FormModel) => {
    try {
      await createProfileMutation({
        variables: {
          input: {
            doctorId: doctorId,
            parameters: selectedParams.map(it => ({
              parameterId: it.mainParameterId,
              preferredBillingType: it.billingInfo.billingType,
            })),
            diagnoseIds: [],
            diagnose: '',
            name: model.name,
            hotKey: model.hotKey,
          },
        },
      });

      message.success('Profil wurde angelegt');
      onSuccess();
      closeAndReset();
    } catch (e) {
      message.error('Profil konnte nicht angelegt werden');
    }
  };

  const closeAndReset = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width={500}
      title="Neues Profil von ausgewählten Parametern anlegen"
      okText="Anlegen"
      okButtonProps={{ icon: <PlusCircleOutlined /> }}
      open={open}
      onCancel={closeAndReset}
      onOk={form.submit}
      confirmLoading={loading}
      destroyOnClose
    >
      <Form<FormModel>
        form={form}
        layout="vertical"
        name="create-from-params-form"
        initialValues={{
          name: '',
          hotKey: null,
        }}
        onFinish={values => create(values)}
      >
        <Form.Item name="name" label="Bezeichnung" rules={[{ required: true, whitespace: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="hotKey" label="Tastaturkürzel">
          <Select allowClear>
            {Object.keys(HotKey).map(it => (
              <Select.Option key={it} value={it} disabled={usedHotKeys.includes(it as HotKey)}>
                {it}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
