import { FC } from 'react';
import { Switch } from 'antd';
import { useDebouncedCallback } from 'use-debounce';

export const FaLgFilterSwitch: FC<{
  onChange: (lgOnly: boolean) => void;
}> = ({ onChange }) => {
  // debounce due to performance
  const debounced = useDebouncedCallback(checked => onChange(checked), 200);
  return <Switch onChange={checked => debounced(checked)} checkedChildren="LG" unCheckedChildren="LG" />;
};
