import React, { useState } from 'react';
import { App, Button, Modal, theme, Typography } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  type CatalogFragment,
  DoctorForm,
  type InsuranceFragment,
  type LabFragment,
  type SpecialRateFragment,
  type ThemeFragment,
} from './DoctorForm';
import { useForm } from 'antd/es/form/Form';
import { FragmentType, graphql } from '../../../graphql/generated';
import { AisImportType, BillingPreference, DoctorInput, DoctorSalutation } from '../../../graphql/generated/graphql.ts';

const CREATE_DOCTOR_MUTATION = graphql(`
  mutation CreateDoctor($input: DoctorInput!) {
    createDoctor(input: $input) {
      id
    }
  }
`);

export const CreateDoctorModal: React.FC<{
  open: boolean;
  onClose: () => void;
  labFragments: FragmentType<typeof LabFragment>[];
  catalogFragments: FragmentType<typeof CatalogFragment>[];
  insuranceFragments: FragmentType<typeof InsuranceFragment>[];
  specialRateFragments: FragmentType<typeof SpecialRateFragment>[];
  themeFragments: FragmentType<typeof ThemeFragment>[];
}> = ({ open, onClose, labFragments, catalogFragments, insuranceFragments, specialRateFragments, themeFragments }) => {
  const { token } = theme.useToken();
  const [createDoctorMutation, { loading }] = useMutation(CREATE_DOCTOR_MUTATION);
  const [form] = useForm<DoctorInput>();
  const [hasError, setError] = useState(false);
  const { message } = App.useApp();

  const create = async (values: DoctorInput) => {
    try {
      await createDoctorMutation({
        variables: {
          input: values,
        },
      });

      message.success('Zuweiser wurde erstellt');
      closeAndReset();
    } catch (e) {
      message.error('Zuweiser konnte nicht erstellt werden: ' + e);
    }
  };

  const closeAndReset = () => {
    form.resetFields();
    setError(false);
    onClose();
  };

  return (
    <Modal
      width={1024}
      title="Neuen Zuweiser erstellen"
      open={open}
      destroyOnClose
      onCancel={closeAndReset}
      footer={[
        <Typography.Text key="validationInfo" type="danger" style={{ marginRight: token.paddingSM }}>
          {hasError && 'Manche Felder sind nicht ordnungsgemäß ausgefüllt'}
        </Typography.Text>,
        <Button key="cancel" onClick={closeAndReset}>
          Abbrechen
        </Button>,
        <Button key="submit" icon={<PlusCircleOutlined />} type="primary" loading={loading} onClick={form.submit}>
          Erstellen
        </Button>,
      ]}
    >
      <DoctorForm
        form={form}
        initialValues={{
          salutation: DoctorSalutation.HERR,
          name: '',
          specialist: '',
          hvNumber: '',
          meAddress: '',
          firstName: '',
          lastName: '',
          preTitle: '',
          postTitle: '',
          street: '',
          zip: '',
          city: '',
          email: '',
          phone: '',
          assignedLabs: [],
          comment: '',
          aisImportType: AisImportType.GENERIC_ANSI,
          aisImportDoctorId: '',
          billingPreference: BillingPreference.ASK,
          preferSpecialRate: false,
          autoDiagnose: false,
          autoSpecialRate: false,
          catalogIds: [],
          insuranceIds: [],
          specialRateIds: [],
          doctorBilling: false,
          patientBilling: false,
          lgDoctorBilling: false,
          lgInsuranceIds: [],
          hasAis: true,
          printTransferDocument: false,
          showPrices: true,
          showPriceSuggestion: false,
          flipParamNames: false,
          addEmptyLabels: 0,
          aisExportTypes: [],
          themeId: null,
          csvImportEnabled: false,
          overrideRequestEnabled: false,
          disabled: false,
        }}
        onFinish={create}
        onFieldsChange={(_, allFields) => setError(allFields.some(it => it.errors?.length))}
        labFragments={labFragments}
        catalogFragments={catalogFragments}
        insuranceFragments={insuranceFragments}
        specialRateFragments={specialRateFragments}
        themeFragments={themeFragments}
      />
    </Modal>
  );
};
