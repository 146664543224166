import React, { useState } from 'react';
import { SheetDataWithId } from './types';
import { Button, Col, Popconfirm, Row, theme } from 'antd';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/css';
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  HolderOutlined,
  PlusOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { SortableGroups } from './SortableGroups.tsx';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import { FragmentType } from '../../graphql/generated';
import type { SheetEditorParameterFragment } from './SheetEditor.tsx';

export const SortableSheetEntry: React.FC<{
  sheet: SheetDataWithId;
  parameters: FragmentType<typeof SheetEditorParameterFragment>[];
  onRemove: (sheetId: string) => void;
  onEdit: (sheetId: string) => void;
  onGroupAdd: (sheetId: string) => void;
  onGroupEdit: (sheetId: string, groupId: string) => void;
  onGroupDragEnd: (sheetId: string, event: DragEndEvent) => void;
  onGroupRemove: (sheetId: string, groupId: string) => void;
  onGroupCopy: (sheetId: string, groupId: string) => void;
  onParamAdd: (sheetId: string, groupId: string) => void;
  onParamDragEnd: (sheetId: string, groupId: string, event: DragEndEvent) => void;
  onParamRemove: (sheetId: string, groupId: string, paramId: string) => void;
  showLab: boolean;
  flipParamNames: boolean;
}> = ({
  sheet,
  parameters,
  onRemove,
  onEdit,
  onGroupAdd,
  onGroupEdit,
  onGroupDragEnd,
  onGroupRemove,
  onGroupCopy,
  onParamAdd,
  onParamDragEnd,
  onParamRemove,
  showLab,
  flipParamNames,
}) => {
  const { token } = theme.useToken();
  const [open, setOpen] = useState(false);
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id: sheet.id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={css`
        touch-action: none;
        padding: ${token.paddingSM}px;
        border: 1px solid ${token.colorBorder};
        border-radius: ${token.borderRadius}px;
        margin-bottom: ${token.marginSM}px;
        background-color: ${isDragging ? '#f6f6f6' : 'white'};
      `}
    >
      <Row
        wrap={false}
        className={css`
          .delete,
          .add,
          .edit {
            opacity: 0;
          }

          :hover {
            .delete,
            .add,
            .edit {
              opacity: 1;
            }
          }
        `}
      >
        <Col
          onClick={() => setOpen(open => !open)}
          flex="auto"
          className={css`
            display: flex;
            align-items: center;
            cursor: pointer;
          `}
        >
          {open ? <DownOutlined /> : <RightOutlined />}
          <h3
            className={css`
              margin: 0 0 0 ${token.paddingXS}px;
            `}
          >
            {sheet.name}
          </h3>
        </Col>
        <Col>
          <Button
            icon={<PlusOutlined />}
            type="text"
            className="add"
            onClick={() => {
              setOpen(true);
              onGroupAdd(sheet.id);
            }}
          />
          <Button icon={<EditOutlined />} type="text" className="edit" onClick={() => onEdit(sheet.id)} />
          <Popconfirm
            title="Sheet entfernen?"
            onConfirm={() => onRemove(sheet.id)}
            okText="Ja, entfernen"
            okButtonProps={{ danger: true }}
            cancelText="Nein"
            placement="left"
          >
            <Button icon={<DeleteOutlined />} danger type="text" className="delete" />
          </Popconfirm>
          <Button
            icon={<HolderOutlined />}
            className={css`
              cursor: move;
            `}
            type="text"
            {...listeners}
            {...attributes}
          />
        </Col>
      </Row>
      {open && (
        <SortableGroups
          sheet={sheet}
          parameters={parameters}
          onGroupDragEnd={onGroupDragEnd}
          onGroupEdit={onGroupEdit}
          onGroupRemove={onGroupRemove}
          onGroupCopy={onGroupCopy}
          onParamAdd={onParamAdd}
          onParamDragEnd={onParamDragEnd}
          onParamRemove={onParamRemove}
          showLab={showLab}
          flipParamNames={flipParamNames}
        />
      )}
    </div>
  );
};
