import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { message } from 'antd';
import { setContext } from '@apollo/client/link/context';
import { loadUser } from '../oidcConfig.ts';

export const apolloFactory = () => {
  const baseUri = window._env_.API_URL;
  const graphqlUri = baseUri + '/graphql';
  let networkErrorTimeoutId = 0;

  const httpLink = createHttpLink({ uri: graphqlUri });

  const authLink = setContext(async (_, { headers }) => {
    try {
      const user = loadUser();
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${user?.access_token}`,
        },
      };
    } catch (e) {
      console.error('Update token error', e);
    }
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        console.error('GraphQL error', error);
      });
    }

    if (networkError) {
      window.clearTimeout(networkErrorTimeoutId);
      networkErrorTimeoutId = window.setTimeout(() => {
        console.error('Network error', networkError);
        message.error(`Ein Verbindungsproblem ist aufgetreten: ${networkError.message}`);
      }, 300);
    }
  });

  return {
    apolloClient: new ApolloClient({
      link: ApolloLink.from([errorLink, authLink, httpLink]),
      cache: new InMemoryCache({
        typePolicies: {
          RequestableParameter: {
            fields: {
              billingInfos: { merge: false },
            },
          },
          Doctor: {
            fields: {
              assignedLabs: { merge: false },
            },
          },
          Request: {
            fields: {
              patientData: { merge: true },
            },
          },
          RequestCopy: {
            fields: {
              patientData: { merge: true },
            },
          },
          PatientData: {
            fields: {
              insuredPerson: { merge: true },
            },
          },
        },
      }),
    }),
  };
};
