import React, { ReactNode, useEffect } from 'react';
import deDE from 'antd/locale/de_DE';
import { ConfigProvider } from 'antd';
import { defaultTheme, useThemeStore } from './hooks/store/useThemeStore.ts';
import { useDoctorTheme } from './hooks/useDoctorTheme.ts';
import { useCurrentContextStore } from './hooks/store/useCurrentContextStore.ts';

export const DynamicConfigProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { currentDoctorId } = useCurrentContextStore();
  const { theme, setTheme, previewMode } = useThemeStore();
  const doctorTheme = useDoctorTheme(currentDoctorId);

  useEffect(() => {
    if (!previewMode && theme.id !== doctorTheme?.id) {
      setTheme(doctorTheme ?? defaultTheme, false);
    }
  }, [doctorTheme, theme, setTheme, previewMode]);

  return (
    <ConfigProvider
      locale={deDE}
      theme={{
        cssVar: true,
        token: {
          colorLink: '#4A88CB',
          colorPrimary: theme.colorPrimary,
          fontFamily: 'Inter',
          controlItemBgActive: theme.controlItemBgActive,
          controlItemBgActiveHover: theme.controlItemBgActiveHover,
        },
        components: {
          Layout: {
            headerBg: theme.colorBgHeader,
            bodyBg: '#fafafa',
            triggerBg: theme.colorBgTrigger,
          },
          Menu: {
            itemBg: '#F8F7F4',
            subMenuItemBg: '#F8F7F4',
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
