import React from 'react';
import { App, Modal } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { DiagnoseForm } from './DiagnoseForm';
import { useForm } from 'antd/es/form/Form';
import { graphql } from '../../../../graphql/generated';
import { Diagnose } from '../Diagnoses.tsx';
import { DiagnoseCreateInput } from '../../../../graphql/generated/graphql.ts';

const CREATE_DIAGNOSE_MUTATION = graphql(`
  mutation CreateDiagnose($input: DiagnoseCreateInput!) {
    createDiagnose(input: $input) {
      id
    }
  }
`);

export const CreateDiagnoseModal: React.FC<{
  labId: string;
  diagnoses: Diagnose[];
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ labId, diagnoses, open, onClose, onSuccess }) => {
  const [createDiagnoseMutation, { loading }] = useMutation(CREATE_DIAGNOSE_MUTATION);
  const [form] = useForm<DiagnoseCreateInput>();
  const { message } = App.useApp();

  const create = async (values: DiagnoseCreateInput) => {
    try {
      await createDiagnoseMutation({
        variables: {
          input: {
            ...values,
            labId: labId,
          },
        },
      });

      message.success('Diagnose wurde erstellt');
      onSuccess();
      closeAndReset();
    } catch (e) {
      message.error('Diagnose konnte nicht erstellt werden');
    }
  };

  const closeAndReset = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width={768}
      title="Neue Diagnose erstellen"
      okText="Erstellen"
      okButtonProps={{ icon: <PlusCircleOutlined /> }}
      open={open}
      onCancel={closeAndReset}
      onOk={form.submit}
      confirmLoading={loading}
      destroyOnClose
    >
      <DiagnoseForm
        form={form}
        initialValues={{
          labId: labId,
          name: '',
          text: '',
        }}
        onFinish={values => create(values as DiagnoseCreateInput)}
        nameValidator={name => diagnoses.length === 0 || !diagnoses.some(it => it.name === name)}
      />
    </Modal>
  );
};
