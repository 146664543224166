import { css } from '@emotion/css';

export const tableActionCell = css`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

export const tableHoverPointer = css`
  &:hover {
    cursor: pointer;
  }
`;

export const tableLinkHoverRow = (primaryColor: string) => css`
  &:hover {
    cursor: pointer;
    color: ${primaryColor};
  }
`;
