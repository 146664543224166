import React, { useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  Alert,
  App,
  Button,
  Flex,
  Input,
  Popconfirm,
  Table,
  TablePaginationConfig,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import {
  CheckOutlined,
  InfoCircleOutlined,
  LinkOutlined,
  ReloadOutlined,
  SearchOutlined,
  WarningOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import { MainContent } from '../../components/MainContent';
import { useMutation, useQuery } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import { DoctorSelect } from '../../components/DoctorSelect';
import { Markable } from '../../components/Markable';
import { PageHeader } from '@ant-design/pro-components';
import { tableActionCell } from '../../styles/globalCss';
import { UpdateUserAssignmentModal } from './users/UpdateUserAssignmentModal';
import { graphql } from '../../graphql/generated';
import { DoctorsForAssignmentQuery, UsersQuery } from '../../graphql/generated/graphql.ts';
import { TableList } from '../../components/TableList.tsx';
import { gold } from '@ant-design/colors';
import { css } from '@emotion/css';

const DOCTORS_QUERY = graphql(`
  query DoctorsForAssignment {
    doctors {
      id
      name
      meAddress
      firstName
      lastName
      postTitle
      preTitle
      salutation
      disabled
    }
  }
`);

const USERS_QUERY = graphql(`
  query Users($page: Int!, $pageSize: Int!, $doctorId: String, $search: String) {
    users(page: $page, pageSize: $pageSize, doctorId: $doctorId, search: $search) {
      total
      limit
      start
      length
      items {
        id
        username
        email
        firstName
        lastName
        disabled
        userAssignment {
          id
          primaryDoctor {
            id
            name
            salutation
            preTitle
            firstName
            lastName
            postTitle
            disabled
          }
          additionalDoctors {
            id
            name
            salutation
            preTitle
            firstName
            lastName
            postTitle
            disabled
          }
        }
      }
    }
  }
`);

const DELETE_USER_ASSIGNMENT_MUTATION = graphql(`
  mutation DeleteUserAssignment($id: ID!) {
    deleteUserAssignment(id: $id)
  }
`);

export type User = NonNullable<UsersQuery['users']>['items'][number];
export type Doctor = NonNullable<DoctorsForAssignmentQuery['doctors']>[number];

export const Users: React.FC = () => {
  const [editUser, setEditUser] = useState<User | null>(null);
  const [selectedDoctorId, setSelectedDoctorId] = useState<string | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch] = useDebounce(search, 500);
  const [pageable, setPageable] = useState<{ page: number; pageSize: number }>({ page: 1, pageSize: 10 });
  const { message } = App.useApp();

  const {
    data: usersData,
    loading: usersLoading,
    refetch: usersRefetch,
  } = useQuery(USERS_QUERY, {
    variables: {
      doctorId: selectedDoctorId,
      page: pageable.page,
      pageSize: pageable.pageSize,
      search: debouncedSearch,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: doctorsData, loading: doctorsLoading } = useQuery(DOCTORS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const [deleteUserAssignmentMutation] = useMutation(DELETE_USER_ASSIGNMENT_MUTATION);

  const deleteUserAssignment = async (id: string) => {
    try {
      await deleteUserAssignmentMutation({ variables: { id } });
      message.success('Beziehung wurde aufgehoben');
      usersRefetch();
    } catch (e) {
      message.error('Beziehung konnte nicht aufgehoben werden: ' + e);
    }
  };

  const columns: ColumnsType<User> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: value => (
        <Typography.Text copyable code>
          {value}
        </Typography.Text>
      ),
      width: 100,
      ellipsis: true,
    },
    {
      title: 'Aktiv',
      dataIndex: 'disabled',
      key: 'disabled',
      width: 70,
      sorter: (a, b) => Number(b.disabled) - Number(a.disabled),
      render: value => (!value ? <CheckOutlined /> : ''),
    },
    {
      title: 'Benutzername',
      dataIndex: 'username',
      key: 'username',
      ellipsis: true,
      width: 100,
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Vorname',
      dataIndex: 'firstName',
      key: 'firstName',
      ellipsis: true,
      width: 100,
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Nachname',
      dataIndex: 'lastName',
      key: 'lastName',
      ellipsis: true,
      width: 100,
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'E-Mail',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      width: 100,
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Hauptzuweiser',
      dataIndex: ['userAssignment', 'primaryDoctor'],
      key: 'primaryDoctor',
      width: 120,
      render: value =>
        value ? (
          <Tag color={value.disabled ? 'warning' : undefined} icon={value.disabled ? <WarningOutlined /> : undefined}>
            {value.disabled ? `Deakt. - ${value.name}` : value.name}
          </Tag>
        ) : (
          ''
        ),
      ellipsis: true,
    },
    {
      title: 'Weitere Zuweiser',
      dataIndex: ['userAssignment', 'additionalDoctors'],
      key: 'additionalDoctors',
      width: 140,
      render: (_, record) => (
        <TableList
          entries={
            record.userAssignment?.additionalDoctors.map(d =>
              d.disabled ? (
                <Flex key={d.id} align="center" gap={4}>
                  <WarningTwoTone twoToneColor={gold[5]} />{' '}
                  <span
                    className={css`
                      color: ${gold[5]};
                      margin: 0;
                    `}
                  >
                    Deakt.
                  </span>
                  {d.name}
                </Flex>
              ) : (
                d.name
              )
            ) ?? []
          }
          maxEntries={3}
        />
      ),
      ellipsis: true,
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '50px',
      className: tableActionCell,
      render: (_, record) => (
        <>
          <Button icon={<LinkOutlined />} type="text" disabled={doctorsLoading} onClick={() => setEditUser(record)} />
          <Popconfirm
            title="Beziehung aufheben?"
            onConfirm={() => deleteUserAssignment(record.userAssignment!.id)}
            okText="Ja"
            okButtonProps={{ danger: true }}
            cancelText="Nein"
            placement="top"
            disabled={doctorsLoading || !record.userAssignment}
          >
            <Button icon={<LinkOutlined />} type="text" danger disabled={doctorsLoading || !record.userAssignment} />
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const page = pagination.current || 1;
    const pageSize = pagination.pageSize || 10;

    setPageable({
      page: page,
      pageSize: pageSize,
    });
  };

  return (
    <MainContent>
      <PageHeader
        title="Benutzer"
        extra={[
          <Input
            key="search"
            allowClear
            autoFocus
            placeholder="Suche"
            value={search ?? ''}
            onChange={e => {
              setSelectedDoctorId(null);
              setPageable(current => ({ ...current, page: 1 })); // reset page
              setSearch(e.target.value);
            }}
            prefix={<SearchOutlined />}
            suffix={
              <Tooltip title="Suche nach Benutzername, Vorname, Nachname oder E-Mail. Mind 3 Zeichen eingeben!">
                <InfoCircleOutlined />
              </Tooltip>
            }
            style={{ width: '250px' }}
          />,
          <DoctorSelect
            key="doctorFilter"
            style={{ width: '400px' }}
            onChange={value => {
              setSelectedDoctorId(value as string);
              setPageable(current => ({ ...current, page: 1 })); // reset page
              setSearch(null);
            }}
            doctors={doctorsData?.doctors ?? []}
            selected={selectedDoctorId || undefined}
          />,
          <Button key="refresh" icon={<ReloadOutlined />} onClick={() => usersRefetch()} />,
        ]}
        style={{ padding: 0, paddingBottom: 'inherit' }}
      />
      <Alert
        message="Hier können die Beziehungen von Benutzern zu deren Zuweisern festgelegt werden. Die Benutzerverwaltung ist in Keycloak durchzuführen."
        type="info"
        showIcon
      />
      <p />
      <Table<User>
        scroll={{ x: 'max-content' }}
        rowKey={record => record.id}
        sticky={true}
        size="middle"
        showSorterTooltip={false}
        dataSource={usersData?.users?.items ?? []}
        pagination={{
          current: usersData?.users ? Math.floor(usersData.users.start / usersData.users.limit + 1) : 1,
          total: usersData?.users?.total ?? 0,
          showTotal: total =>
            `${(usersData?.users?.start ?? 0) + 1} bis ${
              (usersData?.users?.start ?? 0) + (usersData?.users?.length ?? 0)
            } von ${total} Benutzern`,
          showQuickJumper: true,
          showSizeChanger: true,
        }}
        loading={usersLoading}
        columns={columns}
        onChange={pagination => handleTableChange(pagination)}
      />
      <UpdateUserAssignmentModal
        user={editUser}
        onClose={() => {
          setEditUser(null);
          usersRefetch();
        }}
        doctors={doctorsData?.doctors ?? []}
      />
    </MainContent>
  );
};
