import { Tag, theme } from 'antd';
import React from 'react';
import { findRequestStatusMetadata } from '../utils/enumHelpers';
import { RequestStatus } from '../graphql/generated/graphql.ts';

export const RequestStatusBadge: React.FC<{
  status: RequestStatus;
  onClose?: () => void;
  className?: string;
  closable?: boolean;
  prefixText?: string;
}> = ({ status, onClose, className, closable, prefixText }) => {
  const { token } = theme.useToken();
  const { color, translation, iconType: IconComponent } = findRequestStatusMetadata(status, token);

  return (
    <Tag color={color} icon={<IconComponent />} onClose={onClose} className={className} closable={closable}>
      {prefixText ? prefixText + ' ' + translation : translation}
    </Tag>
  );
};
