import React from 'react';
import { App, Modal } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { ThemeForm } from './ThemeForm.tsx';
import { useForm } from 'antd/es/form/Form';
import { defaultTheme } from '../../../hooks/store/useThemeStore.ts';
import { graphql } from '../../../graphql/generated';
import { ThemeInput } from '../../../graphql/generated/graphql.ts';

const CREATE_THEME_MUTATION = graphql(`
  mutation CreateTheme($input: ThemeInput!) {
    createTheme(input: $input) {
      id
    }
  }
`);

export const CreateThemeModal: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const [createThemeMutation, { loading }] = useMutation(CREATE_THEME_MUTATION);
  const [form] = useForm<ThemeInput>();
  const { message } = App.useApp();

  const create = async (values: ThemeInput) => {
    try {
      await createThemeMutation({
        variables: {
          input: values,
        },
      });

      message.success('Theme wurde erstellt');
      closeAndReset();
    } catch (e) {
      message.error('Theme konnte nicht erstellt werden: ' + e);
    }
  };

  const closeAndReset = () => {
    form.resetFields();
    onClose();
  };

  const { id, ...cleanDefaultTheme } = defaultTheme;

  return (
    <Modal
      width={768}
      title="Neues Theme erstellen"
      okText="Erstellen"
      okButtonProps={{ icon: <PlusCircleOutlined /> }}
      open={open}
      onCancel={closeAndReset}
      onOk={form.submit}
      confirmLoading={loading}
      destroyOnClose
    >
      <ThemeForm
        form={form}
        onFinish={create}
        initialValues={{
          ...cleanDefaultTheme,
          name: '',
        }}
      />
    </Modal>
  );
};
