import React, { useEffect, useState } from 'react';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { App, Button, Col, DatePicker, Row, Select, Space } from 'antd';
import { API_DATE_FORMAT, SHORT_DATE_FORMAT } from '../../utils/dateFormatUtils';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import { CloseOutlined, CloudDownloadOutlined, FilePdfOutlined, PrinterOutlined } from '@ant-design/icons';
import { useObjectUrlResource } from '../../hooks/useObjectUrlResource';
import { PdfPreviewModal } from '../../components/PdfPreviewModal';
import { saveAs } from 'file-saver';
import { graphql } from '../../graphql/generated';

const DAILY_REPORT_QUERY = graphql(`
  query DailyReport($doctorId: ID!) {
    doctor(id: $doctorId) {
      id
      assignedLabs {
        lab {
          id
          name
          identifier
        }
      }
    }
  }
`);

export const DailyReport: React.FC<{ doctorId: string }> = ({ doctorId }) => {
  const [reportDate, setReportDate] = useState<dayjs.Dayjs>(dayjs());
  const [labId, setLabId] = useState('');
  const { message } = App.useApp();

  const { data, loading } = useQuery(DAILY_REPORT_QUERY, {
    variables: {
      doctorId: doctorId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const {
    objectUrl: pdfUrl,
    blob: pdfBlob,
    fetchAndSet: fetchPdf,
    cleanup: cleanupPdf,
    loading: pdfLoading,
  } = useObjectUrlResource(
    () => message.error('Es ist ein Fehler beim Laden des Dokuments aufgetreten'),
    'application/pdf'
  );

  useEffect(() => {
    if (data?.doctor?.assignedLabs.length) {
      setLabId(data.doctor.assignedLabs[0].lab.id);
    }
  }, [data]);

  const generate = () => {
    const url =
      window._env_.API_URL +
      '/rest/daily-report?' +
      new URLSearchParams({
        doctorId: doctorId,
        labId: data?.doctor?.assignedLabs.find(it => it.lab.id === labId)?.lab.identifier ?? '',
        reportDate: reportDate.format(API_DATE_FORMAT),
      }).toString();

    fetchPdf(url);
  };

  const print = async () => {
    if (!pdfBlob || !window.nativeApi) {
      return;
    }

    try {
      const buffer = await pdfBlob.arrayBuffer();
      await window.nativeApi.print(buffer, 'a4');
      message.success('Dokument wurde an den Drucker gesendet');
    } catch (e) {
      message.error('Beim Drucken ist ein Fehler aufgetreten');
    }
  };

  const download = () => {
    if (!pdfBlob) {
      return;
    }
    saveAs(
      pdfBlob,
      `tageszusammenfassung_${data?.doctor?.assignedLabs.find(it => it.lab.id === labId)?.lab.name}_${reportDate.format(
        API_DATE_FORMAT
      )}.pdf`
    );
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Row gutter={[12, 12]}>
        <Col sm={24} md={12}>
          <DatePicker
            value={reportDate}
            onChange={d => (d ? setReportDate(d as dayjs.Dayjs) : null)}
            style={{ width: '100%' }}
            format={SHORT_DATE_FORMAT}
            allowClear={false}
            disabledDate={current => current && current.isAfter(dayjs())}
          />
        </Col>
        <Col sm={24} md={12} hidden={(data?.doctor?.assignedLabs.length ?? 0) <= 1}>
          <Select
            value={labId}
            options={data?.doctor?.assignedLabs.map(it => ({
              label: it.lab.name,
              value: it.lab.id,
            }))}
            onChange={setLabId}
            style={{ width: '100%' }}
            allowClear={false}
          />
        </Col>
        <Col sm={24} md={12}>
          <Button
            icon={<FilePdfOutlined />}
            style={{ width: '100%' }}
            onClick={generate}
            disabled={!labId || !reportDate}
          >
            Erstellen
          </Button>
        </Col>
      </Row>
      <PdfPreviewModal
        title={`Tageszusammenfassung ${
          data?.doctor?.assignedLabs.find(it => it.lab.id === labId)?.lab.name
        } ${reportDate.format(SHORT_DATE_FORMAT)}`}
        url={pdfUrl}
        loading={pdfLoading}
        footer={
          <Space direction="horizontal">
            <Button hidden={!window.nativeApi} type="primary" icon={<PrinterOutlined />} onClick={print}>
              Drucken
            </Button>
            <Button
              type={!window.nativeApi ? 'primary' : undefined}
              icon={<CloudDownloadOutlined />}
              onClick={download}
            >
              Download
            </Button>
            <Button icon={<CloseOutlined />} onClick={cleanupPdf}>
              Schließen
            </Button>
          </Space>
        }
        onClose={cleanupPdf}
      />
    </>
  );
};
