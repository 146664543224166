import { FC, useState } from 'react';
import { Button, Card, Col, Row, Tag, theme, Typography } from 'antd';
import { Localization, SelectedParameter } from '../../../hooks/store/useAppStore.ts';
import { css } from '@emotion/css';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import { LocalizationsChooserModal } from '../selection/LocalizationsChooserModal.tsx';

export const Localizations: FC<{
  selectedParams: SelectedParameter[];
  requestIdForReorder: string;
  localizations: Localization[];
  flipParamNames: boolean;
}> = ({ selectedParams, requestIdForReorder, localizations, flipParamNames }) => {
  const { token } = theme.useToken();
  const [localizationsChooserModalOpen, setLocalizationsChooserModalOpen] = useState(false);
  const paramsWithLocalizations = selectedParams.filter(it => it.specimens.some(s => s.requiresLocalizations));

  if (!paramsWithLocalizations.length) {
    return null;
  }

  return (
    <div>
      {!!paramsWithLocalizations.length && (
        <h3>
          Lokalisationen{' '}
          <Button icon={<EditOutlined />} type="text" onClick={() => setLocalizationsChooserModalOpen(true)} />
        </h3>
      )}
      <Row gutter={[8, 8]}>
        {paramsWithLocalizations.map(param => (
          <Col span={paramsWithLocalizations.length > 1 ? 12 : 24} key={param.id}>
            <Card
              size="small"
              type="inner"
              title={
                flipParamNames ? (
                  <>
                    {param.longName}{' '}
                    <Typography.Text
                      className={css`
                        font-weight: normal;
                      `}
                      type="secondary"
                    >
                      {param.shortName}
                    </Typography.Text>
                  </>
                ) : (
                  <>
                    {param.shortName}{' '}
                    <Typography.Text
                      className={css`
                        font-weight: normal;
                      `}
                      type="secondary"
                    >
                      {param.longName}
                    </Typography.Text>
                  </>
                )
              }
            >
              {param.specimens
                .filter(it => it.requiresLocalizations)
                .map(spec => (
                  <Row
                    key={param.id + '_' + spec.id}
                    gutter={[16, 0]}
                    wrap={false}
                    className={css`
                      margin-bottom: ${token.marginSM}px;

                      :last-child {
                        margin-bottom: 0;
                      }
                    `}
                  >
                    <Col flex="150px">
                      <Typography.Text strong>{spec.name}</Typography.Text>
                    </Col>
                    <Col flex="auto">
                      {localizations
                        .filter(it => it.paramId === param.id && it.specimenId === spec.id)
                        .map(it => (
                          <Tag
                            key={it.location}
                            className={css`
                              margin-bottom: 5px;
                            `}
                          >
                            {it.location}
                          </Tag>
                        ))}
                    </Col>
                  </Row>
                ))}
            </Card>
          </Col>
        ))}
      </Row>
      <LocalizationsChooserModal
        selectedParams={selectedParams}
        open={localizationsChooserModalOpen}
        requestIdForReorder={requestIdForReorder}
        okText="OK"
        okIcon={<CheckOutlined />}
        closable={false}
        flipParamNames={flipParamNames}
        onClose={() => setLocalizationsChooserModalOpen(false)}
        onSuccess={() => setLocalizationsChooserModalOpen(false)}
      />
    </div>
  );
};
