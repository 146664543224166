import { FC } from 'react';
import { Switch } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';

export const AcuteFilterSwitch: FC<{
  onChange: (acute: boolean) => void;
}> = ({ onChange }) => {
  // debounce due to performance
  const debounced = useDebouncedCallback(checked => onChange(checked), 200);
  return (
    <Switch
      onChange={checked => debounced(checked)}
      checkedChildren={
        <>
          <ClockCircleOutlined /> Akut
        </>
      }
      unCheckedChildren={
        <>
          <ClockCircleOutlined /> Akut
        </>
      }
    />
  );
};
