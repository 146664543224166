import { useMutation, useQuery } from '@apollo/client';
import { getFragmentData, graphql } from '../graphql/generated';
import { SheetDataWithId } from '../components/sheet-editor/types.ts';
import { App } from 'antd';
import { useEffect, useState } from 'react';
import { SheetData } from '../graphql/sheet.ts';
import { v4 as uuidv4 } from 'uuid';
import { SheetEditorParameterFragment } from '../components/sheet-editor/SheetEditor.tsx';

const LAB_SHEET_QUERY = graphql(`
  query LabSheet($labId: ID!) {
    labSheet(labId: $labId) {
      id
      data
    }

    labParameters(labId: $labId) {
      ...SheetEditor_Parameter
    }
  }
`);

const SAVE_LAB_SHEET_MUTATION = graphql(`
  mutation SaveLabSheet($labId: ID!, $input: SheetInput!) {
    saveLabSheet(labId: $labId, input: $input) {
      id
    }
  }
`);

export const useLabSheets = (labId: string) => {
  const { message } = App.useApp();
  const [sheetData, setSheetData] = useState<SheetDataWithId[]>([]);
  const [saveLabSheetsMutation] = useMutation(SAVE_LAB_SHEET_MUTATION);

  const { data, loading } = useQuery(LAB_SHEET_QUERY, {
    variables: {
      labId: labId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !labId,
  });

  useEffect(() => {
    if (data?.labSheet) {
      setSheetData(
        (data.labSheet.data as SheetData[]).map(sheet => ({
          ...sheet,
          id: uuidv4(),
          groups: sheet.groups.map(group => ({
            ...group,
            id: uuidv4(),
            parameters: group.parameters.map(param => ({
              ...param,
              paramId: param.id,
              id: uuidv4(),
            })),
          })),
        }))
      );
    }
  }, [data]);

  const saveHandler = async (data: SheetDataWithId[]) => {
    try {
      await saveLabSheetsMutation({
        variables: {
          labId: labId,
          input: {
            data: data.map(it => ({
              name: it.name,
              groups: it.groups.map(it => ({
                name: it.name,
                parameterIds: it.parameters.map(it => it.paramId),
              })),
            })),
          },
        },
      });
    } catch {
      message.error('Das Sheet konnte nicht gespeichert werden');
    }
  };

  const autoSave = (sheets: SheetDataWithId[]) => {
    setSheetData(sheets);
    saveHandler(sheets);
  };

  return {
    autoSave,
    loading,
    sheetData,
    labParameters:
      data?.labParameters?.filter(it => !getFragmentData(SheetEditorParameterFragment, it).deactivated) ?? [],
  };
};
