import React, { ReactNode } from 'react';
import { Card, theme } from 'antd';
import { css } from '@emotion/css';

const { useToken } = theme;

interface MainContentProps {
  size?: 'full' | 'large' | 'medium' | 'small';
  children: ReactNode;
  heightType?: 'maxFullHeight' | 'fullHeight';
}

export const MainContent: React.FC<MainContentProps> = ({ size, children, heightType }) => {
  const { token } = useToken();

  let style;
  let heightWrapperStyle = '';

  const styles = {
    wrapper: css`
      width: 100%;
      padding: ${token.paddingLG}px;
    `,
    content: css`
      margin: 0 auto 0 auto;
    `,
    maxFullHeightWrapper: css`
      max-height: 100%;
      display: flex;
    `,
    fullHeightWrapper: css`
      height: 100%;
      display: flex;
    `,
    fullHeightContent: css`
      flex-grow: 1;
      width: 100%;
      .ant-card-body {
        height: 100%;
      }
    `,
    contentFull: css`
      max-width: 100%;
    `,
    contentLarge: css`
      max-width: 1400px;
    `,
    contentMedium: css`
      max-width: 1100px;
    `,
    contentSmall: css`
      max-width: 800px;
    `,
  };

  switch (size) {
    case 'full':
      style = styles.contentFull;
      break;
    case 'large':
      style = styles.contentLarge;
      break;
    case 'medium':
      style = styles.contentMedium;
      break;
    case 'small':
      style = styles.contentSmall;
      break;
    default:
      style = styles.contentLarge;
      break;
  }

  if (heightType === 'maxFullHeight') {
    heightWrapperStyle = styles.maxFullHeightWrapper;
  }

  if (heightType === 'fullHeight') {
    heightWrapperStyle = styles.fullHeightWrapper;
  }

  return (
    <div className={`${styles.wrapper} ${heightWrapperStyle}`}>
      <Card className={`${styles.content} ${style} ${heightType ? styles.fullHeightContent : ''}`}>{children}</Card>
    </div>
  );
};
