import React from 'react';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { SheetDataWithId, SheetGroupWithId } from './types';
import { SortableParamEntry } from './SortableParamEntry.tsx';
import { Empty } from 'antd';
import { FragmentType, getFragmentData } from '../../graphql/generated';
import { SheetEditorParameterFragment } from './SheetEditor.tsx';

export const SortableParams: React.FC<{
  sheet: SheetDataWithId;
  group: SheetGroupWithId;
  parameters: FragmentType<typeof SheetEditorParameterFragment>[];
  onParamDragEnd: (sheetId: string, groupId: string, event: DragEndEvent) => void;
  onParamRemove: (sheetId: string, groupId: string, paramId: string) => void;
  showLab: boolean;
  flipParamNames: boolean;
}> = ({ sheet, group, parameters, onParamDragEnd, onParamRemove, showLab, flipParamNames }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={e => onParamDragEnd(sheet.id, group.id, e)}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext items={group.parameters} strategy={verticalListSortingStrategy}>
        {!group.parameters.length && (
          <Empty description="Keine Parameter vorhanden" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        {group.parameters.map(sheetParam => {
          const param = parameters
            .map(it => getFragmentData(SheetEditorParameterFragment, it))
            .find(it => it.id === sheetParam.paramId);
          return (
            <SortableParamEntry
              available={!!param && !param.deactivated}
              param={sheetParam}
              key={sheetParam.id}
              onRemove={() => onParamRemove(sheet.id, group.id, sheetParam.id)}
              showLab={showLab}
              flipParamNames={flipParamNames}
            />
          );
        })}
      </SortableContext>
    </DndContext>
  );
};
