import React, { DragEvent, useCallback, useEffect, useState } from 'react';
import { App, Button, Modal, Popconfirm, Space, Upload } from 'antd';
import {
  CloseOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  LoadingOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { PdfIframe } from '../../../../components/PdfIframe';
import { PdfPreviewModal } from '../../../../components/PdfPreviewModal';
import { css } from '@emotion/css';
import { saveUrl } from '../../../../utils/helpers';
import { graphql } from '../../../../graphql/generated';
import { Form } from '../Forms.tsx';
import { useAuth } from 'react-oidc-context';

const { Dragger } = Upload;

const allowedMimeTypes = ['application/pdf'];

const DELETE_FORM_DOCUMENT_MUTATION = graphql(`
  mutation DeleteFormDocument($id: ID!) {
    deleteFormDocument(id: $id) {
      id
    }
  }
`);

export const FormDocumentModal: React.FC<{
  form: Form | null;
  onClose: () => void;
}> = ({ form, onClose }) => {
  const [uploading, setUploading] = useState(false);
  const [documentUrl, setDocumentUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const auth = useAuth();
  const { message } = App.useApp();

  const loadDocument = useCallback(async () => {
    setDocumentUrl('');
    if (form === null) {
      return;
    }
    const endpoint = window._env_.API_URL + `/rest/form/${form.id}/documentUrl`;
    const response = await fetch(endpoint, {
      headers: { authorization: `Bearer ${auth.user?.access_token}` },
    });
    const url = await response.text();
    setDocumentUrl(url);
  }, [auth, form]);

  useEffect(() => {
    loadDocument();
  }, [form, loadDocument, auth]);

  const [deleteFormDocumentMutation] = useMutation(DELETE_FORM_DOCUMENT_MUTATION);

  const deleteDocument = async () => {
    try {
      await deleteFormDocumentMutation({
        variables: {
          id: form?.id ?? '',
        },
      });

      message.success('Dokument wurde gelöscht');
      loadDocument();
    } catch (e) {
      message.error('Dokument konnte nicht gelöscht werden');
    }
  };

  const onDrop = (event: DragEvent) => {
    if (Array.from(event.dataTransfer.files).some(f => !allowedMimeTypes.includes(f.type))) {
      message.error('Nur PDF-Dateien sind erlaubt');
    }
  };

  const beforeUpload = (file: File) => {
    const mimeTypeOk = allowedMimeTypes.find(mimeType => mimeType === file.type);
    if (!mimeTypeOk) {
      message.error('Nur PDF-Dateien sind erlaubt');
    }
    const fileSizeOk = file.size / 1024 / 1024 < 0.5;
    if (!fileSizeOk) {
      message.error('Datei muss kleiner als 0,5 MB sein');
    }
    if (mimeTypeOk && fileSizeOk) {
      upload(file);
    }
    return false;
  };

  const upload = async (file: File) => {
    setUploading(true);
    const endpoint = window._env_.API_URL + `/rest/form/${form?.id}/document`;
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        body: formData,
        headers: { authorization: `Bearer ${auth.user?.access_token}` },
      });
      const body = await response.text();
      response.ok ? message.success('Upload erfolgreich') : message.error('Upload fehlgeschlagen: ' + body);
      loadDocument();
    } catch (e) {
      message.error('Upload fehlgeschlagen: ' + e);
    } finally {
      setUploading(false);
    }
  };

  const download = () =>
    saveUrl(documentUrl, 'formular.pdf', () => message.error('Datei konnte nicht heruntergeladen werden'));

  return (
    <Modal
      width={768}
      title={`Dokument für Formular »${form?.name}« bearbeiten`}
      open={!!form}
      onCancel={onClose}
      footer={
        <Space direction="horizontal">
          {documentUrl && (
            <>
              <Button icon={<ZoomInOutlined />} onClick={() => setPreviewUrl(documentUrl)}>
                Großansicht
              </Button>
              <Button icon={<CloudDownloadOutlined />} onClick={download}>
                Download
              </Button>
              <Popconfirm
                title="Dokument wirklich löschen?"
                onConfirm={deleteDocument}
                okText="Ja"
                okButtonProps={{ danger: true }}
                cancelText="Nein"
              >
                <Button danger icon={<DeleteOutlined />}>
                  Löschen
                </Button>
              </Popconfirm>
            </>
          )}
          <Button type="primary" icon={<CloseOutlined />} onClick={onClose}>
            Schließen
          </Button>
        </Space>
      }
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Dragger showUploadList={false} beforeUpload={beforeUpload} accept=".pdf">
          <div
            onDrop={onDrop}
            style={{
              display: 'flex',
              height: '150px',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <p className="ant-upload-drag-icon">{uploading ? <LoadingOutlined /> : <CloudUploadOutlined />}</p>
            <p className="ant-upload-text">Neues Dokument hochladen</p>
            <p className="ant-upload-hint">
              Klicken Sie hier, oder ziehen Sie die gewünschte Datei in diesen Bereich, um den Upload zu starten.
              Erlaubter Dateityp: PDF
            </p>
          </div>
        </Dragger>

        {documentUrl && (
          <div
            className={css`
              height: 300px;
            `}
          >
            <PdfIframe url={documentUrl} />
          </div>
        )}
      </Space>
      <PdfPreviewModal title="PDF-Vorschau" url={previewUrl} loading={false} onClose={() => setPreviewUrl('')} />
    </Modal>
  );
};
