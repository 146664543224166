import React from 'react';
import { App, Modal } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { CatalogForm, type ParameterFragment } from './CatalogForm';
import { useForm } from 'antd/es/form/Form';
import { FragmentType, graphql } from '../../../../graphql/generated';
import { CatalogCreateInput } from '../../../../graphql/generated/graphql.ts';

const CREATE_CATALOG_MUTATION = graphql(`
  mutation CreateCatalog($input: CatalogCreateInput!) {
    createCatalog(input: $input) {
      id
    }
  }
`);

export const CreateCatalogModal: React.FC<{
  labId: string;
  parameterFragments: FragmentType<typeof ParameterFragment>[];
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ labId, parameterFragments, open, onClose, onSuccess }) => {
  const [createCatalogMutation, { loading }] = useMutation(CREATE_CATALOG_MUTATION);
  const [form] = useForm<CatalogCreateInput>();
  const { message } = App.useApp();

  const create = async (values: CatalogCreateInput) => {
    try {
      await createCatalogMutation({
        variables: {
          input: { ...values, labId: labId },
        },
      });

      message.success('Parameterkatalog wurde erstellt');
      onSuccess();
      onClose();
    } catch {
      message.error('Parameterkatalog konnte nicht erstellt werden');
    }
  };

  return (
    <Modal
      width={768}
      title="Neuen Parameterkatalog erstellen"
      okText="Erstellen"
      okButtonProps={{ icon: <PlusCircleOutlined /> }}
      open={open}
      onCancel={onClose}
      onOk={form.submit}
      confirmLoading={loading}
      afterClose={() => form.resetFields()}
      destroyOnClose
    >
      <CatalogForm
        form={form}
        parameterFragments={parameterFragments}
        onFinish={values => create(values as CatalogCreateInput)}
        initialValues={{
          labId: labId,
          name: '',
          description: '',
          parameterIds: [],
        }}
      />
    </Modal>
  );
};
