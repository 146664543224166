import React, { useEffect } from 'react';
import { App, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { SpecialRateForm } from './SpecialRateForm';
import { useForm } from 'antd/es/form/Form';
import { graphql } from '../../../../graphql/generated';
import { SpecialRate } from '../SpecialRates.tsx';
import { SpecialRateUpdateInput } from '../../../../graphql/generated/graphql.ts';

const UPDATE_SPECIAL_RATE_MUTATION = graphql(`
  mutation UpdateSpecialRate($id: ID!, $input: SpecialRateUpdateInput!) {
    updateSpecialRate(id: $id, input: $input) {
      id
    }
  }
`);

export const UpdateSpecialRateModal: React.FC<{
  specialRate: SpecialRate | null;
  specialRates: SpecialRate[];
  onClose: () => void;
  onSuccess: () => void;
}> = ({ specialRate, specialRates, onClose, onSuccess }) => {
  const [updateSpecialRateMutation, { loading }] = useMutation(UPDATE_SPECIAL_RATE_MUTATION);
  const [form] = useForm<SpecialRateUpdateInput>();
  const { message } = App.useApp();

  const update = async (values: SpecialRateUpdateInput) => {
    try {
      await updateSpecialRateMutation({
        variables: {
          id: specialRate?.id ?? '',
          input: { ...values },
        },
      });

      message.success('Sondertarif wurde aktualisiert');
      onSuccess();
      closeAndReset();
    } catch (e) {
      message.error('Sondertarif konnte nicht aktualisiert werden');
    }
  };

  const closeAndReset = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue({
      name: specialRate?.name ?? '',
      shortName: specialRate?.shortName ?? '',
      code: specialRate?.code ?? '',
    });
  }, [form, specialRate]);

  return (
    <Modal
      width={768}
      title="Sondertarif bearbeiten"
      okText="Aktualisieren"
      okButtonProps={{ icon: <EditOutlined /> }}
      open={!!specialRate}
      onCancel={closeAndReset}
      onOk={form.submit}
      confirmLoading={loading}
      destroyOnClose
      forceRender
    >
      <SpecialRateForm
        form={form}
        onFinish={update}
        codeValidator={code => !specialRates.filter(sr => sr.id !== specialRate?.id).some(sr => sr.code === code)}
      />
    </Modal>
  );
};
