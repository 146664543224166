import React, { useEffect } from 'react';
import { App, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { SpecimenForm } from './SpecimenForm';
import { useForm } from 'antd/es/form/Form';
import { graphql } from '../../../../graphql/generated';
import { Form, Specimen, TestTube } from '../Specimens.tsx';
import { SpecimenGroup, SpecimenUpdateInput } from '../../../../graphql/generated/graphql.ts';

const UPDATE_SPECIMEN_MUTATION = graphql(`
  mutation UpdateSpecimen($id: ID!, $input: SpecimenUpdateInput!) {
    updateSpecimen(id: $id, input: $input) {
      id
    }
  }
`);

export const UpdateSpecimenModal: React.FC<{
  specimen: Specimen | null;
  specimens: Specimen[];
  testTubes: TestTube[];
  forms: Form[];
  onClose: () => void;
  onSuccess: () => void;
}> = ({ specimen, specimens, testTubes, forms, onClose, onSuccess }) => {
  const [updateSpecimenMutation, { loading }] = useMutation(UPDATE_SPECIMEN_MUTATION);
  const [form] = useForm<SpecimenUpdateInput>();
  const { message } = App.useApp();

  const update = async (values: SpecimenUpdateInput) => {
    try {
      await updateSpecimenMutation({
        variables: {
          id: specimen?.id ?? '',
          input: { ...values },
        },
      });

      message.success('Material wurde aktualisiert');
      onSuccess();
      closeAndReset();
    } catch (e) {
      message.error('Material konnte nicht aktualisiert werden');
    }
  };

  const closeAndReset = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue({
      name: specimen?.name ?? '',
      classification: specimen?.classification ?? '',
      testTubeIds: specimen?.testTubes.map(tt => tt.id) ?? [],
      formIds: specimen?.forms.map(it => it.id) ?? [],
      group: specimen?.group ?? SpecimenGroup.NONE,
      features: specimen?.features ?? [],
      storagePeriod: specimen?.storagePeriod ?? 0,
      requiresLocalizations: specimen?.requiresLocalizations ?? false,
      localizationInputEnabled: specimen?.localizationInputEnabled ?? false,
      predefinedLocalizations: specimen?.predefinedLocalizations ?? [],
    });
  }, [form, specimen]);

  return (
    <Modal
      width={768}
      title="Material bearbeiten"
      okText="Aktualisieren"
      okButtonProps={{ icon: <EditOutlined /> }}
      open={!!specimen}
      onCancel={closeAndReset}
      onOk={form.submit}
      confirmLoading={loading}
      destroyOnClose
      forceRender
    >
      <SpecimenForm
        form={form}
        onFinish={update}
        nameValidator={name => !specimens.filter(s => s.id !== specimen?.id).some(s => s.name === name)}
        testTubes={testTubes}
        forms={forms}
      />
    </Modal>
  );
};
