import React, { DragEvent, useEffect, useState } from 'react';
import { Alert, App, Col, Input, Row, Space, Switch, Table, Tooltip, Upload } from 'antd';
import { MainContent } from '../../components/MainContent';
import { CloudUploadOutlined, InfoCircleOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import { ColumnsType } from 'antd/es/table';
import { css } from '@emotion/css';
import { useDebounce } from 'use-debounce';
import { Markable } from '../../components/Markable';
import { useAuth } from 'react-oidc-context';
import { useCurrentContextStore } from '../../hooks/store/useCurrentContextStore.ts';

interface Message {
  readonly id: string;
  readonly type: string;
  readonly requestId: string;
  readonly text: string;
}

interface RequestImportResponse {
  readonly successCount: number;
  readonly messages: Message[];
}

const { Dragger } = Upload;
const allowedMimeTypes = ['application/json'];

export const DbToolImport: React.FC = () => {
  const { currentDoctorId } = useCurrentContextStore();
  const [uploading, setUploading] = useState(false);
  const [dryRun, setDryRun] = useState(true);
  const [result, setResult] = useState<RequestImportResponse | null>(null);
  const auth = useAuth();
  const { message } = App.useApp();
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch] = useDebounce(search, 250);
  const [filteredMessages, setFilteredMessages] = useState<Message[]>([]);

  useEffect(() => {
    setFilteredMessages(
      result?.messages.filter(it => {
        if (debouncedSearch && debouncedSearch.length) {
          const searchValue = debouncedSearch.toLowerCase();
          return it.requestId.toLowerCase().includes(searchValue);
        }
        return true;
      }) ?? []
    );
  }, [debouncedSearch, result]);

  const onDrop = (event: DragEvent) => {
    if (Array.from(event.dataTransfer.files).some(f => !allowedMimeTypes.includes(f.type))) {
      message.error('Nur JSON-Dateien sind erlaubt');
    }
  };

  const beforeUpload = (file: File) => {
    const mimeTypeOk = allowedMimeTypes.find(mimeType => mimeType === file.type);
    if (!mimeTypeOk) {
      message.error('Nur JSON-Dateien sind erlaubt');
    }
    const fileSizeOk = file.size / 1024 / 1024 < 10;
    if (!fileSizeOk) {
      message.error('Datei muss kleiner als 10 MB sein');
    }
    if (mimeTypeOk && fileSizeOk) {
      upload(file);
    }
    return false;
  };

  const upload = async (file: File) => {
    setUploading(true);
    const endpoint = window._env_.API_URL + '/rest/request-import';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('doctorId', currentDoctorId);
    formData.append('dryRun', dryRun.toString());

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        body: formData,
        headers: { authorization: `Bearer ${auth.user?.access_token}` },
      });
      const result = await response.json();
      setResult(result);

      if (!response.ok) {
        message.error('Import fehlgeschlagen');
        setResult(null);
      }
    } catch {
      message.error('Import fehlgeschlagen');
      setResult(null);
    } finally {
      setUploading(false);
    }
  };

  const columns: ColumnsType<Message> = [
    {
      title: 'RequestID',
      dataIndex: 'requestId',
      key: 'requestId',
      width: 150,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.requestId.localeCompare(b.requestId),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Typ',
      dataIndex: 'type',
      key: 'type',
      width: 150,
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: 'Nachricht',
      dataIndex: 'text',
      key: 'text',
      sorter: (a, b) => a.text.localeCompare(b.text),
    },
  ];

  return (
    <MainContent>
      <PageHeader
        title="DBTool Import"
        style={{ padding: 0, paddingBottom: 'inherit' }}
        extra={
          <>
            DryRun
            <Tooltip title="Wenn aktiv werden keine Daten persistiert">
              <InfoCircleOutlined />
            </Tooltip>
            <Switch checked={dryRun} onChange={checked => setDryRun(checked)} />
          </>
        }
      />

      <Dragger disabled={uploading} showUploadList={false} beforeUpload={beforeUpload} accept=".json">
        <div
          onDrop={onDrop}
          style={{
            display: 'flex',
            height: '150px',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <p className="ant-upload-drag-icon">{uploading ? <LoadingOutlined /> : <CloudUploadOutlined />}</p>
          <p className="ant-upload-text">
            Anforderungen für <b>ausgewählten Zuweiser</b> importieren
          </p>
          <p className="ant-upload-hint">
            Klicken Sie hier, oder ziehen Sie die Anforderung-Export-Datei in diesen Bereich, um den Vorgang zu starten.
            Erlaubter Dateityp: JSON
          </p>
        </div>
      </Dragger>
      <p />
      {result && (
        <Space
          direction="vertical"
          className={css`
            width: 100%;
          `}
        >
          <Alert type="success" showIcon message={`${result.successCount} Anforderungen erfolgreich verarbeitet`} />
          <Row align={'middle'} gutter={[16, 0]}>
            <Col>
              <h3
                className={css`
                  margin: 0;
                `}
              >
                Meldungen
              </h3>
            </Col>
            <Col>
              <Input
                allowClear
                placeholder="Suche nach RequestID"
                value={search ?? ''}
                onChange={e => setSearch(e.target.value)}
                prefix={<SearchOutlined />}
              />
            </Col>
          </Row>
          <Table<Message>
            scroll={{ x: 'max-content' }}
            rowKey={record => record.id}
            size="small"
            showSorterTooltip={false}
            dataSource={filteredMessages}
            pagination={{
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]} bis ${range[1]} von ${total} Meldungen`,
            }}
            columns={columns}
          />
        </Space>
      )}
    </MainContent>
  );
};
