import React from 'react';
import { SheetDataWithId, SheetGroupWithId } from './types';
import { Button, Card, Popconfirm, theme } from 'antd';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/css';
import { CopyOutlined, DeleteOutlined, EditOutlined, HolderOutlined, PlusOutlined } from '@ant-design/icons';
import { SortableParams } from './SortableParams.tsx';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import { FragmentType } from '../../graphql/generated';
import type { SheetEditorParameterFragment } from './SheetEditor.tsx';

export const SortableGroupEntry: React.FC<{
  sheet: SheetDataWithId;
  parameters: FragmentType<typeof SheetEditorParameterFragment>[];
  group: SheetGroupWithId;
  onRemove: () => void;
  onEdit: () => void;
  onCopy: () => void;
  onParamAdd: () => void;
  onParamDragEnd: (sheetId: string, groupId: string, event: DragEndEvent) => void;
  onParamRemove: (sheetId: string, groupId: string, paramId: string) => void;
  showLab: boolean;
  flipParamNames: boolean;
}> = ({
  sheet,
  parameters,
  group,
  onRemove,
  onEdit,
  onCopy,
  onParamAdd,
  onParamDragEnd,
  onParamRemove,
  showLab,
  flipParamNames,
}) => {
  const { token } = theme.useToken();
  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({
    id: group.id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <Card
      ref={setNodeRef}
      style={style}
      title={group.name}
      type="inner"
      size="small"
      className={css`
        touch-action: none;
        background-color: ${isDragging ? '#f6f6f6' : 'white'};
        margin-top: ${token.marginSM}px;

        .ant-card-head {
          .delete,
          .edit,
          .copy,
          .add {
            opacity: 0;
          }
        }

        .ant-card-head:hover {
          .delete,
          .edit,
          .copy,
          .add {
            opacity: 1;
          }
        }
      `}
      extra={
        <>
          <Button icon={<PlusOutlined />} type="text" className="add" onClick={onParamAdd} />
          <Button icon={<EditOutlined />} type="text" className="edit" onClick={onEdit} />
          <Button icon={<CopyOutlined />} type="text" className="copy" onClick={onCopy} />
          <Popconfirm
            title="Gruppe entfernen?"
            onConfirm={() => onRemove()}
            okText="Ja, entfernen"
            okButtonProps={{ danger: true }}
            cancelText="Nein"
            placement="left"
          >
            <Button icon={<DeleteOutlined />} danger type="text" className="delete" />
          </Popconfirm>
          <Button
            icon={<HolderOutlined />}
            className={css`
              cursor: move;
            `}
            type="text"
            {...listeners}
            {...attributes}
          />
        </>
      }
    >
      <SortableParams
        sheet={sheet}
        parameters={parameters}
        group={group}
        onParamDragEnd={onParamDragEnd}
        onParamRemove={onParamRemove}
        showLab={showLab}
        flipParamNames={flipParamNames}
      />
    </Card>
  );
};
