import React, { ReactNode } from 'react';
import logo from '../assets/logo.svg';
import { Alert, Badge, Button, Layout, theme } from 'antd';
import { HelpMenu } from './HelpMenu';
import { css } from '@emotion/css';
import { Theme } from '../graphql/generated/graphql.ts';
import { useAppVersionStore } from '../hooks/store/useAppVersionStore.ts';

const { useToken } = theme;
const { Header } = Layout;

const envTag: string = window._env_.ENV_TAG;

interface AppHeaderProps {
  userActions: ReactNode;
  topMenu: ReactNode;
  theme: Theme;
}

const calcHeaderTextColor = (hex?: string) => {
  if (!hex || hex.length !== 7) {
    // default for transparent color and no hex string beginning with #
    return 'inherit';
  }
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'inherit' : '#f6f6f6';
};

export const AppHeader: React.FC<AppHeaderProps> = ({ userActions, topMenu, theme }) => {
  const { token } = useToken();
  const { bannerVisible } = useAppVersionStore();

  const styles = {
    header: css`
      display: flex;
      position: relative;
      box-sizing: border-box;
      padding-inline: ${token.paddingLG}px !important;
      color: ${calcHeaderTextColor(token.Layout?.headerBg)};
    `,
    logo: css`
      img {
        width: 200px;
      }
    `,
    content: css`
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    title: css`
      font-weight: 500;
      font-size: 20px;
    `,
    versionAlert: css`
      background-color: ${token.colorError};
      border: none;
      color: white;

      .ant-btn {
        margin-left: ${token.marginSM}px;
        animation: pulsate 3s ease-out;
        animation-iteration-count: infinite;
        opacity: 1;

        @keyframes pulsate {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
          100% {
            opacity: 1;
          }
        }
      }
    `,
    actions: css`
      line-height: initial;
      display: flex;
      align-items: center;
    `,
    help: css`
      margin-left: ${token.paddingSM}px;
    `,
  };

  return (
    <Header className={styles.header}>
      <div className={styles.logo}>
        {envTag && envTag !== 'prod' ? (
          <Badge.Ribbon text={envTag} color="red">
            <img src={theme.logoData ? theme.logoData : logo} alt="labcomplete Anforderungen" />
          </Badge.Ribbon>
        ) : (
          <img src={theme.logoData ? theme.logoData : logo} alt="labcomplete Anforderungen" />
        )}
      </div>
      <div className={styles.content}>
        {bannerVisible() ? (
          <Alert
            message={<strong>Neue Version von labcomplete verfügbar!</strong>}
            type="error"
            action={<Button onClick={() => window.location.reload()}>Jetzt aktualisieren</Button>}
            className={styles.versionAlert}
          />
        ) : (
          <span className={styles.title}>Anforderungen</span>
        )}
      </div>
      <div className={styles.actions}>
        {topMenu}
        {userActions}
        <HelpMenu className={styles.help} />
      </div>
    </Header>
  );
};
