import React from 'react';
import { Button, Card, Row, theme } from 'antd';
import { css } from '@emotion/css';
import { SelectedParameter } from '../../../../hooks/store/useAppStore.ts';
import { SheetParameterCol } from './SheetParameterCol.tsx';
import {
  ParameterSelection_RequestableParameterFragment,
  ParameterToAddInput,
} from '../../../../graphql/generated/graphql.ts';
import { SheetData, SheetGroup, SheetParameter } from '../../../../graphql/sheet';

export const SheetGroupCard: React.FC<{
  sheet: SheetData;
  group: SheetGroup;
  params: readonly ParameterSelection_RequestableParameterFragment[];
  selectedParams: SelectedParameter[];
  onAdd: (inputs: ParameterToAddInput[]) => void;
  onRemove: (paramId: string) => void;
  onInfo: (param: ParameterSelection_RequestableParameterFragment) => void;
  reorder: boolean;
  flipParamNames: boolean;
}> = ({ sheet, group, params, selectedParams, onAdd, onRemove, onInfo, reorder, flipParamNames }) => {
  const { token } = theme.useToken();

  const findRequestableParam = (sheetParam: SheetParameter) => params.find(it => it.mainParameterId === sheetParam.id);

  const isAvailable = (requestableParam?: ParameterSelection_RequestableParameterFragment) =>
    requestableParam?.id && !selectedParams.flatMap(sp => sp.withoutParameterIds).includes(requestableParam.id);

  const addAll = () => {
    onAdd(
      group.parameters
        .map(sheetParam => findRequestableParam(sheetParam))
        .filter(param => isAvailable(param))
        .map(param => ({
          parameterId: param!.id,
          preferredBillingType: null,
          preferredDiagnoseIds: [],
        }))
    );
  };

  const withoutParameterIds = selectedParams.flatMap(sp => sp.withoutParameterIds);

  return (
    <Card
      title={group.name}
      type="inner"
      size="small"
      className={css`
        margin-top: ${token.marginSM}px;

        :first-child {
          margin-top: 0;
        }
      `}
      extra={
        <Button type="link" size="small" onClick={addAll}>
          Alle auswählen
        </Button>
      }
    >
      <Row gutter={[16, 0]}>
        {group.parameters.map((sheetParam, index) => {
          const requestableParam = findRequestableParam(sheetParam);
          const selectedParam = selectedParams.find(it => it.mainParameterId === sheetParam.id);
          const available = !!requestableParam;
          const compatible = available && !withoutParameterIds.includes(requestableParam.id);

          return (
            <SheetParameterCol
              key={index}
              sheet={sheet}
              group={group}
              sheetParam={sheetParam}
              requestableParam={requestableParam}
              available={available}
              compatible={compatible}
              selected={!!selectedParam}
              onAdd={onAdd}
              onRemove={onRemove}
              onInfo={onInfo}
              reorder={reorder}
              flipParamNames={flipParamNames}
            />
          );
        })}
      </Row>
    </Card>
  );
};
