import { App, Button, Empty, Table, theme, Tooltip, Typography } from 'antd';
import { ClockCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { testTubeColorProperties, translateBillingType } from '../../../utils/enumHelpers';
import {
  BillingType,
  ParameterSelection_ProfileFragment,
  ParameterSelection_RequestableParameterFragment,
  ParameterToAddInput,
} from '../../../graphql/generated/graphql.ts';
import { AlignType } from 'rc-table/lib/interface';
import { css } from '@emotion/css';
import { TestTubeColor } from '../../../components/TestTubeColor.tsx';
import React, { useMemo } from 'react';
import { tableActionCell, tableHoverPointer } from '../../../styles/globalCss.ts';
import { SelectedParameter } from '../../../hooks/store/useAppStore.ts';
import { ellipsis } from '../../../utils/ellipsis.ts';

type ProfileParamType = ParameterSelection_ProfileFragment['parameters'][0];

type ParamInfo = {
  profileParam: ProfileParamType;
  requestableParam?: ParameterSelection_RequestableParameterFragment;
};

export const ProfileDetails: React.FC<{
  params: readonly ParameterSelection_RequestableParameterFragment[];
  selectedParams: SelectedParameter[];
  profile: ParameterSelection_ProfileFragment | null;
  flipParamNames: boolean;
  onAdd: (paramInput: ParameterToAddInput) => void;
  onRemove: (paramId: string) => void;
  onInfo: (param: ParameterSelection_RequestableParameterFragment) => void;
}> = ({ params, selectedParams, profile, flipParamNames, onAdd, onRemove, onInfo }) => {
  const { token } = theme.useToken();
  const { message } = App.useApp();

  const paramInfos: ParamInfo[] =
    profile?.parameters.map(profileParam => ({
      profileParam: profileParam,
      requestableParam: params.find(it => it.mainParameterId === profileParam.parameter.id),
    })) ?? [];

  const hasAcuteParams = paramInfos.some(it => it.profileParam?.parameter?.acute) ?? false;
  const isAddDisabled = (id: string) => selectedParams.flatMap(sp => sp.withoutParameterIds).includes(id);

  const availableLabs = useMemo(
    () =>
      params.reduce<ParameterSelection_RequestableParameterFragment['lab'][]>(
        (labs, param) => (labs.some(l => l.id === param.lab.id) ? labs : [...labs, param.lab]),
        []
      ),
    [params]
  );

  const handleClick = (
    parameter: ParameterSelection_RequestableParameterFragment | null | undefined,
    preferredBillingType: BillingType | null | undefined,
    preferredDiagnoseIds: string[]
  ) => {
    if (!parameter) {
      message.warning('Der Parameter ist nicht verfügbar');
      return;
    }

    if (isAddDisabled(parameter.id)) {
      message.warning('Der Parameter ist nicht mit den bereits ausgewählten Parametern kompatibel');
      return;
    }

    if (selectedParams.map(sp => sp.id).includes(parameter.id)) {
      onRemove(parameter.id);
      return;
    }
    onAdd({
      parameterId: parameter.id,
      preferredBillingType: preferredBillingType,
      preferredDiagnoseIds: preferredDiagnoseIds,
    });
  };

  const shortNameCol: ColumnType<ParamInfo> = {
    title: 'Kurzname',
    dataIndex: ['profileParam', 'parameter', 'shortName'],
    key: 'shortName',
    ellipsis: true,
    defaultSortOrder: flipParamNames ? undefined : 'ascend',
    width: 110,
    sorter: (a, b) => a.profileParam.parameter.shortName.localeCompare(b.profileParam.parameter.shortName),
    render: (value, record) => {
      const disabled = !record.requestableParam || isAddDisabled(record.requestableParam.id);
      return (
        <Typography.Text disabled={disabled} style={{ fontWeight: flipParamNames ? 'normal' : 500 }}>
          {value}
        </Typography.Text>
      );
    },
  };
  const longNameCol: ColumnType<ParamInfo> = {
    title: 'Langbezeichnung',
    dataIndex: ['profileParam', 'parameter', 'longName'],
    key: 'longName',
    ellipsis: true,
    defaultSortOrder: flipParamNames ? 'ascend' : undefined,
    width: 160,
    sorter: (a, b) => a.profileParam.parameter.longName.localeCompare(b.profileParam.parameter.longName),
    render: (value, record) => {
      const disabled = !record.requestableParam || isAddDisabled(record.requestableParam.id);
      const ellipsisValue = ellipsis(value, 28);

      return (
        <Tooltip title={value} trigger={disabled || value.length === ellipsisValue.length ? [] : ['hover']}>
          <Typography.Text disabled={disabled} style={{ fontWeight: flipParamNames ? 500 : 'normal' }}>
            {ellipsisValue}
          </Typography.Text>
        </Tooltip>
      );
    },
  };

  const columns: ColumnsType<ParamInfo> = [
    ...(flipParamNames ? [longNameCol, shortNameCol] : [shortNameCol, longNameCol]),
    ...(hasAcuteParams
      ? [
          {
            title: 'Akut',
            dataIndex: ['profileParam', 'parameter', 'acute'],
            key: 'acute',
            ellipsis: true,
            width: 70,
            align: 'center' as AlignType,
            sorter: (a, b) => Number(a.profileParam.parameter.acute) - Number(b.profileParam.parameter.acute),
            render: (_, record) => {
              return record.profileParam.parameter.acute ? (
                <ClockCircleOutlined
                  className={css`
                    font-size: 16px;
                    color: ${!record.requestableParam || isAddDisabled(record.requestableParam.id)
                      ? token.colorTextDisabled
                      : token.colorText};
                    cursor: ${!record.requestableParam || isAddDisabled(record.requestableParam.id)
                      ? 'not-allowed'
                      : 'inherit'};
                  `}
                />
              ) : (
                ''
              );
            },
          } as ColumnType<ParamInfo>,
        ]
      : []),
    ...(availableLabs.length > 1
      ? [
          {
            title: 'Labor',
            dataIndex: ['profileParam', 'parameter', 'lab', 'shortName'],
            key: 'lab.shortName',
            ellipsis: true,
            width: 80,
            sorter: (a: ParamInfo, b: ParamInfo) =>
              a.profileParam.parameter.lab.shortName.localeCompare(b.profileParam.parameter.lab.shortName),
            render: (value: string, record: ParamInfo) => {
              const disabled = !record.requestableParam || isAddDisabled(record.requestableParam.id);
              return <Typography.Text disabled={disabled}>{value}</Typography.Text>;
            },
          },
        ]
      : []),
    {
      title: 'Verrechnung',
      dataIndex: ['profileParam', 'preferredBillingType'],
      key: 'preferredBillingType',
      width: 150,
      ellipsis: true,
      render: (_, record) => {
        if (record.profileParam.preferredBillingType) {
          const disabled = !record.requestableParam || isAddDisabled(record.requestableParam.id);
          return (
            <Typography.Text disabled={disabled}>
              {translateBillingType(record.profileParam.preferredBillingType)}
            </Typography.Text>
          );
        }
        return null;
      },
    },
    {
      title: 'Proben',
      dataIndex: ['requestableParam', 'specimens'],
      key: 'testTubes',
      width: 90,
      ellipsis: true,
      render: (_, record) =>
        record.requestableParam?.specimens
          .flatMap(it => it.testTubes)
          .map((tt, i) => (
            <TestTubeColor
              key={tt.id + '_' + i}
              withText={false}
              properties={testTubeColorProperties(tt.color)}
              style={{ fontSize: 'inherit' }}
            />
          )),
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '50px',
      className: tableActionCell,
      render: (_, record) => (
        <Button
          icon={<InfoCircleOutlined />}
          disabled={!record.requestableParam}
          type="text"
          onClick={e => {
            e.stopPropagation();
            if (record.requestableParam) {
              onInfo(record.requestableParam);
            }
          }}
        />
      ),
    },
  ];

  return (
    <Table<ParamInfo>
      scroll={{ x: 'max-content' }}
      rowKey={record => record.requestableParam?.id ?? record.profileParam.id}
      size="small"
      showSorterTooltip={false}
      dataSource={paramInfos}
      pagination={false}
      columns={columns}
      rowClassName={tableHoverPointer}
      locale={{
        emptyText: (
          <Empty
            description="Das Profil beinhaltet keine anforderbaren Parameter"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ),
      }}
      className={css`
        padding: ${token.paddingSM}px 0;
      `}
      onRow={record => {
        return {
          onClick: e => {
            e.preventDefault();
            handleClick(
              record.requestableParam,
              record.profileParam.preferredBillingType,
              profile?.diagnoses.map(it => it.id) ?? []
            );
          },
        };
      }}
      rowSelection={{
        columnWidth: 24,
        type: 'checkbox',
        hideSelectAll: true,
        selectedRowKeys: selectedParams.map(p => p.id),
        onSelect: (record: ParamInfo, selected: boolean) => {
          if (!record.requestableParam) {
            message.warning('Der Parameter ist nicht verfügbar');
            return;
          }

          if (isAddDisabled(record.requestableParam.id)) {
            message.warning('Der Parameter ist nicht mit den bereits ausgewählten Parametern kompatibel');
            return;
          }

          selected
            ? onAdd({
                parameterId: record.requestableParam.id,
                preferredBillingType: record.profileParam.preferredBillingType,
                preferredDiagnoseIds: profile?.diagnoses.map(it => it.id) ?? [],
              })
            : onRemove(record.requestableParam.id);
        },
      }}
    />
  );
};
