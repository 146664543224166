import React, { ReactNode } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { theme, Tooltip } from 'antd';

const { useToken } = theme;

interface FormLabelProps {
  required: boolean;
  help: string;
  label: ReactNode;
  htmlFor?: string;
}

export const FormLabel: React.FC<FormLabelProps> = ({ required, help, label, htmlFor }) => {
  const { token } = useToken();

  return (
    <div>
      {required && (
        <span
          className={css`
            display: inline-block;
            margin-inline-end: ${token.paddingXXS}px;
            color: ${token.colorError};
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
          `}
        >
          *
        </span>
      )}
      <label htmlFor={htmlFor}>{label}</label>
      {help && (
        <Tooltip title={help}>
          <QuestionCircleOutlined
            className={css`
              display: inline-block;
              margin-inline-start: ${token.paddingXXS}px;
              color: ${token.colorTextSecondary};
            `}
          />
        </Tooltip>
      )}
    </div>
  );
};
