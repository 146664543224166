import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Theme } from '../../graphql/generated/graphql.ts';

export interface ThemeState {
  theme: Theme;
  previewMode: boolean;
  setTheme: (theme: Theme, previewMode: boolean) => void;
}

export const defaultTheme: Theme = {
  id: 'default',
  name: 'Default Theme',
  colorPrimary: '#d93f3d',
  controlItemBgActive: '#fff7dc',
  controlItemBgActiveHover: '#ffeeb4',
  colorBgHeader: '#f8d86f',
  colorBgTrigger: '#343434',
  logoData: '',
};

export const useThemeStore = create<ThemeState>()(
  devtools(
    set => ({
      theme: defaultTheme,
      previewMode: false,
      setTheme: (theme, previewMode) => set({ theme, previewMode }),
    }),
    { name: 'theme-store' }
  )
);
