import dayjs from 'dayjs';
import { PatientData } from '../graphql/generated/graphql.ts';

export interface ImportFileParseResult {
  readonly invalidPatientFields: string[];
  readonly invalidAdditionalDataFields: string[];
  readonly diagnose: string;
  readonly additionalData: Record<string, string>;
  readonly patientData: PatientData;
  readonly switchToDoctorId: string | null;
  readonly profile: string | null;
  readonly paramShortNames: string[];
}

export const aisImport = (jwtToken: string, currentDoctorId: string): Promise<ImportFileParseResult> =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    if (!window.nativeApi) {
      return reject('Native API not available');
    }

    try {
      const uint8Array = await window.nativeApi.readImportFile();
      const data = new FormData();
      data.append('file', new Blob([uint8Array], { type: 'text/plain' }));
      data.append('doctorId', currentDoctorId);

      const endpoint = window._env_.API_URL + `/rest/import-file`;
      const response = await fetch(endpoint, {
        method: 'POST',
        body: data,
        headers: { authorization: `Bearer ${jwtToken}` },
      });
      if (!response.ok) {
        return reject('Unable to read import file');
      }
      const result = (await response.json()) as ImportFileParseResult;

      if (dayjs(result.patientData.birthday).isSame(dayjs('9999-01-01'))) {
        // bad hack
        result.patientData.birthday = '';
      }
      if (
        result.patientData.insuredPerson &&
        dayjs(result.patientData.insuredPerson.birthday).isSame(dayjs('9999-01-01')) // bad hack
      ) {
        result.patientData.insuredPerson.birthday = '';
      }
      if (result.invalidPatientFields.includes('P-Geschlecht')) {
        // bad hack
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        result.patientData.gender = null as any;
      }

      resolve(result);
    } catch (e) {
      return reject(e);
    }
  });
