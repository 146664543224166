import React, { useEffect } from 'react';
import { App, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { DiagnoseForm } from './DiagnoseForm';
import { useForm } from 'antd/es/form/Form';
import { graphql } from '../../../../graphql/generated';
import { Diagnose } from '../Diagnoses.tsx';
import { DiagnoseUpdateInput } from '../../../../graphql/generated/graphql.ts';

const UPDATE_DIAGNOSE_MUTATION = graphql(`
  mutation UpdateDiagnose($id: ID!, $input: DiagnoseUpdateInput!) {
    updateDiagnose(id: $id, input: $input) {
      id
    }
  }
`);

export const UpdateDiagnoseModal: React.FC<{
  diagnose: Diagnose | null;
  diagnoses: Diagnose[];
  onClose: () => void;
  onSuccess: () => void;
}> = ({ diagnose, diagnoses, onClose, onSuccess }) => {
  const [updateDiagnoseMutation, { loading }] = useMutation(UPDATE_DIAGNOSE_MUTATION);
  const [form] = useForm<DiagnoseUpdateInput>();
  const { message } = App.useApp();

  const update = async (values: DiagnoseUpdateInput) => {
    try {
      await updateDiagnoseMutation({
        variables: {
          id: diagnose?.id ?? '',
          input: { ...values },
        },
      });

      message.success('Diagnose wurde aktualisiert');
      onSuccess();
      closeAndReset();
    } catch (e) {
      message.error('Diagnose konnte nicht aktualisiert werden');
    }
  };

  const closeAndReset = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue({
      name: diagnose?.name ?? '',
      text: diagnose?.text ?? '',
    });
  }, [form, diagnose]);

  return (
    <Modal
      width={768}
      title="Diagnose bearbeiten"
      okText="Aktualisieren"
      okButtonProps={{ icon: <EditOutlined /> }}
      open={!!diagnose}
      onCancel={closeAndReset}
      onOk={form.submit}
      confirmLoading={loading}
      destroyOnClose
      forceRender
    >
      <DiagnoseForm
        form={form}
        onFinish={update}
        nameValidator={name => !diagnoses.filter(d => d.id !== diagnose?.id).some(d => d.name === name)}
      />
    </Modal>
  );
};
