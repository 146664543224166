import { FC } from 'react';
import { Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { HelpSupport } from './HelpSupport.tsx';

const styles = {
  icon: css`
    font-size: 20px;
    cursor: pointer;
  `,
};

export const HelpMenu: FC<{ className?: string }> = ({ className }) => {
  return (
    <Popover content={<HelpSupport />} placement="bottomRight" align={{ offset: [10, 15] }} arrow trigger={['click']}>
      <QuestionCircleOutlined className={styles.icon + ' ' + className} />
    </Popover>
  );
};
