import React from 'react';
import { Button, Col, Form, FormInstance, Input, Row } from 'antd';
import { EditableTagGroup } from '../../../components/EditableTagGroup';
import { InsuranceInput } from '../../../graphql/generated/graphql.ts';

export const InsuranceForm: React.FC<{
  codeValidator: (code: string | null | undefined) => boolean;
  form: FormInstance<InsuranceInput>;
  onFinish: (values: InsuranceInput) => void;
}> = ({ codeValidator, form, onFinish }) => {
  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Row gutter={[16, 0]}>
        <Col span={8}>
          <Form.Item name="shortName" label="Kurzbezeichnung" rules={[{ required: true, whitespace: true }]}>
            <Input autoFocus />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="name" label="Bezeichnung" rules={[{ required: true, whitespace: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="code"
            label="Code"
            rules={[
              { required: true, whitespace: true },
              {
                validator: (_, code) =>
                  codeValidator(code) ? Promise.resolve() : Promise.reject('Dieser Code ist bereits in Verwendung'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="mappings" label="Alias-Codes" valuePropName="tags" initialValue={[]} rules={[{ type: 'array' }]}>
        <EditableTagGroup addLabel="Neuer Code" />
      </Form.Item>
      {/* hack for enter button support */}
      <Button htmlType="submit" hidden />
    </Form>
  );
};
