import React from 'react';
import { Button, Col, Row, Space, theme, Tooltip, Typography } from 'antd';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/css';
import { DeleteOutlined, HolderOutlined, WarningTwoTone } from '@ant-design/icons';
import { SheetParameter } from '../../graphql/sheet';

export const SortableParamEntry: React.FC<{
  param: SheetParameter;
  available: boolean;
  onRemove: () => void;
  showLab: boolean;
  flipParamNames: boolean;
}> = ({ param, available, onRemove, showLab, flipParamNames }) => {
  const { token } = theme.useToken();
  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({
    id: param.id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const textColStyle = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={css`
        touch-action: none;
        padding: 2px ${token.paddingSM}px 2px ${token.paddingSM}px;
        background-color: ${isDragging ? '#f6f6f6' : 'white'};
        .delete {
          opacity: 0;
        }

        :hover {
          background-color: #f6f6f6;
          .delete {
            opacity: 1;
          }
        }
      `}
    >
      <Row wrap={false} gutter={[16, 0]}>
        <Col span={flipParamNames ? 13 : 5} className={textColStyle}>
          <Space direction="horizontal">
            {!available && (
              <Tooltip title="Der Parameter wird vom Labor nicht mehr angeboten. Bitte kontaktieren Sie das Labor für weitere Informationen.">
                <WarningTwoTone twoToneColor={token.colorWarning} />
              </Tooltip>
            )}
            <Typography.Text
              className={css`
                font-weight: 500;
              `}
              disabled={!available}
            >
              {flipParamNames ? param.longName : param.shortName}
            </Typography.Text>
          </Space>
        </Col>
        <Col span={flipParamNames ? 5 : 13} className={textColStyle}>
          <Typography.Text type="secondary" disabled={!available}>
            {flipParamNames ? param.shortName : param.longName}
          </Typography.Text>
        </Col>
        <Col span={4} className={textColStyle}>
          {showLab && (
            <Typography.Text type="secondary" disabled={!available}>
              {param.labShortName}
            </Typography.Text>
          )}
        </Col>
        <Col
          span={2}
          className={css`
            display: flex;
            justify-content: end;
          `}
        >
          <Button size="small" icon={<DeleteOutlined />} danger type="text" className="delete" onClick={onRemove} />
          <Button
            size="small"
            icon={<HolderOutlined />}
            className={css`
              cursor: move;
            `}
            type="text"
            {...listeners}
            {...attributes}
          />
        </Col>
      </Row>
    </div>
  );
};
