import React, { useEffect } from 'react';
import { App, Button, Form, Modal } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { DoctorSelect } from '../../../components/DoctorSelect';
import { useForm } from 'antd/es/form/Form';
import { graphql } from '../../../graphql/generated';
import { Doctor, User } from '../Users.tsx';
import { UserAssignmentInput } from '../../../graphql/generated/graphql.ts';

const SAVE_USER_ASSIGNMENT_MUTATION = graphql(`
  mutation SaveUserAssignment($input: UserAssignmentInput!) {
    saveUserAssignment(input: $input) {
      id
    }
  }
`);

export const UpdateUserAssignmentModal: React.FC<{
  user: User | null;
  onClose: () => void;
  doctors: Doctor[];
}> = ({ user, onClose, doctors }) => {
  const [form] = useForm<UserAssignmentInput>();
  const [saveUserAssignmentMutation, { loading }] = useMutation(SAVE_USER_ASSIGNMENT_MUTATION);
  const { message } = App.useApp();

  const save = async (values: UserAssignmentInput) => {
    try {
      await saveUserAssignmentMutation({
        variables: {
          input: {
            ...values,
            userId: user!.id,
          },
        },
      });

      message.success('Beziehung wurde gespeichert');
      onClose();
    } catch (e) {
      message.error('Beziehung konnte nicht gespeichert werden: ' + e);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      primaryDoctorId: user?.userAssignment?.primaryDoctor.id ?? '',
      additionalDoctorIds: user?.userAssignment?.additionalDoctors?.map(it => it.id) ?? [],
    });
  }, [form, user]);

  return (
    <Modal
      width={768}
      title="Benutzer/Zuweiser Beziehung bearbeiten"
      okText="Speichern"
      okButtonProps={{ icon: <SaveOutlined /> }}
      open={!!user}
      onCancel={onClose}
      onOk={form.submit}
      confirmLoading={loading}
      destroyOnClose
      forceRender
    >
      <Form<UserAssignmentInput> layout="vertical" form={form} onFinish={save}>
        <Form.Item
          name="primaryDoctorId"
          label="Haupt-Zuweiser"
          rules={[{ type: 'string', required: true, whitespace: true }]}
          valuePropName="selected"
        >
          <DoctorSelect style={{ width: '100%' }} doctors={doctors} />
        </Form.Item>
        <Form.Item
          name="additionalDoctorIds"
          label="Weitere Zuweiser"
          rules={[{ type: 'array', max: 50 }]}
          valuePropName="selected"
        >
          <DoctorSelect style={{ width: '100%' }} multiple doctors={doctors} />
        </Form.Item>
        {/* hack for enter button support */}
        <Button htmlType="submit" hidden />
      </Form>
    </Modal>
  );
};
