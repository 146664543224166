import React from 'react';
import { css } from '@emotion/css';

export const PdfIframe: React.FC<{ url: string }> = ({ url }) => {
  return (
    <iframe
      title="PDF-Vorschau"
      src={`/pdf-viewer/web/viewer.html?file=${encodeURIComponent(url)}`}
      className={css`
        width: 100%;
        height: 100%;
        border: none;
      `}
    />
  );
};
