import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSocket } from './useSocket';
import { UpdateEvent } from './types';
import { debounce } from 'lodash';

type Options = { type: string; id?: string; tenantId?: string; skip?: boolean };

const DEBOUNCE_WAIT = 500;
const DEBOUNCE_MAX_WAIT = 500;

export const useSubscription = (opts: Options, callback: (event: UpdateEvent) => void) => {
  const [options, setOptions] = useState<Options>(opts);
  const { socket, isConnected } = useSocket();

  useEffect(() => {
    // deep comparison: prevents re-subscribing on each render cycle
    if (
      opts.type !== options.type ||
      opts.id !== options.id ||
      opts.tenantId !== options.tenantId ||
      opts.skip !== options.skip
    ) {
      setOptions(opts);
    }
  }, [opts, options]);

  useEffect(() => {
    const subscriptionId = uuidv4();
    let isSubscribed = false;
    const debouncer = debounce(callback, DEBOUNCE_WAIT, { maxWait: DEBOUNCE_MAX_WAIT });
    const listener = (event: UpdateEvent) => {
      if (event.subscriptionId === subscriptionId) {
        debouncer(event);
      }
    };

    if (isConnected && !options.skip) {
      socket.on('update-event', listener);

      console.debug(`subscribe to ${JSON.stringify(options)} with subscriptionId ${subscriptionId}`);
      socket.emit('subscribe', {
        id: subscriptionId,
        updateType: options.type,
        updateId: options.id,
        updateTenantId: options.tenantId,
      });
      isSubscribed = true;
    }

    return () => {
      if (isSubscribed) {
        socket.off('update-event', listener);
        console.debug('unsubscribe ' + subscriptionId);
        socket.emit('unsubscribe', subscriptionId);
      }
    };
  }, [socket, isConnected, options, callback]);
};
