import React from 'react';
import { Alert, Modal, Space } from 'antd';
import { CloseOutlined, WarningOutlined } from '@ant-design/icons';

export const InvalidAdditionalDataDialog: React.FC<{
  invalidFields: string[];
  onCancel: () => void;
}> = ({ invalidFields, onCancel }) => {
  return (
    <Modal
      width={500}
      title={
        <>
          <WarningOutlined /> Zusatzdaten der Anforderung ungültig
        </>
      }
      okButtonProps={{ hidden: true }}
      open={!!invalidFields.length}
      onCancel={onCancel}
      cancelButtonProps={{ type: 'primary', icon: <CloseOutlined /> }}
      cancelText="Anforderung abbrechen"
      destroyOnClose
    >
      <Space direction="vertical">
        <div>Folgende vom AIS bzw. externen System automatisch übernommene Felder sind ungültig:</div>
        <Alert type="error" message={invalidFields.join(', ')} showIcon />
        <div>
          Bitte korrigieren Sie die Daten in Ihrem AIS bzw. im externen System und versuchen sie die Anforderung erneut
          zu starten.
        </div>
      </Space>
    </Modal>
  );
};
