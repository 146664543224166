import React from 'react';
import { MainContent } from '../components/MainContent';
import { PageHeader } from '@ant-design/pro-components';
import { App, Button, Form, Input, Select, theme } from 'antd';
import { CreateDoctorFrontDeskInput, DoctorSalutation } from '../graphql/generated/graphql.ts';
import { translateDoctorSalutation } from '../utils/enumHelpers.ts';
import { serverSideEmailRule } from '../utils/helpers.ts';
import { MailOutlined, PhoneOutlined, SaveOutlined } from '@ant-design/icons';
import { graphql } from '../graphql/generated';
import { useMutation, useQuery } from '@apollo/client';
import { LoadingIndicator } from '../components/LoadingIndicator.tsx';
import { useForm } from 'antd/es/form/Form';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/css';
import { useCurrentContextStore } from '../hooks/store/useCurrentContextStore.ts';

const { Option } = Select;

const FRONT_DESK_DOCTOR_QUERY = graphql(`
  query FrontDeskDoctor {
    assignmentForCurrentUser {
      id
      primaryDoctor {
        id
        assignedLabs {
          lab {
            id
            name
          }
        }
      }
    }
  }
`);

const CREATE_FRONT_DESK_DOCTOR_MUTATION = graphql(`
  mutation CreateFrontDeskDoctor($input: CreateDoctorFrontDeskInput!) {
    createDoctorFrontDesk(input: $input) {
      id
    }
  }
`);

export const CreateFrontDeskDoctor: React.FC = () => {
  const [form] = useForm<CreateDoctorFrontDeskInput>();
  const { message } = App.useApp();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const { setCurrentDoctorId } = useCurrentContextStore();

  const { data, loading } = useQuery(FRONT_DESK_DOCTOR_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const [createFrontDeskDoctorMutation, { loading: saveLoading }] = useMutation(CREATE_FRONT_DESK_DOCTOR_MUTATION);

  const onFinish = async (values: CreateDoctorFrontDeskInput) => {
    try {
      const response = await createFrontDeskDoctorMutation({
        variables: {
          input: values,
        },
      });

      if (response.data?.createDoctorFrontDesk?.id) {
        message.success('Zuweiser wurde erstellt. Sie befinden sich jetzt im Kontext des angelegten Zuweisers');
        setCurrentDoctorId(response.data.createDoctorFrontDesk.id);
        navigate('/pv-uebersicht');
      }
    } catch (e) {
      message.error('Zuweiser konnte nicht erstellt werden: ' + e);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <MainContent size="small">
      <PageHeader
        title="Neuen Zuweiser anlegen"
        className={css`
          padding: 0;
          padding-bottom: ${token.paddingLG}px;
        `}
        onBack={() => navigate('/zuweiserauswahl')}
      />
      <Form
        layout="horizontal"
        labelCol={{ xs: 24, sm: 24, md: 10, lg: 10, xl: 10, xxl: 10 }}
        labelAlign="left"
        colon={false}
        form={form}
        initialValues={{
          salutation: DoctorSalutation.FRAU,
          name: '',
          specialist: '',
          hvNumber: '',
          meAddress: '',
          firstName: '',
          lastName: '',
          preTitle: '',
          postTitle: '',
          street: '',
          zip: '',
          city: '',
          email: '',
          phone: '',
          labId: data?.assignmentForCurrentUser?.primaryDoctor?.assignedLabs[0]?.lab?.id,
          customerId: '',
          comment: '',
        }}
        onFinish={onFinish}
      >
        <Form.Item name="labId" label="Labor" rules={[{ required: true }]}>
          <Select autoFocus>
            {(data?.assignmentForCurrentUser?.primaryDoctor?.assignedLabs ?? []).map(it => {
              return (
                <Option key={it.lab.id} value={it.lab.id}>
                  {it.lab.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="salutation"
          label="Anrede"
          rules={[{ type: 'enum', enum: Object.keys(DoctorSalutation), required: true }]}
        >
          <Select>
            {Object.keys(DoctorSalutation).map(s => {
              return (
                <Option key={s} value={s}>
                  {translateDoctorSalutation(s)}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="preTitle" label="Titel vorangestellt">
          <Input />
        </Form.Item>
        <Form.Item name="postTitle" label="Titel nachgestellt">
          <Input />
        </Form.Item>
        <Form.Item name="firstName" label="Vorname">
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Nachname bzw. Firma"
          tooltip="Relevant für z.B. HL7 und Dokumente"
          rules={[{ type: 'string', required: true, whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Anzeigename"
          tooltip="Wird im UI angezeigt"
          rules={[{ type: 'string', required: true, whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="specialist" label="Facharzt">
          <Input />
        </Form.Item>
        <Form.Item name="meAddress" label="ME-Adresse" rules={[{ type: 'string', required: true, whitespace: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="customerId"
          label="Kundennummer"
          rules={[{ type: 'string', required: true, whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="hvNumber" label="Hauptverbandsnummer">
          <Input />
        </Form.Item>
        <Form.Item
          name="street"
          label="Straße inkl. Hausnummer"
          rules={[{ type: 'string', required: true, whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="zip" label="PLZ" rules={[{ type: 'string', required: true, whitespace: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="city" label="Ort" rules={[{ type: 'string', required: true, whitespace: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label="E-Mail" rules={[serverSideEmailRule]} validateDebounce={200}>
          <Input addonBefore={<MailOutlined />} />
        </Form.Item>
        <Form.Item name="phone" label="Telefon">
          <Input addonBefore={<PhoneOutlined />} />
        </Form.Item>
        <Form.Item name="comment" label="Interne Anmerkung">
          <Input.TextArea rows={5} />
        </Form.Item>

        <Button type="primary" htmlType="submit" icon={<SaveOutlined />} loading={saveLoading}>
          Speichern
        </Button>
      </Form>
    </MainContent>
  );
};
