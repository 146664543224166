import { useQuery } from '@apollo/client';
import { graphql } from '../graphql/generated';
import { AisExportType } from '../graphql/generated/graphql.ts';

const DOCTOR_AIS_EXPORT_TYPES_QUERY = graphql(`
  query DoctorAisExportTypes($id: ID!) {
    doctor(id: $id) {
      id
      aisExportTypes
    }
  }
`);

export const useDoctorAisExportTypes = (doctorId: string): AisExportType[] => {
  const { data } = useQuery(DOCTOR_AIS_EXPORT_TYPES_QUERY, {
    variables: {
      id: doctorId,
    },
    fetchPolicy: 'cache-and-network',
  });

  return data?.doctor?.aisExportTypes ?? [];
};
