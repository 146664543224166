import { AuthProviderNoUserManagerProps } from 'react-oidc-context';
import { User, WebStorageStateStore } from 'oidc-client-ts';
import { currentUrlWithoutQueryString } from './utils/helpers.ts';

//if (process.env.NODE_ENV === 'development') {
//Log.setLogger(console);
//  Log.setLevel(Log.DEBUG);
//}

// in memory store is a bit more secure but slower
// on a browser reload a full authorization code flow has to be executed
// sessionStorage is the default of oidc-client-ts
//const inMemoryUserStore = new InMemoryWebStorage();

export const oidcConfig: AuthProviderNoUserManagerProps = {
  authority: window._env_.KEYCLOAK_URL + '/realms/medcom',
  client_id: 'labrador-ui',
  redirect_uri: currentUrlWithoutQueryString(),
  userStore: new WebStorageStateStore({ store: window.sessionStorage /* inMemoryUserStore */ }),
  onSigninCallback: () => window.history.replaceState({}, document.title, window.location.pathname),
  // tracks the session state via hidden iframe, a.o. used for multi-tab logout
  // see https://melmanm.github.io/misc/2023/01/30/article3-openid-connect-session-management.html
  // Third-party cookies need to be enabled, no more support by major modern browsers including Chrome, FF, Safari
  // with monitorSession=false the user will get logged out of other tabs when the next access token refresh fails (within 5 min)
  // we use websockets for realtime single session logout
  monitorSession: false, // false is the default of this lib
};

const loadUserState = () => window.sessionStorage.getItem(`oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`);

export const loadUser = () => {
  const userState = loadUserState();
  if (!userState) {
    return null;
  }
  return User.fromStorageString(userState);
};
